import {Dropdown, Form, Menu, Row, Select} from "antd";
import React, {Props, useEffect, useState} from "react";
import api from "../../../../api";
import {RootState} from "../../../../redux-stuffs/store/store";
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {WEB_FLOW} from "../../../../constants";
import Button from "../../button/Button";
import Spinner from "react-spinner-material";

const {Option} = Select;

const WebFlowSecondForm = ({success, handleCancel, form, setCurrent}) => {
    const history = useHistory();
    const {getFieldDecorator} = form;
    const {serverToken} = useSelector<RootState, any>(
        (state) => state?.selfReducers
    );

    const [siteData, setSiteData] = useState([]);

    const [collectionData, setCollectionData] = useState<any>([]);

    const [loading, setLoading] = useState<any>(false);
    const [error, setError] = useState(null);

    const [fieldData, setFieldData] = useState<any>([]);

    useEffect(() => {
        if (history.location?.state?.id) {
            setLoading(true);
            api
                .getWebFlowSites(serverToken, history.location.state.id)
                .then((res) => {
                    if (res.length > 0) {
                        setError(null)
                        setSiteData(res);
                        setLoading(false);
                    } else {
                        setError("Your account doesn't have site.")
                    }
                });
        }
    }, []);

    return (
        <div className="webflow-second-form">
            <Form>
                {/*<h4>Select Site & Connections</h4>*/}
                <div className="first-content"
                >
                    <Form.Item
                    >
                        {getFieldDecorator('site', {
                            rules: [{required: true, message: 'Please select site!'}],
                        })
                        (<Select
                            placeholder="Select a site"
                            size="small"
                            disabled={siteData?.length === 0}
                            onChange={async (value: any) => {
                                setLoading(true);
                                form.setFieldsValue({
                                    collection: undefined,
                                    title: undefined,
                                    content: undefined
                                })
                                await api
                                    .getWebFlowSiteCollection(
                                        serverToken,
                                        history.location.state.id,
                                        value //selected site
                                    )
                                    .then((res) => {
                                        if (res.length > 0) {
                                            setError(null);
                                            setCollectionData(res)
                                            setFieldData([])
                                        } else {
                                            setError("No Data Available");
                                            setCollectionData([])
                                            setFieldData([])
                                        }
                                        setLoading(false);
                                    });
                            }}
                        >
                            {siteData.map((i) => (
                                <Option key={i._id} value={i._id}>
                                    {i.name}
                                </Option>
                            ))}
                        </Select>)}
                    </Form.Item>


                    <Form.Item>
                        {getFieldDecorator('collection', {
                            rules: [{required: true, message: 'Please select post collection!'}],
                        })
                        (<Select
                            placeholder="Select your post collection"
                            disabled={collectionData?.length === 0}
                            size="small"
                            onChange={async (value: any) => {
                                setLoading(true);
                                form.setFieldsValue({title: undefined, content: undefined})
                                await api
                                    .getWebFlowCollectionDetail(
                                        serverToken,
                                        history.location.state.id,
                                        value
                                    ).then(res => {
                                        if (res.fields.length > 0) {
                                            setError(null);
                                            let richTextFilter = res.fields.filter(fi => fi.type === 'RichText')
                                            if (richTextFilter.length > 0) {
                                                setFieldData(res.fields)
                                                setError(null)
                                            } else {
                                                setFieldData([])
                                                setError("Selected post collection doesn't have the field for cSelected post collection doesn't have the field for content.ontent.")
                                            }
                                        } else {
                                            setError("No Data Available");
                                            setFieldData([])
                                        }
                                    })
                                setLoading(false);
                            }}
                        >
                            {collectionData?.map((i) => (
                                <Option key={i._id} value={i._id}>
                                    {i.name}
                                </Option>
                            ))}
                        </Select>)}
                    </Form.Item>

                </div>

                <div className="last-content">
                    <Form.Item>
                        {getFieldDecorator('content', {
                            rules: [{required: true, message: 'Please select Content Field!'}],
                        })
                        (<Select
                            placeholder="Select content field"
                            size="small"
                            disabled={fieldData?.length === 0}
                        >
                            {fieldData?.filter(fd => fd.type === "RichText")?.map((i) => (
                                <Option key={i._id} value={i.slug}>
                                    {i.name}
                                </Option>
                            ))}
                        </Select>)}
                    </Form.Item>
                </div>

                <div
                    style={{
                        display: loading ? "block" : "none",
                        marginBottom: "15px",
                    }}
                >
                    <Spinner
                        size={20}
                        spinnerColor={"#364699"}
                        spinnerWidth={2}
                        visible={true}
                        value="completed"
                    />
                </div>

                {error !== null && (
                    <div style={{color: "#f5222d", fontSize: "15px", margin: "10px 0 10px 5px"}}>
                        {error}
                    </div>
                )}


                <Row style={{margin: "15px 0 "}}>
                    <Button
                        title="Cancel"
                        color="grey"
                        className="medium-font margin-right-10 border-grey webflow-cancel"
                        onClick={handleCancel}
                    />
                    <Button
                        title="Add Site"
                        color="purple"
                        className="medium-font border-none webflow-add-site"
                        onClick={async () => {
                            setLoading(true)
                            await form.validateFields(async (err, values) => {
                                if (!err) {
                                    await api
                                        .createWebFlow(serverToken, {
                                            id: history.location.state.id,
                                            site: siteData.find(si => si._id === values.site)?.name + " (" + collectionData.find(cd => cd._id === values.collection)?.name + ")",
                                            collection_id: values.collection,
                                            content_field: values.content,
                                        })
                                        .then((res) => {
                                            history.replace("/websites");
                                            success(WEB_FLOW);
                                        }).catch(err => {
                                            console.log(err)
                                            err.then(result =>
                                                setError(result.error || result.data)
                                            )
                                        })
                                }
                                setLoading(false)

                            })

                        }
                        }
                    />
                </Row>
            </Form>

        </div>
    );
};


export default Form.create<any>()(WebFlowSecondForm);
