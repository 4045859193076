import React, { useEffect, useState, useMemo } from "react";
import "./plans.scss";
import ReactLoading from "react-loading";
import { useSelector, useDispatch } from "react-redux";
import { Route, useHistory } from "react-router";
import { Switch as ToggleButton, Icon } from "antd";
import api from "../../../api";
import { RootState } from "../../../redux-stuffs/store/store";

const Plans = () => {
  const history = useHistory();

  const [plansData, setPlansData] = useState<any>([]);
  const [pricing, setPricing] = useState<any>("MONTHLY");

  const { profile, serverToken } = useSelector<RootState, any>(
    (state) => state?.selfReducers
  );

  useEffect(() => {
    api.listAllPlans(serverToken).then((res) => setPlansData(res));
  }, []);

  return plansData?.length > 0 ? (
    <div className="plans-container">
      <div className="membership-section-bottom-container">
        <div className="plan-detail-info">
          <h2 className="plan-section-header">Choose A Plan To Continue</h2>
        </div>
        <div className="plan-section-container">
          <div className="switch-plan-container">
            <p
              className="monthly-plan"
              style={{ color: pricing === "MONTHLY" ? "#1A1A1A" : "inherit" }}
            >
              {" "}
              Monthly Pricing{" "}
            </p>{" "}
            <ToggleButton
              onChange={() =>
                setPricing(pricing === "MONTHLY" ? "YEARLY" : "MONTHLY")
              }
            />{" "}
            <p
              className="annual-plan"
              style={{ color: pricing === "MONTHLY" ? "inherit" : "#1A1A1A" }}
            >
              {" "}
              Annual Pricing
            </p>
          </div>
          <div className="save-path" />
          <p className="save-section"> GET 2 MONTHS FREE </p>
        </div>
        <div className="package-description-container" id="plan-section">
          {plansData.map((i) => (
            <div key={i.id} className="individual-package-info-container">
              {i.plan_entity_list
                .filter((fi) => fi.recurring_payment_type === pricing)
                .map((p) => (
                  <div key={p.id}>
                    <div className="package-name-container">
                      <p className="package-name-left-section">{i.name}</p>
                      <p className="package-name-right-section">
                        ${p.discounted_price}
                        {pricing === "MONTHLY" ? "/mo" : "/yr"}
                      </p>
                    </div>
                    <div className="year-plan-details">
                      <ul>
                        {p.features.map((feature, index) => (
                          <li key={index}>
                            <i className="far fa-check-circle" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div
                      className="explore-plan-container"
                      onClick={async () => {
                        await api
                          .checkoutUser(serverToken, p.id)
                          .then((res) => {
                            window.location.href = res.message;
                          });
                      }}
                    >
                      Start Free Trial
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading type="spin" color="#364699" height={30} width={30} />
    </div>
  );
};

export default Plans;
