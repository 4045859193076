import React, { Component } from "react";
import "./dropdown.scss";
import { Select } from "antd";
import * as CONSTANTS from "../../../constants";

const { Option } = Select;

export class Dropdown1 extends Component<Props, DropDownChoice> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuKey: props.title,
    };
  }

  handleChange = (value: any) => {
    this.setState(
      {
        menuKey: value,
      },
      () => {
        this.props.changePlatform(value.key);
      }
    );
  };
  resetDropdwon = () => {
    this.setState(
      {
        menuKey: CONSTANTS.WORDPRESS,
      },
      () => {
        this.props.resetDropDownFunction(false);
        console.log("this is menuKey after reset Dropdown", this.state.menuKey);
      }
    );
  };

  render(): any {
    const { className } = this.props;

    return (
      <div id="area">
        {this.props.resetDropdown ? this.resetDropdwon() : null}
        {(() => {
          console.log("this is props title", this.props.title);
        })()}
        <Select
          dropdownClassName="platform-dropdown"
          getPopupContainer={() =>
            document.getElementById("area") || document.body
          }
          labelInValue
          defaultValue={{ key: `${this.state.menuKey}` }}
          /*
                                                  value={this.state.menuKey}
                              */
          onChange={this.handleChange}
          className={className}
        >
          <Option value={CONSTANTS.WORDPRESS}>Wordpress</Option>
          {/*<Option value={CONSTANTS.SHOPIFY}>Shopify</Option>*/}
          <Option value={CONSTANTS.MEDIUM}>Medium</Option>
          {/* <Option value={CONSTANTS.DRUPAL}>Drupal</Option> */}
          <Option value={CONSTANTS.WEB_FLOW}>Web Flow</Option>
        </Select>
      </div>
    );
  }
}

interface DropDownChoice {
  menuKey: string;
}

interface Props {
  title: string;
  className: string;
  changePlatform?: any;
  resetDropdown?: boolean;
  resetDropDownFunction?: any;
  history?: any;
}
