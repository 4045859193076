import React, { Component } from "react";
import {
  Input,
  Modal,
  Row,
  Select,
  Icon,
  Table as AntTable,
  Tooltip,
  Pagination,
} from "antd";
import ErrorImage from "../../../assets/images/empty-state/empty-state_Gdocs.svg";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { getInitials } from "../../../utils/CommonFunctions";
import "./table.scss";
import { connect } from "react-redux";
import DefaultButton from "../button/Button";
import NotionPress from "../../../assets/images/NotionPress.png";
import FileIcon from "../../../assets/images/table/icon_gdoc.svg";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import SearchIcon from "../../../assets/images/table/icon_search.svg";
import api from "../../../api/index";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import * as CONSTANTS from "../../../constants";
import { SHOPIFY, WORDPRESS } from "../../../constants";
import ReactLoading from "react-loading";
import fileDownload from "react-file-download";
import Header from "../Typography/Header";
import PlusImage from "../../../assets/icons/website/home page/faq-plus-purple.svg";
import { Dropdown1 } from "../dropdown/Dropdown1";
import classNames from "classnames";
import WordpressForm from "../form/wordpress/WordpressForm";
import ShopifyForm from "../form/shopify/ShopifyForm";
import MediumForm from "../form/medium/Medium";
import DrupalForm from "../form/Drupal/DrupalForm";
import BigCommerceForm from "../form/bigcommerce/BigCommerceForm";
import SuccessModal from "../modal/SuccessModal/SuccessModal";
import { RouteComponentProps } from "react-router";
import CursorPagination from "./cursorPagination";
import SearchBar from "./searchBar";
import WebFlowForm from "../form/webflow/WebFlowForm";

const { Option } = Select;

class NotionTable extends Component<Props, TableState> {
  constructor(props: Props) {
    super(props);
    console.log("this is props", props);
    this.state = {
      nextBtn: 0,
      searchText: "",
      searchItem: false,
      // data: this.props.tableData,
      loading: false,
      tableLoading: false,
      paginationTableData: [],
      searchTableData: [],
      icon: 1,
      pagination: 25,
      modifiedInitialState: "LAST_MODIFIED",
      browseModalVisible: false,
      exportModalVisible: false,
      titleIconModalVisible: false,
      markDownIconModalVisible: false,
      exportTooltipIconModalVisible: false,
      selectSiteKey: "",
      placeholderError: false,
      dataKey: 1,
      // apiLoading: this.props.apiLoading,
      linkedAccountsList: [
        {
          id: undefined,
          url: undefined,
          cms_domain: undefined,
        },
      ],

      currentItem: {
        alternate_link: "",
        title: "",
        id: "",
      },
      currentItemIndex: -1,

      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
      exportSuccess: false,
      exportError: false,
      error: null,
      exportingDoc: false,
      currentPlatform: CONSTANTS.WORDPRESS,
      addWebsiteModalVisible: false,
    };
  }

  componentDidMount() {
    this.props.loadingUpdate(true);
    this.callNotionDocs();

    api
      .getLinkedAccounts(this.props.token)
      .then((res) => {
        console.log("This is the account res", res);
        this.setState({
          linkedAccountsList: res,
        });
      })
      .catch((error) => {
        console.log("this is error", error);
        // alert(error);
      });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        searchTableData: [],
        searchText: "",
        searchItem: false,
      });
      this.callNotionDocs();
    }
  }

  callNotionDocs = async () => {
    this.props.loadingUpdate(true);
    // this.setState({ tableLoading: true });

    await api
      .getGoogleDocList(this.props.token)
      .then((res) => {
        console.log("This is doclist res", res);
        this.setState({
          // tableLoading: false,
          nextBtn: 0,
          paginationTableData: {
            pageCount: Math.ceil(res.notion_doc_list.length / 25),
            currentIndex: 0,
            data: [
              {
                id: 1,
                next_cursor: res.next_cursor,
                notion_doc_list: res.notion_doc_list,
                has_more: res.has_more,
              },
            ],
          },
        });

        this.props.updateTable({
          ...res,
          id: 1,
          notion_doc_list: res.notion_doc_list.slice(0, 25),
        });
        this.props.loadingUpdate(false);
      })
      .catch((error) => {
        console.log("error", error);

        this.props.loadingUpdate(false);
      });
  };

  onClickTitleHoverIcon = (e) => {
    e.preventDefault();
    this.setState({
      titleIconModalVisible: true,
    });
  };
  handleCancelTitleIcon = (e) => {
    e.preventDefault();
    this.setState({
      titleIconModalVisible: false,
    });
  };
  onClickMarkdownHoverIcon = (e) => {
    e.preventDefault();
    this.setState({
      markDownIconModalVisible: true,
    });
  };
  handleCancelMarkdownIcon = (e) => {
    e.preventDefault();
    this.setState({
      markDownIconModalVisible: false,
    });
  };
  onClickExportHoverTooltipIcon = (e) => {
    e.preventDefault();
    this.setState({
      exportTooltipIconModalVisible: true,
    });
  };
  handleCancelExportHoverTooltipIcon = (e) => {
    e.preventDefault();
    this.setState({
      exportTooltipIconModalVisible: false,
    });
  };

  onClickBrowseModalICon = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: true,
    });
  };
  handleCancelBrowseModal = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: false,
    });
  };
  onHandleExportCancel = (e) => {
    e.preventDefault();
    this.setState({
      exportModalVisible: false,
      placeholderError: false,
      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
    });
  };
  onClickExport = (id) => {
    console.log("this is data index", id);
    const { tableData: data } = this.props;

    this.setState(
      {
        exportModalVisible: true,
        dataKey: id,

        currentItem: data.notion_doc_list.find((i: any) => i.id === id),
        currentItemIndex: data.notion_doc_list.findIndex(
          (i: any) => i.id === id
        ),
      },
      () => {
        api
          .getLinkedAccounts(this.props.token)
          .then((res) => {
            console.log("This is the account res", res);
            this.setState({
              linkedAccountsList: res,
            });
          })
          .catch((error) => {
            console.log("this is error", error);
            // alert(error);
          });
      }
    );
  };
  exportSiteChange = async (i) => {
    console.log("This is the i", i);
    i = parseInt(i);
    this.setState(
      {
        currentLinkedAccountIndex: i,
        currentLinkedAccount: this.state.linkedAccountsList[i],
      },
      () => {
        console.log(
          "This is the this.state.currentLinkedAccountIndex",
          this.state.currentLinkedAccountIndex
        );

        console.log(
          "This is the this.state.currentLinkedAccount",
          this.state.currentLinkedAccount
        );
        const { currentLinkedAccount } = this.state;
        console.log(
          "This is the currentLinkedAccount.cms_domain",
          currentLinkedAccount.cms_domain
        );
        if (currentLinkedAccount.cms_domain === SHOPIFY) {
          try {
            let shopifyBlogsArray = api.getShopifyBlogList(
              this.props.token,
              currentLinkedAccount.id
            );
            console.log("This is the shopifyBlogList", shopifyBlogsArray.id);
            // shopifyBlogList = shopifyBlogList.json();
            shopifyBlogsArray
              .then((res) => {
                console.log("This is the res", res);
                this.setState({
                  shopifyBlogList: res,
                });
              })
              .catch((err) => {
                console.log("This is the err after json making", err);
                err.then((finalErr) => {
                  console.log("This is the finalErr", finalErr);
                });
              });
          } catch (e) {
            console.log("This is the e", e);
          }
        }
      }
    );
  };
  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        currentPlatform: val,
      },
      () => {}
    );
  };
  handleChangePagination = (value) => {
    this.setState(
      {
        pagination: value.key,
      },
      () => {
        console.log("this is pagination value", this.state.pagination);
      }
    );
    console.log(value);
  };
  handleChangeModified = (value) => {
    this.setState(
      {
        modifiedInitialState: value.key,
      },
      () => {
        api
          .getGoogleDocList(this.props.token)
          .then((res) => {
            console.log("This is doclist res", res);
            // this.setState({
            //   data: res,
            //   // apiLoading: false,
            // });
            this.props.updateTable(res);

            this.props.loadingUpdate(false);
          })
          .catch((error) => {
            // this.setState({
            //   apiLoading: false,
            // });
            this.props.loadingUpdate(false);

            console.log("error", error);

            /*
                                            window.location.href = "https://wordpress-335777-1033023.cloudwaysapps.com/";
                            */
            /*
                                        // alert("Token Expired Login again ");
                            */
            /*
                                        // alert(error);
                            */
          });
      }
    );
    console.log(value);
  };
  updateSearchText = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        searchItem: e.target.value,
      },
      () => {
        console.log("this is searchText", this.state.searchText);
      }
    );
  };
  searchText = (e) => {
    this.setState({
      searchItem: this.state.searchText,
    });
  };
  // keyPressEnter = (e) => {
  //   if (e.key === "Enter" && this.state.searchText !== null) {
  //     this.searchText(e);
  //   }
  // };

  changeShopifyId = (val) => {
    console.log("This is the val", val);
    this.setState({
      shopifyBlogId: val,
    });
  };
  markdownDownload = (data) => {
    let finalBody = {
      id: data.id,
      fileName: data.title,
    };
    api.markdownDownload(finalBody, this.props.token).then((res) => {
      fileDownload(res, data.title + ".zip");
    });
  };

  handleExport = () => {
    const {
      currentLinkedAccount,
      currentLinkedAccountIndex,
      currentItem,
      currentItemIndex,
      shopifyBlogId,
    } = this.state;
    console.log("This is the currentLinkedAccount", currentLinkedAccount);
    console.log(
      "This is the currentLinkedAccountIndex",
      currentLinkedAccountIndex
    );
    console.log("This is the currentItem", currentItem);
    console.log("This is the currentItemIndex", currentItemIndex);

    if (currentLinkedAccountIndex < 0) {
      /*
                              // alert("Please select a site");
                  */
      this.setState({
        placeholderError: true,
      });
      return;
    }

    let exportBody = {};
    let exportUrl = "";

    exportBody["doc_name"] = currentItem.title;
    exportBody["file_id"] = currentItem.id;

    console.log("PODD", currentLinkedAccount.cms_domain);

    switch (currentLinkedAccount.cms_domain) {
      case WORDPRESS:
        exportBody["status"] = "draft";
        exportBody["wordpress_account_id"] = currentLinkedAccount.id;
        exportBody["page_or_post"] = "post";

        exportUrl = "word-press/export";
        break;
      case "SHOPIFY":
        if (!shopifyBlogId) {
          // alert("Shopify selected but shopify blog not selected");
          return;
        }
        exportBody["id_shopify_account"] = currentLinkedAccount.id;
        exportBody["blog_id"] = shopifyBlogId;

        console.log("this is shopify");

        exportUrl = "shopify/export";
        break;
      case "MEDIUM":
        exportBody["id_medium_account"] = currentLinkedAccount.id;

        exportUrl = "medium/export";
        break;
      case "BIG_COMMERCE":
        exportBody["big_commerce_id"] = currentLinkedAccount.id;

        exportUrl = "big_commerce/export";
        break;

      case "WEB_FLOW":
        exportBody["id_web_flow_account"] = currentLinkedAccount.id;

        exportUrl = "web-flow/export";
        break;
      case "DRUPAL":
        exportBody["drupal_account_id"] = currentLinkedAccount.id;

        exportUrl = "drupal/export";
        break;

      default:
        break;
    }

    this.setState({
      exportModalVisible: false,
      exportingDoc: true,
      exportSuccess: true,
    });

    api
      .exportDocument(this.props.token, exportBody, exportUrl)
      .then((result) => {
        this.setState({
          exportingDoc: false,
          exportError: false,
          currentLinkedAccountIndex: -1,
          currentLinkedAccount: "",
        });
        api.getUserInfoAfterLogin(this.props.token).then((result) => {
          this.props.saveDetailsOfMe(result);
        });
      })
      .catch((e) => {
        e.then((res) => {
          console.log(res, "rrrr");
          this.setState({
            exportModalVisible: false,
            exportingDoc: false,
            exportError: true,
            error: res.data,
          });
        });
        console.log("This is the e", e);
        // alert("error occured. Please try again");
      });
  };
  onCancelExportSuccess = () => {
    this.setState({
      exportSuccess: false,
    });
  };
  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState(
      {
        currentPlatform: CONSTANTS.SUCCESS,
      },
      () => {
        api
          .getLinkedAccounts(this.props.token)
          .then((res) => {
            console.log("This is the account res", res);
            this.setState({
              linkedAccountsList: res,
            });
          })
          .catch((error) => {
            console.log("this is error", error);
            // alert(error);
          });
      }
    );
  };
  handleCancel = () => {
    console.log("handel Cancel worked");
    this.setState(
      {
        addWebsiteModalVisible: false,
      },
      () => {
        if (this.state.linkedAccountsList.length < 2) {
          this.setState({
            exportModalVisible: false,
          });
        }
        if (this.state.addWebsiteModalVisible === false) {
          setTimeout(() => {
            this.setState({
              currentPlatform: CONSTANTS.WORDPRESS,
            });
          }, 2000);
        }
      }
    );
  };
  handleOk = () => {};
  getInputForm = () => {
    const { token } = this.props;
    const { currentPlatform } = this.state;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            token={token}
            handleCancel={this.handleCancel}
            className=""
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.WEB_FLOW:
        return (
          <WebFlowForm
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
        break;
      // case CONSTANTS.SHOPIFY:
      //     return (
      //         <ShopifyForm
      //             handleCancel={this.handleCancel}
      //             success={this.onSuccessfulAddition}
      //         />
      //     );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.DRUPAL:
        return (
          <DrupalForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.BIG_COMMERCE:
        return (
          <BigCommerceForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.SUCCESS:
        return <SuccessModal handleCancel={this.handleCancel} />;
      default:
        return "Nothing selected";
    }
  };
  onClickAddWebsite = (e) => {
    e.preventDefault();
    console.log("on click add website");
    this.setState({
      addWebsiteModalVisible: true,
    });
  };
  onSelectDropdownVisible = () => {
    this.setState({
      placeholderError: false,
    });
  };

  render(): any {
    const {
      apiLoading,
      currentPlatform,
      addWebsiteModalVisible,
      exportingDoc,
      exportModalVisible,
      data,
      currentLinkedAccount,
      linkedAccountsList,
      currentItem,
      currentItemIndex,
      shopifyBlogList,
    } = this.state;
    const { profile } = this.props;
    const { email = "" } = profile || {};
    console.log("profile", profile);

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        width: "65%",
        key: "title",
        render: (text, record) => (
          <a href={record.alternate_link} target={"_blank"}>
            {" "}
            <Tooltip
              placement="top"
              title={
                <span>
                  Notion Doc
                  <div
                    className="info-circle"
                    onClick={this.onClickTitleHoverIcon}
                  >
                    <img className="info-icon" src={InfoIcon} alt="info" />
                  </div>
                </span>
              }
            >
              <div className="title-icon-container">
                {/*<i className="fas fa-file-alt"/>*/}
                <img src={FileIcon} alt="doc-icon" />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={
                <span>
                  Notion Doc
                  <div
                    className="info-circle"
                    onClick={this.onClickTitleHoverIcon}
                  >
                    <img className="info-icon" src={InfoIcon} alt="info" />
                  </div>
                </span>
              }
            >
              <p className="table-title-row">{text}</p>
            </Tooltip>
          </a>
        ),
      },
      {
        title: "Last modified",
        dataIndex: "last_edited_time",
        key: "last_edited_time",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div style={{ color: "#676A6E", fontSize: "16px" }}>
              {moment(text).format("MMM DD, YYYY")}
            </div>
          );
        },
      },
      {
        title: "Export",
        dataIndex: "export",
        key: "export",
        width: "15%",
        render: (text, record, index) => {
          return (
            <>
              <Tooltip
                placement="top"
                title={
                  <span>
                    Export Notion Docs
                    <div
                      className="info-circle"
                      onClick={this.onClickExportHoverTooltipIcon}
                    >
                      <img className="info-icon" src={InfoIcon} alt="info" />
                    </div>
                  </span>
                }
              >
                <div
                  className="modified-export-icon-container"
                  onClick={() => {
                    this.props.profile?.remaining_export !== 0 &&
                      this.onClickExport(record.id);
                  }}
                >
                  <p>Export</p>
                  <div
                    className="export-icon"
                    style={{
                      cursor:
                        this.props.profile?.remaining_export === 0
                          ? "not-allowed"
                          : "",
                    }}
                  ></div>
                </div>
              </Tooltip>
            </>
          );
        },
      },
    ];

    let newData = this.props.tableData?.notion_doc_list?.filter(
      (fi) => fi.title !== null
    );

    return (
      <>
        <div className="table-top-container">
          <SearchBar
            setSearchTableData={(d: any) =>
              this.setState({ searchTableData: d })
            }
            searchTableData={this.state.searchTableData}
            setSearchText={(t: string) =>
              this.setState({
                searchText: t,
              })
            }
            setNextBtn={(b: any) => this.setState({ nextBtn: b })}
            paginationTableData={this.state.paginationTableData}
            setPaginationTableData={(d: any) =>
              this.setState({ paginationTableData: d })
            }
            searchText={this.state.searchText}
            loading={(b: any) => this.setState({ tableLoading: b })}
            updateTable={(d: any) => this.props.updateTable(d)}
            // refresh={this.props.refresh}
            setSearchItem={(d: boolean) => this.setState({ searchItem: d })} //to remove text on refresh
            searchItem={this.state.searchItem}
          />
          <div className="table-filter-container">
            {/* <div className="modified-filter" id="modifiedFilter">
              <Select
                getPopupContainer={() =>
                  document.getElementById("modifiedFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: "LAST_MODIFIED" }}
                onChange={this.handleChangeModified}
                className="modified-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value="LAST_MODIFIED">Last modified</Option>
                <Option value="LAST_OPENED">Last opened</Option>
                <Option value="LAST_CREATED">Last created</Option>
              </Select>
            </div> */}
            {/* <div className="page-filter" id="pageFilter">
              <p className="page-filter-selection">Rows per page:</p>
              <Select
                getPopupContainer={() =>
                  document.getElementById("pageFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: 25 }}
                onChange={this.handleChangePagination}
                className="page-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={75}>75</Option>
                <Option value={100}>100</Option>
              </Select>
            </div> */}
          </div>
        </div>
        <div
          id="exportModal"
          style={{ display: addWebsiteModalVisible ? "none" : "block" }}
        >
          {exportModalVisible ? (
            <Modal
              maskClosable={false}
              getContainer={() =>
                document.getElementById("exportModal") || document.body
              }
              wrapClassName="export-modal-container"
              title={null}
              width={746}
              visible={this.state.exportModalVisible}
              onCancel={this.onHandleExportCancel}
              footer={null}
            >
              <h2 className="export-header"> Create a new export </h2>
              <div>
                <div className="box-button-container">
                  <div
                    className="export-to-dropdown-container"
                    id="exportDropdown"
                  >
                    <Select
                      className="ant-export-select"
                      onChange={this.exportSiteChange}
                      onDropdownVisibleChange={this.onSelectDropdownVisible}
                      getPopupContainer={() =>
                        document.getElementById("exportDropdown") ||
                        document.body
                      }
                      placeholder={
                        <p
                          className={
                            this.state.placeholderError
                              ? "ant-select-selection__placeholder_error"
                              : "ant-select-selection__placeholder"
                          }
                        >
                          Choose a site to export to
                        </p>
                      }
                    >
                      {/* {console.log(linkedAccountsList, "dropdown item")} */}
                      {linkedAccountsList.length > 0 ? (
                        linkedAccountsList.map((item, i) => {
                          return (
                            <Option key={i} value={i}>
                              {item.url}
                            </Option>
                          );
                        })
                      ) : (
                        <Option
                          value="no sites added"
                          className="no-site-menu"
                          disabled={true}
                        >
                          No webistes found in your Docpress account
                        </Option>
                      )}
                      <Option
                        value="no sites added"
                        disabled={true}
                        className="select-add-site-button"
                      >
                        <DefaultButton
                          onClick={this.onClickAddWebsite}
                          title={
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <p className="select-dropdown-button-text">
                                Add a new site
                              </p>
                              <span className="plus-button-span">
                                <img
                                  src={PlusImage}
                                  alt="plus_icon"
                                  width="13px"
                                  height="13px"
                                />
                              </span>
                            </div>
                          }
                          className="width-100 height-63 border-radius-0"
                        />
                      </Option>
                    </Select>
                    {addWebsiteModalVisible ? (
                      <Modal
                        maskClosable={false}
                        title={
                          currentPlatform !== CONSTANTS.SUCCESS ? (
                            <Row
                              id="modal"
                              className="display-flex-modal"
                              style={{
                                height: "100px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p className="modal-title grey-1" id="modalTitle">
                                Connect a new{" "}
                              </p>

                              <Dropdown1
                                className="purple-1"
                                title={currentPlatform}
                                changePlatform={this.changePlatform}
                              />

                              <p className="modal-title grey-1"> site </p>
                            </Row>
                          ) : null
                        }
                        width={746}
                        visible={addWebsiteModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        className={classNames(
                          "docpress-modal",
                          currentPlatform === CONSTANTS.SUCCESS
                            ? "padding-0-modal"
                            : null
                        )}
                      >
                        {this.getInputForm()}
                      </Modal>
                    ) : null}
                  </div>

                  {currentLinkedAccount.cms_domain === SHOPIFY ? (
                    <div className="shopify-dropdown" id="shopifyDropdown">
                      <Select
                        getPopupContainer={() =>
                          document.getElementById("shopifyDropdown") ||
                          document.body
                        }
                        placeholder="Select Blog"
                        onChange={this.changeShopifyId}
                      >
                        {shopifyBlogList.map((obj, i) => {
                          return (
                            <Option key={obj.id} value={obj.id}>
                              {obj.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  ) : null}

                  <div className="google-doc-modal">
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="title-icon-container">
                        <img src={FileIcon} alt="doc-icon" />
                      </div>
                      <p className="table-title-row">
                        {/*{newData.length ? (newData[newData.findIndex(x => x.id === this.state.dataKey) === -1 ? 0 : newData.findIndex(x => x.key === this.state.dataKey)].title) : null}*/}
                        {currentItem.title}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <p className="doc-edit">
                        <a
                          href={currentItem.alternate_link}
                          target={"_blank"}
                          style={{ color: "#364699" }}
                        >
                          edit
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="export-modal-button-container">
                  <DefaultButton
                    title="Cancel"
                    scale="medium"
                    color="grey"
                    className="medium-font margin-right-10 border-grey"
                    onClick={(e) => {
                      this.onHandleExportCancel(e);
                    }}
                  />
                  <DefaultButton
                    title="Export"
                    scale="medium"
                    color="purple"
                    className="medium-font border-none"
                    onClick={this.handleExport}
                  />
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
        <div id="googleBrowseModal" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("googleBrowseModal") || document.body
            }
            wrapClassName="google-drive-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  We found no Notion Docs in your Notion Account{" "}
                </h3>
                <p className="google-drive-email-account-display">
                  {" "}
                  Notion account: <span className="email-section">{email}</span>
                </p>
              </Row>
            }
            width={746}
            visible={this.state.browseModalVisible}
            onCancel={this.handleCancelBrowseModal}
            footer={
              <p className="warning-message">
                <span style={{ color: "#DE5138" }}>Attention:</span> Notion
                Press ONLY pulls in Notion Docs.
              </p>
            }
          >
            <div className="modal-body-container">
              <div className="row">
                <div className="column">
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">1</p>
                      </div>
                      <p className="bullet-message">
                        Check your Notion Drive to make sure a Notion Doc
                        exists.
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={NotionPress}
                              alt="driveIcon"
                              width="20px"
                              height="18px"
                              style={{ marginRight: "5px" }}
                            />{" "}
                            Select Workspace
                          </span>
                        }
                        scale="medium"
                        color="white"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
                <div className="column" style={{ alignItems: "flex-end" }}>
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">2</p>
                      </div>
                      <p className="bullet-message">
                        Still not seeing your Notion docs?
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Contact us
                          </span>
                        }
                        scale="medium"
                        color="grey"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div id="titleIconHover" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("titleIconHover") || document.body
            }
            wrapClassName="titleIcon-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  Notion Docs in NotionPress{" "}
                </h3>
                <p className="header-message-section">
                  {" "}
                  All docs you see in NotionPress are previews (links) of your
                  Notion files. This means we don’t store any of the
                  data/content on the document
                </p>
              </Row>
            }
            width={746}
            visible={this.state.titleIconModalVisible}
            onCancel={this.handleCancelTitleIcon}
            footer={null}
          >
            <div className="body-explain-section">
              <h3 className="modal-title grey-1" id="modalTitle">
                {" "}
                What can I do with my Notion Docs?{" "}
              </h3>
              <div className="bullet-section">
                <div
                  className="bullet-container"
                  style={{ display: "inline-flex", flexDirection: "row" }}
                >
                  <div className="bullet-circle">
                    <p className="numbering">A</p>
                  </div>
                  <p className="bullet-message">
                    Export them to your sites{" "}
                    <i
                      className="fas fa-arrow-right"
                      style={{ marginLeft: "5px" }}
                    />
                  </p>
                </div>
                {/* <div
                  className="bullet-container"
                  style={{ display: "inline-flex", flexDirection: "row" }}
                >
                  <div className="bullet-circle">
                    <p className="numbering">B</p>
                  </div>
                  <p className="bullet-message">
                    Download as Markdown{" "}
                    <i
                      className="fas fa-code"
                      style={{ marginLeft: "5px" }}
                    ></i>
                  </p>
                </div> */}
              </div>
            </div>
            <div className="ant-body-footer-message">
              Having trouble finding your Notion Docs?{" "}
              <Link to="#"> Visit our help page </Link>
            </div>
          </Modal>
        </div>
        <div id="markDownIconHover" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("markDownIconHover") || document.body
            }
            wrapClassName="markdownIcon-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  Markdown in NotionPress{" "}
                </h3>
                <p className="header-message-section">
                  {" "}
                  Notion lets you download Notion Docs as Markdown (.md) files.
                  These files preserve the styling in your original Notion doc.
                </p>
              </Row>
            }
            width={746}
            visible={this.state.markDownIconModalVisible}
            onCancel={this.handleCancelMarkdownIcon}
            footer={null}
          >
            <div className="body-explain-section">
              <h3 className="modal-title grey-1" id="modalTitle">
                {" "}
                How to Export{" "}
              </h3>
              <div className="bullet-section">
                <div className="bullet-container">
                  <div className="bullet-circle">
                    <p className="numbering">1</p>
                  </div>
                  <p className="bullet-message">
                    Click the export icon{" "}
                    <i className="fas fa-code" style={{ marginLeft: "5px" }} />
                  </p>
                </div>
                <div
                  className="bullet-container"
                  style={{ marginLeft: "40px" }}
                >
                  <div className="bullet-circle">
                    <p className="numbering">2</p>
                  </div>
                  <p className="bullet-message">Select a website</p>
                </div>
                <div className="bullet-container">
                  <div className="bullet-circle">
                    <p className="numbering">3</p>
                  </div>
                  <p className="bullet-message">Click Export</p>
                </div>
              </div>
            </div>
            <div className="ant-body-footer-message">
              Having trouble with Markdown?{" "}
              <Link to="#"> Visit our help page </Link>
            </div>
          </Modal>
        </div>
        <div id="exportToolTipIconHover" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("exportToolTipIconHover") || document.body
            }
            wrapClassName="exportIcon-tooltip-icon-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  Exports in NotionPress{" "}
                </h3>
                <p className="header-message-section">
                  {" "}
                  NotionPress lets you export Notion doc content to the
                  following platforms: Wordpress, Shopify, and Medium{" "}
                </p>
              </Row>
            }
            width={746}
            visible={this.state.exportTooltipIconModalVisible}
            onCancel={this.handleCancelExportHoverTooltipIcon}
            footer={null}
          >
            <div className="body-explain-section">
              <h3 className="modal-title grey-1" id="modalTitle">
                {" "}
                How to Export{" "}
              </h3>
              <div className="bullet-section">
                <div className="bullet-container">
                  <div className="bullet-circle">
                    <p className="numbering">1</p>
                  </div>
                  <p className="bullet-message">
                    Click the export icon{" "}
                    <i
                      className="fas fa-arrow-right"
                      style={{ marginLeft: "5px" }}
                    />
                  </p>
                </div>
                <div
                  className="bullet-container"
                  style={{ marginLeft: "40px" }}
                >
                  <div className="bullet-circle">
                    <p className="numbering">2</p>
                  </div>
                  <p className="bullet-message">Select a website</p>
                </div>
                <div className="bullet-container">
                  <div className="bullet-circle">
                    <p className="numbering">3</p>
                  </div>
                  <p className="bullet-message">Click Export</p>
                </div>
              </div>
            </div>
            <div className="ant-body-footer-message">
              Having trouble exporting?{" "}
              <Link to="#"> Visit our help page </Link>
            </div>
          </Modal>
        </div>
        <div id="exportSuccess" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("exportSuccess") || document.body
            }
            wrapClassName="export-success-modal-container"
            title={null}
            width={746}
            visible={this.state.exportSuccess}
            onCancel={this.onCancelExportSuccess}
            footer={null}
          >
            <div className="success-modal main-container">
              <div className="inner-bottom-container">
                <div>
                  <div className="success-body-top">
                    <img
                      src={require("../../../assets/images/confetti-left.svg")}
                      alt="confetti-left"
                    />
                    <div className="sussess-export-message">
                      {exportingDoc ? (
                        <>
                          <Header
                            text="Exporting"
                            style={{ marginRight: "10px" }}
                          />
                          <ReactLoading
                            type="spin"
                            color="#364699"
                            height={25}
                            width={25}
                          />
                        </>
                      ) : this.state.exportError ? (
                        <Header text={this.state.error} />
                      ) : (
                        <Header text="Document exported successfully " />
                      )}
                    </div>
                    <img
                      src={require("../../../assets/images/confetti-right.svg")}
                      alt="confetti-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        {this.props.apiLoading || this.state.tableLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "200px",
            }}
          >
            <ReactLoading type="spin" color="#364699" height={25} width={25} />
          </div>
        ) : (
          <>
            <AntTable
              columns={columns}
              rowKey="key"
              locale={{
                emptyText: (
                  <>
                    <div className="error-image-container">
                      <img src={ErrorImage} alt="error-icon" />
                    </div>
                    <span
                      style={{
                        marginTop: "48px",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p className="no-data-message"> No Notion docs found </p>{" "}
                      <div
                        className="info-circle"
                        onClick={this.onClickBrowseModalICon}
                      >
                        <img className="info-icon" src={InfoIcon} alt="info" />
                      </div>
                    </span>
                  </>
                ),
              }}
              pagination={false}
              // scroll={{ y: 550 }}
              // pagination={<CursorPagination data={this.props.tableData} />}
              // pagination={
              //   // { pageSize: this.state.pagination }
              //   {
              //     itemRender: () => (
              //       // <Pagination
              //       //   simple
              //       //   defaultCurrent={1}
              //       //   total={this.state.pagination}
              //       // />
              //     ),
              //   }
              // }
              dataSource={newData}
            />
            {newData?.length ? (
              <div
                style={{
                  marginBottom: "50px",
                }}
              >
                <CursorPagination
                  data={this.props.tableData}
                  setPaginationTableData={(d: any) =>
                    this.setState(
                      this.state.searchText
                        ? { searchTableData: d }
                        : { paginationTableData: d }
                    )
                  }
                  nextBtn={this.state.nextBtn}
                  setNextBtn={(b: any) => this.setState({ nextBtn: b })}
                  paginationTableData={
                    this.state.searchText
                      ? this.state.searchTableData
                      : this.state.paginationTableData
                  }
                  loading={(b: any) => this.setState({ tableLoading: b })}
                  updateTable={(d: any) => this.props.updateTable(d)}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
}

interface TableState {
  nextBtn?: any;
  data?: any;
  pagination?: any;
  loading?: any;
  icon?: number;
  modifiedInitialState?: string;
  searchText?: any;
  tableLoading?: boolean;
  searchTableData?: any;
  paginationTableData?: any;
  searchItem?: any;
  modalVisible?: boolean;
  exportModalVisible: boolean;
  browseModalVisible: boolean;
  titleIconModalVisible?: boolean;
  markDownIconModalVisible?: boolean;
  exportTooltipIconModalVisible?: boolean;
  apiLoading?: boolean;
  linkedAccountsList: {
    id?: number;
    url?: string;
    cms_domain?: string;
  }[];
  selectSiteKey: any;
  dataKey: number;

  currentItem: {
    alternate_link: string;
    title: string;
    id: string;
  };
  currentItemIndex: number;

  currentLinkedAccount?: any;
  currentLinkedAccountIndex: number;

  shopifyBlogList: {
    id: string;
    handle: string;
    title: string;
  }[];
  shopifyBlogId?: string;
  exportSuccess?: boolean;
  exportError?: boolean;
  error?: any;
  exportingDoc?: boolean;
  currentPlatform: string;
  addWebsiteModalVisible?: boolean;
  placeholderError: boolean;
}

interface Props {
  tableData?: any;
  apiLoading: boolean;
  loadingUpdate: any;
  updateTable: any;
  refresh: boolean;
  setRefresh: any;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
    };
  } else {
    return {};
  }
};

interface Props extends RouteComponentProps<any> {
  token: string;

  [profile: string]: any;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotionTable));
