import React, { Component } from "react";
import "./innernavbar.scss";
import { Layout } from "antd";
import TableMenu from "./TableMenu";

const { Content } = Layout;

class InnerNavbar extends Component<Props, CurrentState> {
  constructor(props) {
    super(props);
    this.state = {
      current: "NotionDocs",
    };
  }

  render(): any {
    return (
      <Layout>
        <Content style={{ background: "#FFF", zIndex: 0 }}>
          <TableMenu />
        </Content>
      </Layout>
    );
  }
}

interface CurrentState {
  current: string;
}

interface Props {}

export default InnerNavbar;
