import React, { Component } from "react";
import * as CONSTANTS from "../../../../constants";
import { Modal, Row } from "antd";
import { Dropdown1 } from "../../dropdown/Dropdown1";
import classNames from "classnames";
import WordpressForm from "../../form/wordpress/WordpressForm";
import ShopifyForm from "../../form/shopify/ShopifyForm";
import MediumForm from "../../form/medium/Medium";
import SuccessModal from "../SuccessModal/SuccessModal";

class AddWebsite extends Component<Props, stateStatus> {
  constructor(props) {
    super(props);
    this.state = {
      currentPlatform: CONSTANTS.DRUPAL,
      modalVisible: this.props.modalVisible,
    };
  }

  handleCancel = () => {};
  handleOk = () => {};

  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        currentPlatform: val,
      },
      () => {}
    );
  };
  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState({
      currentPlatform: CONSTANTS.SUCCESS,
    });
  };
  getInputForm = () => {
    const { currentPlatform } = this.state;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            handleCancel={this.handleCancel}
            className=""
            token="token"
            success={this.onSuccessfulAddition}
          />
        );
      // case CONSTANTS.SHOPIFY:
      //     return (
      //         <ShopifyForm
      //             handleCancel={this.handleCancel}
      //             success={this.onSuccessfulAddition}
      //         />
      //     );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.SUCCESS:
        return <SuccessModal />;
      default:
        return "Nothing selected";
    }
  };

  render(): any {
    const { currentPlatform, modalVisible } = this.state;

    return (
      <Modal
        maskClosable={false}
        title={
          currentPlatform !== CONSTANTS.SUCCESS ? (
            <Row id="modal " className="display-flex-modal">
              <p className="modal-title grey-1" id="modalTitle">
                Connect a new{" "}
              </p>

              <Dropdown1
                className="purple-1"
                title={currentPlatform}
                changePlatform={this.changePlatform}
              />

              <p className="modal-title grey-1"> site </p>
            </Row>
          ) : null
        }
        width={746}
        visible={modalVisible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
        className={classNames(
          "docpress-modal",
          currentPlatform === CONSTANTS.SUCCESS ? "padding-0-modal" : null
        )}
      >
        {this.getInputForm()}
      </Modal>
    );
  }
}

interface Props {
  modalVisible?: boolean;
}

interface stateStatus {
  currentPlatform: string;
  modalVisible?: boolean;
}

export default AddWebsite;
