import React, { Component, useEffect, useState } from "react";
import "./style.scss";
import Button from "../../button/Button";
import { Row, Steps } from "antd";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Form from "antd/es/form";
import WebFlowSecondForm from "./WebFlowSecondForm";
import { useHistory } from "react-router";

const { Step } = Steps;

const WebFlowForm = ({ success, handleCancel }) => {
  const history = useHistory();

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (history.location.state?.id !== undefined) {
      setCurrent(1);
    }
  }, []);

  return (
    <div className="webflow-main-container">
      <div className="webflow-form-container">
        <div className="steps-container">
          <Steps size="small" current={current} type="navigation">
            <Step
              icon={<></>}
              title="STEP 1"
              className={classNames(
                "single-step",
                current === 0 ? "active-step" : "inactive-step"
              )}
            />
            <Step
              icon={<></>}
              title="STEP 2"
              className={classNames(
                "single-step",
                current === 1 ? "active-step" : "inactive-step",
                "step-2"
              )}
            />
          </Steps>
        </div>
        <div>
          {current === 0 ? (
            <>
              <div className="step1-container">
                <Button
                  color="purple"
                  className="medium-font border-none"
                  title={"Connect Your Web Flow Account"}
                  onClick={() =>
                    (window.location.href =
                      process.env.REACT_APP_WEB_FLOW_REDIRECT_URL)
                  }
                />
              </div>

              <Button
                title="Cancel"
                color="grey"
                className="medium-font webflow-cancel-btn"
                onClick={handleCancel}
              />
            </>
          ) : (
            <WebFlowSecondForm
              setCurrent={setCurrent}
              success={success}
              handleCancel={handleCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WebFlowForm;
