import React from "react";
import './style.scss';

export default function DefaultHeader(props: Props): JSX.Element {

    return (
        <h2 className={props.className}>{props.title}</h2>
    );
}

interface Props {
    title: string;
    className?: string;
}

