import React, {Component} from "react";
import {Form, Row} from "antd";
import "./style.scss"
import Button from "../../button/Button";
import {WORDPRESS} from "../../../../constants";

export default class DrupalSecondForm extends Component<Props> {
    addSite = () => {
        this.props.success(WORDPRESS);
    };


    render(): any {
        return (
            <div className="drupal-form-container">
                <Form className="form-input">
                    <ol>
                        <p className="drupal-form-steps-description">Complete the following steps in your Drupal admin
                            account</p>
                        <li><p className="drupal-form-list"> Login to your Drupal account</p></li>
                        <li><p className="drupal-form-list">Click “Manage” from top site menu/header</p></li>
                        <li><p className="drupal-form-list">Select “Extend” from the sub menu</p></li>
                        <li><p className="drupal-form-list">Check all web services</p></li>
                        <li><p className="drupal-form-list">Click Install</p></li>
                    </ol>


                    <Row style={{display: "inline-flex", marginBottom: "50px"}}>
                        <Button title="Back" scale="medium" color="grey"
                                className="medium-font margin-right-10 border-grey"
                                onClick={() => this.props.changeStep(0)}/>
                        <Button title="Done" scale="medium" color="purple" className="medium-font border-none"
                                onClick={this.addSite}/>
                    </Row>
                </Form>
            </div>
        );
    }
}

interface Props {
    title?: string;
    className?: string;
    success?: any;
    changeStep?: any
}

