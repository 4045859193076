import React, { Component } from "react";
import { Modal, Row, Select, Table as AntTable } from "antd";
import ErrorImage from "../../../assets/images/empty-state/empty-state_websites.svg";
import { Link } from "react-router-dom";
import "./table.scss";
import { connect } from "react-redux";
import DefaultButton from "../button/Button";
import DeleteIcon from "../../../assets/images/table/icon_trash-can.svg";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import WordPressIcon from "../../../assets/images/table/site-table/icon_wordpress-small.svg";
import MediumIcon from "../../../assets/images/table/site-table/icon_medium-small.svg";
import ShopifyIcon from "../../../assets/images/table/site-table/icon_shopify-small.svg";
import DrupalIcon from "../../../assets/images/table/site-table/icon_drupal-small.svg";
import WebFlowIcon from "../../../assets/images/table/site-table/icon_webflow-small.svg";
import BigCommerceIcon from "../../../assets/images/table/site-table/icon_bigcommerce-small.svg";
import {
  BIG_COMMERCE,
  DRUPAL,
  MEDIUM,
  SHOPIFY,
  WEB_FLOW,
  WORDPRESS,
} from "../../../constants";
import api from "../../../api/index";
import {
  openExportModal,
  reRenderSiteTable,
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import ReactLoading from "react-loading";
import Header from "../Typography/Header";
import addWebsite from "../modal/AddWebsiteModal/AddWebsite";

const { Option } = Select;

const serverData = [
  {
    id: 89,
    url: "https://anujpoudel.home.blog",
    cms_domain: "WORDPRESS",
  },
  {
    id: 14,
    url: "https://daari-store.myshopify.com",
    cms_domain: "SHOPIFY",
  },
];

const data = [
  {
    key: 1,
    title: "Medium Minds",
    site_link: "https://mediumminds.com",
    last_modified: "2.8.19 by Jonh Smith",
  },
  {
    key: 2,
    title: "Keto Snack",
    site_link: "https://ketosnacks.com/home",
    last_modified: "2.8.19 by Jonh Smith",
  },
  {
    key: 3,
    title: "Personal Muse",
    site_link: "https://medium.com/username",
    last_modified: "2.8.19 by Jonh Smith",
  },
  {
    key: 4,
    title: "Personal Muse",
    site_link: "https://baxtonsports.com",
    last_modified: "2.8.19 by Jonh Smith",
  },
  {
    key: 5,
    title: "Travel Guides America",
    site_link: "https://mytravelguidesacrossamerica.com",
    last_modified: "2.8.19 by Jonh Smith",
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];

/*
const data = [];
*/
class SiteTable extends Component<ComponentProps, TableState> {
  constructor(props) {
    super(props);
    this.state = {
      data: serverData,
      sortData: serverData,
      loading: false,
      icon: 1,
      pagination: 25,
      platform: "ALL_PLATFORM",
      browseModalVisible: false,
      deleteModalVisible: false,
      selectSiteKey: "",
      deleteDocumentKey: undefined,
      deleteDocumentTitle: "",
      deletionDocumentIndex: null,
      apiLoading: true,
      deletionDocumentCms: "TEST",
      deleteModalSuccess: false,
      deleteModalContentStatus: false,
    };
  }

  componentDidMount() {
    api.getLinkedAccounts(this.props.token).then((res) => {
      console.log("This is the res", res);
      this.setState({
        data: res,
        sortData: res,
        apiLoading: false,
      });
    });
  }

  onClickAddWebsite = (e) => {
    e.preventDefault();
    console.log("on click add website");
    this.handleCancelBrowseModal(e);
    this.props.onClickAddWebsite(e);
  };
  addWebsite = (e) => {
    console.log("addWebsite", addWebsite);

    /*
                    this.handleCancelBrowseModal(e)
                    this.props.onClickAddWebsite(e)
            */
  };
  onClickDeleteIcon = (url, id, cmsDomain, index) => {
    console.log("this is cms_domain on delete", cmsDomain);
    if (cmsDomain.toLowerCase() === "wordpress") {
      cmsDomain = "word-press";
    } else if (cmsDomain.toLowerCase() === "web_flow") {
      cmsDomain = "web-flow";
    } else {
      cmsDomain = cmsDomain;
    }
    this.setState({
      deleteModalVisible: true,
      deleteDocumentKey: id,
      deleteDocumentTitle: url,
      deletionDocumentIndex: index,
      deletionDocumentCms: cmsDomain,
    });
  };
  onHandleCancelDeleteModal = (e) => {
    e.preventDefault();
    this.setState({
      deleteModalVisible: false,
    });
  };

  onClickBrowseModalICon = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: true,
    });
  };
  handleCancelBrowseModal = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: false,
    });
  };
  handleChangePagination = (value) => {
    this.setState(
      {
        pagination: value.key,
      },
      () => {
        console.log("this is pagination value", this.state.pagination);
      }
    );
    console.log(value);
  };
  handleChangePlatform = (value) => {
    this.setState(
      {
        platform: value.key,
      },
      () => {
        let dataFilter = this.state.sortData;
        console.log("this is dataFilter", dataFilter);
        if (this.state.platform !== "ALL_PLATFORM") {
          let FilterValue = dataFilter.filter(
            (x) => x.cms_domain === this.state.platform
          );
          this.setState({
            data: FilterValue,
          });
        } else {
          this.setState({
            data: this.state.sortData,
          });
        }

        console.log("this is pagination value", this.state.platform);
      }
    );
  };

  handleDelete = (key) => {
    const { deleteDocumentKey, deletionDocumentIndex, deletionDocumentCms } =
      this.state;
    this.setState({
      deleteModalSuccess: true,
      deleteModalVisible: false,
      deleteModalContentStatus: true,
    });

    api
      .deleteCms(
        this.props.token,
        deleteDocumentKey,
        deletionDocumentCms.toLowerCase()
      )
      .then((res) => {
        console.log("This is the res", res);
        if (res.success) {
          this.setState((prevState) => ({
            ...prevState,
            data: [
              ...prevState.data.slice(0, deletionDocumentIndex),
              ...prevState.data.slice(deletionDocumentIndex + 1),
            ],
            deleteModalContentStatus: false,
          }));
        } else {
          // alert("Something went wrong. Please try again");
        }
      });
  };
  onCancelExportSuccess = () => {
    this.setState({
      deleteModalSuccess: false,
    });
  };

  updateDataOnSuccessfulSiteAddition = () => {
    api.getLinkedAccounts(this.props.token).then((res) => {
      console.log("This is the res", res);
      this.setState(
        {
          data: res,
          sortData: res,
          apiLoading: false,
        },
        () => {
          this.props.chengeSuccessStatus(false);
          this.props.reRenderSiteTable(false);

          let dataFilter = this.state.sortData;
          console.log("this is dataFilter", dataFilter);
          if (this.state.platform !== "ALL_PLATFORM") {
            let FilterValue = dataFilter.filter(
              (x) => x.cms_domain === this.state.platform
            );
            this.setState({
              data: FilterValue,
            });
          } else {
            this.setState({
              data: this.state.sortData,
            });
          }

          console.log("this is pagination value", this.state.platform);
        }
      );
    });
  };

  render(): any {
    const { profile } = this.props;
    const { email = "" } = profile || {};
    console.log("profile", profile);
    const columns = [
      {
        title: "Domain",
        dataIndex: "cms_domain",
        width: "10%",
        key: "cms_domain",
        render: (text, record) => (
          <Link to="#">
            <div className="title-icon-container">
              {(() => {
                console.log("this is inside switchcase", record.site_link);
                switch (record.cms_domain) {
                  case WORDPRESS:
                    return <img src={WordPressIcon} alt="wordpress-icon" />;
                  case SHOPIFY:
                    return <img src={ShopifyIcon} alt="shopify-icon" />;
                  case MEDIUM:
                    return <img src={MediumIcon} alt="medium-icon" />;
                  case WEB_FLOW:
                    return <img src={WebFlowIcon} alt="webflow-icon" />;
                  case BIG_COMMERCE:
                    return <img src={BigCommerceIcon} alt="bigcommerce-icon" />;
                  case DRUPAL:
                    return <img src={DrupalIcon} alt="drupal-icon" />;
                  default:
                    return;
                }
              })()}
            </div>
            {/*<p className="table-title-row">{firstLetterCapital(record.cms_domain, "_")}</p>*/}
          </Link>
        ),
      },
      {
        title: "Site link",
        dataIndex: "url",
        width: "70%",
        key: "url",
        render: (text) => (
          <a href={text} target="_blank">
            <p className="page-link">{text}</p>
          </a>
        ),
      },
      {
        title: "Action",
        width: "20%",
        dataIndex: "action",
        key: "action",
        render: (text, record, index) => (
          <>
            <img
              src={DeleteIcon}
              style={{ cursor: "pointer" }}
              alt="delete-icon"
              onClick={() => {
                this.onClickDeleteIcon(
                  record.url,
                  record.id,
                  record.cms_domain,
                  index
                );
              }}
            />
          </>
        ),
        /*
                                                                this.handleDelete(record.key)
                                        */
      },
    ];

    const { apiLoading, deleteModalSuccess, deleteModalContentStatus } =
      this.state;
    console.log("this is reset Table State", this.props.resetTableState);

    return (
      <>
        {this.props.addSuccessStatus === true
          ? this.updateDataOnSuccessfulSiteAddition()
          : null}
        {this.props.resetTableState === true
          ? this.updateDataOnSuccessfulSiteAddition()
          : null}

        <div className="table-top-container">
          <div className="sites-table-filter-container">
            <div className="platform-filter" id="platformFilter">
              <Select
                getPopupContainer={() =>
                  document.getElementById("platformFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: "ALL_PLATFORM" }}
                onChange={this.handleChangePlatform}
                className="platform-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value="ALL_PLATFORM">All Platforms</Option>
                <Option value="WORDPRESS">Wordpress</Option>
                {/*<Option value="SHOPIFY">Shopify</Option>*/}
                <Option value="MEDIUM">Medium</Option>
                {/* <Option value="BIG_COMMERCE">BigCommerce</Option>
                                <Option value="DRUPAL">Drupal</Option> */}
              </Select>
            </div>
            <div className="site-page-filter" id="exportPageFilter">
              <p className="page-filter-selection">Rows per page:</p>
              <Select
                getPopupContainer={() =>
                  document.getElementById("exportPageFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: 25 }}
                onChange={this.handleChangePagination}
                className="page-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={75}>75</Option>
                <Option value={100}>100</Option>
              </Select>
            </div>
          </div>
        </div>

        <div id="deleteModalSuccess" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("deleteModalSuccess") || document.body
            }
            wrapClassName="delete-success-modal-container"
            title={null}
            width={746}
            visible={deleteModalSuccess}
            onCancel={this.onCancelExportSuccess}
            footer={null}
          >
            <div className="success-modal main-container">
              <div className="inner-bottom-container">
                <div>
                  <div className="success-body-top">
                    <img
                      src={require("../../../assets/images/confetti-left.svg")}
                      alt="confetti-left"
                    />
                    <div className="sussess-export-message">
                      {deleteModalContentStatus ? (
                        <>
                          <Header
                            text="Deleting Site"
                            style={{ marginRight: "10px" }}
                          />
                          <ReactLoading
                            type="spin"
                            color="#364699"
                            height={25}
                            width={25}
                          />
                        </>
                      ) : (
                        <Header text="Site Successfully Deleted " />
                      )}
                    </div>
                    <img
                      src={require("../../../assets/images/confetti-right.svg")}
                      alt="confetti-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <div id="googleBrowseModal" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("googleBrowseModal") || document.body
            }
            wrapClassName="sites-drive-browse-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  We found no sites connected to your account
                </h3>
                <p className="google-drive-email-account-display">
                  {" "}
                  Notion Press account:{" "}
                  <span className="email-section">{email}</span>
                </p>
              </Row>
            }
            width={746}
            visible={this.state.browseModalVisible}
            onCancel={this.handleCancelBrowseModal}
            footer={null}
          >
            <div className="modal-body-container">
              <div className="row">
                <div className="column">
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">1</p>
                      </div>
                      <p className="bullet-message">
                        Try connecting a website using the button below.
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        onClick={this.onClickAddWebsite}
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Add a Website
                          </span>
                        }
                        scale="medium"
                        color="green"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
                <div className="column" style={{ alignItems: "flex-end" }}>
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">2</p>
                      </div>
                      <p className="bullet-message">
                        Still having issues connecting your site?
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Contact us
                          </span>
                        }
                        scale="medium"
                        color="grey"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div id="deleSitesModal" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("deleSitesModal") || document.body
            }
            wrapClassName="sites-drive-delete-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  Delete Website ?
                </h3>
                <p className="google-drive-email-account-display">
                  <span className="email-section">
                    {this.state.deleteDocumentTitle}
                  </span>
                </p>
              </Row>
            }
            width={746}
            visible={this.state.deleteModalVisible}
            onCancel={this.onHandleCancelDeleteModal}
            footer={null}
          >
            <div className="modal-body-container">
              <div className="display-button-field">
                <DefaultButton
                  title={
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      Cancel
                    </span>
                  }
                  onClick={this.onHandleCancelDeleteModal}
                  scale="medium"
                  color="grey"
                  className="medium-font button-width-150"
                />
              </div>
              <div className="display-button-field">
                <DefaultButton
                  title={
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      Delete
                    </span>
                  }
                  scale="medium"
                  color="green"
                  onClick={() =>
                    this.handleDelete(this.state.deleteDocumentKey)
                  }
                  className="medium-font button-width-150"
                />
              </div>
            </div>
          </Modal>
        </div>
        {apiLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "200px",
            }}
          >
            <ReactLoading type="spin" color="#43D17F" height={25} width={25} />
          </div>
        ) : (
          <AntTable
            columns={columns}
            locale={{
              emptyText: (
                <>
                  <div className="sites-error-image-container">
                    <img src={ErrorImage} alt="error-icon" />
                  </div>
                  <span
                    style={{
                      marginTop: "48px",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="no-data-message"> No sites added yet </p>{" "}
                    <div
                      className="info-circle"
                      onClick={this.onClickBrowseModalICon}
                    >
                      <img className="info-icon" src={InfoIcon} alt="info" />
                    </div>
                  </span>
                </>
              ),
            }}
            /*scroll={{ y: 500 }}*/ pagination={{
              pageSize: this.state.pagination,
            }}
            dataSource={this.state.data}
          />
        )}
      </>
    );
  }
}

interface Props {
  token: string;
}

interface ComponentDispatchProps {}

interface ComponentStateProps {
  token: string;
  resetTableState?: boolean;
  reRenderSiteTable?: any;
  openExportModal?: any;

  [profile: string]: any;
}

interface ComponentOwnProps {
  addSuccessStatus: boolean;
  chengeSuccessStatus: any;
  onClickAddWebsite?: any;
}

type ComponentProps = ComponentStateProps &
  ComponentDispatchProps &
  ComponentOwnProps;

interface TableState {
  data?: any;
  sortData?: any;
  pagination?: any;
  platform?: any;
  loading?: any;
  icon?: number;
  modalVisible?: boolean;
  browseModalVisible?: boolean;
  apiLoading?: boolean;
  deleteModalVisible?: boolean;
  selectSiteKey?: any;
  deleteDocumentKey?: number;
  deleteDocumentTitle?: string;
  deletionDocumentCms: string;
  deletionDocumentIndex?: any;
  deleteModalSuccess?: boolean;
  deleteModalContentStatus?: boolean;
}

const mapDispatchToProps: ComponentDispatchProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
  reRenderSiteTable: reRenderSiteTable,
  openExportModal: openExportModal,
};

const mapStateToProps = (state): ComponentStateProps => {
  if (state.selfReducers.serverToken) {
    return {
      resetTableState: state.selfReducers.resetTableState,
      profile: state.selfReducers.profile,
      token: state.selfReducers.serverToken,
    };
  } else {
    return {
      resetTableState: false,
      token: "",
      profile: {
        email: "",
      },
    };
  }
};

export default connect<
  ComponentStateProps,
  ComponentDispatchProps,
  ComponentOwnProps,
  TableState
>(
  mapStateToProps,
  mapDispatchToProps
)(SiteTable);
