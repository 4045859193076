import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import InnerNavbar from "../innerNavbar/InnerNavbar";
import RightDrawer from "../drawer/RightDrawer";
import NotionDoc from "../table/NotionDoc";
import ExportDoc from "../table/ExportDoc";
import SiteDoc from "../table/SiteDoc";
import FooterIcon from "../table/FooterIcon";
import Account from "../account/Account";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import LoginRouting from "../../../routes";
import { IntercomProvider } from "react-use-intercom";

class DemoComponent extends Component<Props, CurrentState> {
  constructor(props) {
    super(props);
    this.state = {};
    console.log("demo page");
  }

  componentDidMount() {}

  render(): any {
    return (
      <>
        <IntercomProvider
          autoBootProps={{ email: this.props.profile.email }}
          autoBoot
          appId={process.env.REACT_APP_INTERCOM_APP_ID}
        >
          <RightDrawer />
          <InnerNavbar />
          <Switch>
            <LoginRouting exact path="/documents" component={NotionDoc} />
            <LoginRouting exact path="/exports" component={ExportDoc} />
            <LoginRouting exact path="/websites" component={SiteDoc} />
            <LoginRouting exact path="/account" component={Account} />
          </Switch>
          {/* <FooterIcon /> */}
          {/*table*/}

          {/*            <Switch>
                <Route exact path='/demo/gdocs' component={GoogleDoc}/>
                <Route exact path='/demo/exports' component={ExportDoc}/>
                <Route exact path='/demo/sites' component={SiteDoc}/>
            </Switch>*/}
        </IntercomProvider>
      </>
    );
  }
}

interface Props extends RouteComponentProps<any> {
  token: string;
  profile: any;
  saveDetailsOfMe: any;
  saveServerToken: any;
}

const mapDispatchToProps = {
  saveServerToken: saveServerToken,
  saveDetailsOfMe: saveDetailsOfMe,
};

interface CurrentState {}

const mapStateToProps = (state) => {
  console.log("This is the state", state);
  if (state?.selfReducers?.serverToken) {
    return {
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
    };
  } else {
    return {};
  }
};

export default withRouter(connect(mapStateToProps)(DemoComponent));
