import React, {Component} from "react";
import "./style.scss";
import {Steps} from 'antd';
import classNames from 'classnames';
import BigCommerceFirstForm from "./BigCommerceFirstForm";
import BigCommerceSecondForm from "./BigCommerceSecondForm";
import {Link} from "react-router-dom";

const {Step} = Steps;


class BigCommerceForm extends Component<Props, VisibleState> {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            api_path: "",
            access_token: "",
            client_secret: "",
            client_id: "",
            website: "",
        }
    }

    handleChangeWebsite = (value) => {
        this.setState({
            website: value
        }, () => {
            console.log("this is handleChangeWebsite ", this.state.website);

        });
    };
    handleChangeApiPath = (value) => {
        this.setState({
            api_path: value
        }, () => {
            console.log("this is api_path", this.state.api_path);
        });
    };
    handleChangeAccessToken = (value) => {
        this.setState({
            access_token: value
        }, () => {
            console.log("this is api_path", this.state.access_token);
        });
    };
    handleChangeClientId = (value) => {
        this.setState({
            client_id: value
        }, () => {
            console.log("this is api_path", this.state.client_id);
        });
    };
    handleChangeClientSecret = (value) => {
        this.setState({
            client_secret: value
        }, () => {
            console.log("this is api_path", this.state.client_secret);
        });
    };
    changeStep = (step) => {
        this.setState({
            current: step
        });
    };


    render(): any {
        const {current} = this.state;
        const {api_path, access_token, client_secret, client_id, website} = this.state;
        const values = {api_path, access_token, client_secret, client_id, website};

        const {token} = this.props;
        return (
            <div className="bigcommerce-main-container">

                <div className="bigcommerce-form-container">
                    <div className="steps-container">
                        <Steps size="small" current={current} type="navigation">
                            <Step icon={<></>} title="STEP 1" className="single-step active-step"/>
                            <Step icon={<></>} title="STEP 2"
                                  className={classNames("single-step", current === 1 ? "active-step" : "inactive-step", "step-2")}/>
                        </Steps>
                    </div>
                    <div className="link-container">
                        {/* eslint-disable-next-line react/jsx-no-undef */}
                        {current === 0 ? <p className="video-tutorial"> Watch video tutorial <Link to="#">here</Link>
                        </p> : ""}
                    </div>
                </div>

                {current === 0 ?
                    <BigCommerceFirstForm handleCancel={this.props.handleCancel} changeStep={this.changeStep}/>
                    :
                    <BigCommerceSecondForm handleChangeWebsite={this.handleChangeWebsite}
                                           handleChangeApiPath={this.handleChangeApiPath}
                                           handleChangeClientId={this.handleChangeClientId}
                                           handleChangeAccessToken={this.handleChangeAccessToken}
                                           handleChangeClientSecret={this.handleChangeClientSecret}
                                           values={values} token={token} changeStep={this.changeStep}
                                           success={this.props.success}/>
                }

            </div>
        );
    }
}

interface Props {
    success?: any;
    handleCancel?: any;
    token: string

}

interface VisibleState {
    current: number;
    api_path?: string;
    access_token?: string;
    client_secret?: string;
    client_id?: string;
    website?: string;
}

export default BigCommerceForm;
