import { Button } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux-stuffs/store/store";
import api from "../../../api";
import "./table.scss";

const CursorPagination = ({
  data,
  updateTable,
  loading,
  setPaginationTableData,
  paginationTableData,
  nextBtn,
  setNextBtn,
}) => {
  const { serverToken } = useSelector<RootState, any>(
    (state) => state?.selfReducers
  );

  const limit = 25;

  console.log(
    "data",
    Array?.from(Array(paginationTableData.pageCount)),
    { nextBtn, ...paginationTableData },
    ";"
  );
  return (
    <div id="cursor-pagination">
      {nextBtn !== 0 && (
        <Button
          style={{ marginRight: "7px" }}
          onClick={() => {
            setNextBtn(nextBtn - 1);
            let nextBtnCounter = nextBtn - 1;

            const data = paginationTableData.data[
              nextBtnCounter
            ]?.notion_doc_list?.slice(
              // Math.abs(0 - nextBtnCounter * 4) * limit,
              // (0 + 1 - nextBtnCounter * 4) * limit
              0,
              25
            );

            setPaginationTableData({
              ...paginationTableData,
              data: paginationTableData.data.slice(0, nextBtn),
              currentIndex: paginationTableData.currentIndex - 4,
              pageCount: paginationTableData.pageCount - 4,
            });

            updateTable({
              id: paginationTableData.data[nextBtnCounter].id,
              notion_doc_list: data,
            });

            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          Prev
        </Button>
      )}

      {Array.from(Array(paginationTableData?.pageCount))
        .map((i: any, index) => (
          <Button
            key={index}
            style={{
              marginRight: "7px",
              backgroundColor:
                paginationTableData.currentIndex === index ? "#364699" : "",
              color:
                paginationTableData.currentIndex === index
                  ? "white"
                  : "inherit",
            }}
            onClick={() => {
              if (paginationTableData.currentIndex !== index) {
                const data = paginationTableData.data[
                  nextBtn
                ]?.notion_doc_list?.slice(
                  Math.abs(index - nextBtn * 4) * limit,
                  (index + 1 - nextBtn * 4) * limit
                );

                loading(true);

                updateTable({
                  id: paginationTableData.data[nextBtn].id,
                  notion_doc_list: data,
                });

                setPaginationTableData({
                  ...paginationTableData,
                  currentIndex: index,
                });

                loading(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }
            }}
          >
            {index + 1}
          </Button>
        ))
        .slice(nextBtn * 4, (nextBtn + 1) * 4)}

      {paginationTableData?.data?.find((i: any) => i.id === data.id)
        ?.has_more === true && (
        <Button
          onClick={async () => {
            setNextBtn(nextBtn + 1);
            loading(true);

            await api
              .getGoogleDocList(
                serverToken,
                paginationTableData?.data?.find((i: any) => i.id === data.id)
                  ?.next_cursor
              )
              .then((res) => {
                let pageCounter =
                  paginationTableData.pageCount +
                  Math.ceil(res.notion_doc_list.length / 25);

                setPaginationTableData({
                  pageCount: pageCounter,
                  currentIndex: pageCounter - 4,
                  data: [
                    ...paginationTableData.data,
                    {
                      id: paginationTableData.data.length + 1,
                      next_cursor: res.next_cursor,
                      notion_doc_list: res.notion_doc_list,
                      has_more: res.has_more,
                    },
                  ],
                });

                updateTable({
                  id: paginationTableData.data.length + 1,
                  notion_doc_list: res.notion_doc_list.slice(0, 25),
                });

                loading(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              });
          }}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default CursorPagination;
