import React, {Component} from "react";
import './index.scss';
import Header from "../../Typography/Header";
import Paragraph from "../../Typography/Paragraph";
import DefaultButton from "../../button/Button";
import {RouteComponentProps, withRouter} from "react-router-dom";

class SuccessModal extends Component<Props> {
    constructor(props) {
        super(props);
        console.log("This is the props", props);
        console.log("This is the props", JSON.stringify(props));
    }

    onClickSuccessModalButton = () => {
        console.log("this is website location", window.location.pathname);
        if (window.location.pathname === "/websites") {
            this.props.history.push({
                pathname: "/websites"
            });
            this.props.handleCancel();
            /*
                        window.location.pathname= window.location.pathname
            */
        } else {
            this.props.handleCancel();
            this.props.history.push({
                pathname: "/documents"
            });
        }

    };

    render(): any {
        return (
            <div className="success-modal main-container">
                <div className="bottom-container">


                    <div>
                        <div className="body-top">
                            <img src={require("../../../../assets/images/confetti-left.svg")} alt="confetti-left"/>
                            <div>
                                <Header text="Site added!"/>
                                <Paragraph text="Now you can start exporting"/>
                            </div>
                            <img src={require("../../../../assets/images/confetti-right.svg")} alt="confetti-right"/>
                        </div>
                    </div>


                    <div>
                        <DefaultButton
                            onClick={this.props.handleCancel && this.onClickSuccessModalButton}
                            title={"Get Started"}
                            style={{marginTop: 45}}
                            scale={"large"}
                            color={"purple"}
                            className="medium-font"
                        />
                    </div>

                </div>
            </div>
        );
    }
}

interface Props extends RouteComponentProps<any> {
    handleCancel?: any
}

export default withRouter(SuccessModal);
