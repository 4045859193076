import env, { envType } from "./config/index";
import MockApi from "./MockApi";
import Implementation from "./implementation";

let currentUrl = "";

let api;
let localUrl = "http://localhost:3000";
// let serverUrl = "https://notionpress.ktm.yetiappcloud.com";
const serverUrl = process.env.REACT_APP_SERVER_URL;
// let serverUrl = "http://localhost:5000";

switch (env) {
  case envType.LOCAL:
    // api = new Implementation('http://192.168.1.20:8080');
    api = new Implementation(serverUrl);
    // currentUrl = localUrl;
    // api = new Implementation('https://core.docpress.io');

    break;
  case envType.DEV:
    api = new Implementation(
      "http://ec2-34-207-220-193.compute-1.amazonaws.com:8080"
    );
    break;
  case envType.PROD:
    api = new Implementation("http://example.com/api");
    break;
  case envType.MOCK:
  default:
    api = new MockApi();
}

export default api;
// export const url = currentUrl;
