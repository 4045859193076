import React, {Component} from "react";
import "./table.scss"
import DefaultHeader from "../Header/DefaultHeader";
import ExportTable from "./ExportTable";


class ExportDoc extends Component<Props, stateStatus> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectSiteKey: "",
            emptyData: true,
            lastExoport: "",
        }
    }

    exportTableStatus = (data) => {
        console.log("this is data", data);
        this.setState({
            emptyData: data
        }, () => {
            console.log("this is emptydata", this.state.emptyData);
        })
    };

    lastExportDate = (date) => {
        console.log("this is data exportdate data", date);
        this.setState({
            lastExoport: date
        }, () => {
            console.log("lastExport", this.state.lastExoport);

        });
    };

    render(): any {
        // @ts-ignore
        // @ts-ignore
        return (
            <div className="export-table-container">
                <div className="drive-detail-container">
                    <div className="drive-email-section">
                        <DefaultHeader title="Export History" className="header-2"/>
                        <p className="drive-email" style={{display: this.state.emptyData ? "none" : "flex"}}> Last
                            export: {this.state.lastExoport} </p>
                    </div>
                </div>
                <ExportTable exportTableStatus={this.exportTableStatus} lastExportDate={this.lastExportDate}/>
            </div>);
    }
}

interface stateStatus {
    selectSiteKey: any,
    emptyData?: boolean,
    lastExoport?: string
}

interface Props {

}

export default ExportDoc;