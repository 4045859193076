import React, { Component } from "react";
import "./innernavbar.scss";
import {
  Avatar,
  Dropdown,
  Input,
  Menu,
  Modal,
  Row,
  Select,
  Table as AntTable,
} from "antd";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import DefaultButton from "../button/Button";
import {
  logout,
  openExportModal,
  reRenderExportTable,
  reRenderSiteTable,
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import { connect } from "react-redux";
import api from "../../../api";
import {
  firstLetterCapital,
  getInitials,
} from "../../../utils/CommonFunctions";
import BrandLogo from "../../../assets/images/Logo_Notionpress.png";
import PlusImage from "../../../assets/icons/website/home page/faq-plus-purple.svg";
import * as CONSTANTS from "../../../constants";
import { SHOPIFY, WORDPRESS } from "../../../constants";
import { Dropdown1 } from "../dropdown/Dropdown1";
import classNames from "classnames";
import FileIcon from "../../../assets/images/table/icon_gdoc.svg";
import WordpressForm from "../form/wordpress/WordpressForm";
import ShopifyForm from "../form/shopify/ShopifyForm";
import MediumForm from "../form/medium/Medium";
import DrupalForm from "../form/Drupal/DrupalForm";
import BigCommerceForm from "../form/bigcommerce/BigCommerceForm";
import SuccessModal from "../modal/SuccessModal/SuccessModal";
import PlusButton from "../../../assets/icons/website/home page/faq-plus.svg";
import BackButton from "../../../assets/images/icon_arrow-left.svg";
import SearchIcon from "../../../assets/images/table/icon_search.svg";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import moment from "moment";
import ErrorImage from "../../../assets/images/empty-state/empty-state_Gdocs.svg";
import Header from "../Typography/Header";
import ReactLoading from "react-loading";
import CursorPagination from "../table/cursorPagination";
import SearchBar from "../table/searchBar";
import WebFlowForm from "../form/webflow/WebFlowForm";
import NewExport from "./NewExport";

const { Option } = Select;

class TableMenu extends Component<Props, CurrentState> {
  constructor(props) {
    super(props);
    this.state = {
      nextBtn: 0,
      searchText: "",
      searchItem: false,
      // data: this.props.tableData,
      // tableLoading: false,
      paginationTableData: [],
      searchTableData: [],
      current: "/demo/gdocs",
      username: "",
      data: [],
      pagination: 25,
      modifiedInitialState: "LAST_MODIFIED",
      selectSiteKey: "",
      placeholderError: false,
      selectDocError: false,
      dataKey: 1,
      apiLoading: false,
      linkedAccountsList: [
        {
          id: undefined,
          url: undefined,
          cms_domain: undefined,
        },
      ],

      currentItem: {
        alternate_link: "",
        title: "",
        id: "",
      },
      currentItemIndex: -1,

      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
      exportSuccess: false,
      exportingDoc: false,
      currentPlatform: CONSTANTS.WORDPRESS,

      addWebsiteModalVisible: false,
      exportModalVisible: false,
      docSelectModalVisible: false,
      resetDropdown: false,
      addSuccessWebsite: false,
      resetSiteTable: false,
      resetExportTable: false,
    };
  }

  logout = () => {
    api.userLogout(this.props.token).then((res) => {
      this.props.logout();
      window.location.href = res.message;
    });
  };
  onClickAddNewExport = () => {
    this.setState({
      tableLoading: true,
      exportModalVisible: true,
    });
    this.props.openExportModal(true);

    api
      .getLinkedAccounts(this.props.token)
      .then((res) => {
        console.log("This is the account res", res);
        this.setState({
          linkedAccountsList: res,
        });
      })
      .catch((error) => {
        console.log("this is error", error);
        // alert(error);
      });

    api
      .getGoogleDocList(this.props.token)
      .then((res) => {
        this.setState({
          tableLoading: false,
          paginationTableData: {
            pageCount: Math.ceil(res.notion_doc_list.length / 25),
            currentIndex: 0,
            data: [
              {
                id: 1,
                next_cursor: res.next_cursor,
                notion_doc_list: res.notion_doc_list,
                has_more: res.has_more,
              },
            ],
          },
        });
        console.log("This is doclist res", res);
        this.setState({
          data: {
            ...res,
            id: 1,
            notion_doc_list: res.notion_doc_list.slice(0, 25),
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  onClickDocSelectDiv = () => {
    this.setState(
      {
        docSelectModalVisible: true,
        searchItem: "",
        searchText: "",
      },
      () => {
        console.log(
          "this is docSelectModalVisible",
          this.state.docSelectModalVisible
        );
      }
    );
  };
  onCancelSelectDocModal = () => {
    this.setState({
      docSelectModalVisible: false,
    });
  };

  componentDidMount() {
    // api.getUserInfoAfterLogin(this.props.token).then((res) => {
    // console.log("This is userInfo response", res);
    this.setState({
      username: this.props.profile.name,
    });

    // api.getLinkedAccounts(this.props.token).then((res) => {
    //   console.log("This is the account res", res);
    //   this.setState({
    //     linkedAccountsList: res,
    //   });
    // });
    //       .catch((error) => {
    //         console.log("this is error", error);
    //         /*
    //                 window.location.href = "https://wordpress-335777-1033023.cloudwaysapps.com/";
    // */
    //       });
  }

  // componentDidUpdate(
  //   prevProps: Readonly<Props>,
  //   prevState: Readonly<CurrentState>
  // ) {
  //   if (prevProps.exportModalState !== this.props.exportModalState) {
  //     if (this.props.exportModalState) {
  //       this.onClickAddNewExport();
  //     }
  //   }
  // }

  resetDropDownFunction = (data) => {
    this.setState(
      {
        resetDropdown: data,
      },
      () => {
        console.log(
          "this is resetDropdown inside Table Menu",
          this.state.resetDropdown
        );
      }
    );
  };
  handleClick = (e) => {
    console.log("click ", e);
    this.setState(
      {
        current: e.key,
      },
      () => {
        console.log("this is current", this.state.current);
      }
    );
  };
  onHandleExportCancel = (e) => {
    e.preventDefault();
    this.props.openExportModal(false);
    this.setState({
      exportModalVisible: false,
      currentItem: {
        alternate_link: "",
        title: "",
        id: "",
      },
      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
    });
  };
  exportSiteChange = async (i) => {
    console.log("This is the i", i);
    i = parseInt(i);
    this.setState(
      {
        currentLinkedAccountIndex: i,
        currentLinkedAccount: this.state.linkedAccountsList[i],
      },
      () => {
        console.log(
          "This is the this.state.currentLinkedAccountIndex",
          this.state.currentLinkedAccountIndex
        );

        console.log(
          "This is the this.state.currentLinkedAccount",
          this.state.currentLinkedAccount
        );
        const { currentLinkedAccount } = this.state;
        console.log(
          "This is the currentLinkedAccount.cms_domain",
          currentLinkedAccount.cms_domain
        );
        if (currentLinkedAccount.cms_domain === SHOPIFY) {
          try {
            let shopifyBlogsArray = api.getShopifyBlogList(
              this.props.token,
              currentLinkedAccount.id
            );
            console.log("This is the shopifyBlogList", shopifyBlogsArray.id);
            // shopifyBlogList = shopifyBlogList.json();
            shopifyBlogsArray
              .then((res) => {
                console.log("This is shopifyBlogList res", res);
                this.setState({
                  shopifyBlogList: res,
                });
              })
              .catch((err) => {
                console.log("This is the err after json making", err);
                err.then((finalErr) => {
                  console.log("This is the finalErr", finalErr);
                });
              });
          } catch (e) {
            console.log("This is the e", e);
          }
        }
      }
    );
  };

  onSelectDropdownVisible = () => {
    this.setState({
      placeholderError: false,
    });
  };

  changeShopifyId = (val) => {
    console.log("This is the val", val);
    this.setState({
      shopifyBlogId: val,
    });
  };

  onClickAddWebsite = (e) => {
    e.preventDefault();
    console.log("on click add website");
    this.setState({
      addWebsiteModalVisible: true,
      addSuccessWebsite: false,
      resetSiteTable: false,
      resetExportTable: false,
    });
  };

  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        currentPlatform: val,
      },
      () => {}
    );
  };

  handleChangePagination = (value) => {
    this.setState(
      {
        pagination: value.key,
      },
      () => {
        console.log("this is pagination value", this.state.pagination);
      }
    );
    console.log(value);
  };

  handleCancel = () => {
    console.log("handel Cancel worked");
    this.setState(
      {
        addWebsiteModalVisible: false,
        resetDropdown: true,
      },
      () => {
        if (
          this.state.linkedAccountsList.length < 2 &&
          this.state.addSuccessWebsite
        ) {
          this.setState({
            exportModalVisible: false,
          });
        }
        setTimeout(() => {
          this.setState({
            currentPlatform: CONSTANTS.WORDPRESS,
          });
        }, 2000);
      }
    );
  };
  handleOk = () => {};
  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState(
      {
        currentPlatform: CONSTANTS.SUCCESS,
        addSuccessWebsite: true,
        resetSiteTable: true,
      },
      () => {
        this.props.reRenderSiteTable(this.state.resetSiteTable);
        // api
        //   .getLinkedAccounts(this.props.token)
        //   .then((res) => {
        //     console.log("This is the account res", res);
        //     this.setState({
        //       linkedAccountsList: res,
        //     });
        //   })
        //   .catch((error) => {
        //     console.log("this is error", error);
        //     alert(error);
        //   });
      }
    );
  };

  getInputForm = () => {
    const { token } = this.props;
    const { currentPlatform } = this.state;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            token={token}
            handleCancel={this.handleCancel}
            className=""
            success={this.onSuccessfulAddition}
          />
        );
      // case CONSTANTS.SHOPIFY:
      //   return (
      //     <ShopifyForm
      //       handleCancel={this.handleCancel}
      //       success={this.onSuccessfulAddition}
      //     />
      //   );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.DRUPAL:
        return (
          <DrupalForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.BIG_COMMERCE:
        return (
          <BigCommerceForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.WEB_FLOW:
        // return (
        //   <BigCommerceForm
        //     token={token}
        //     handleCancel={this.handleCancel}
        //     success={this.onSuccessfulAddition}
        //   />
        // );
        return (
          <WebFlowForm
            success={this.onSuccessfulAddition}
            handleCancel={this.handleCancel}
          />
        );

      case CONSTANTS.SUCCESS:
        return <SuccessModal handleCancel={this.handleCancel} />;
      default:
        return "Nothing selected";
    }
  };

  handleExport = () => {
    const {
      currentLinkedAccount,
      currentLinkedAccountIndex,
      currentItem,
      currentItemIndex,
      shopifyBlogId,
    } = this.state;
    console.log("This is the currentLinkedAccount", currentLinkedAccount);
    console.log(
      "This is the currentLinkedAccountIndex",
      currentLinkedAccountIndex
    );
    console.log("This is the currentItem", currentItem);
    console.log("This is the currentItemIndex", currentItemIndex);

    if (currentLinkedAccountIndex < 0) {
      /*
                                                      // alert("Please select a site");
                                          */
      this.setState({
        placeholderError: true,
      });
      return;
    }
    if (currentItemIndex < 0) {
      this.setState({
        selectDocError: true,
      });
      return;
    }

    let exportBody = {};
    let exportUrl = "";

    exportBody["doc_name"] = currentItem.title;
    exportBody["file_id"] = currentItem.id;

    console.log("PODD", currentLinkedAccount.cms_domain);

    switch (currentLinkedAccount.cms_domain) {
      case WORDPRESS:
        exportBody["status"] = "draft";
        exportBody["wordpress_account_id"] = currentLinkedAccount.id;
        exportBody["page_or_post"] = "post";

        exportUrl = "word-press/export";
        break;
      case "SHOPIFY":
        if (!shopifyBlogId) {
          // alert("Shopify selected but shopify blog not selected");
          return;
        }
        exportBody["id_shopify_account"] = currentLinkedAccount.id;
        exportBody["blog_id"] = shopifyBlogId;

        console.log("this is shopify");

        exportUrl = "shopify/export";
        break;
      case "MEDIUM":
        exportBody["id_medium_account"] = currentLinkedAccount.id;

        exportUrl = "medium/export";
        break;
      case "BIG_COMMERCE":
        exportBody["big_commerce_id"] = currentLinkedAccount.id;

        exportUrl = "big_commerce/export";
        break;

      case "WEB_FLOW":
        exportBody["id_web_flow_account"] = currentLinkedAccount.id;

        exportUrl = "web-flow/export";
        break;
      case "DRUPAL":
        exportBody["drupal_account_id"] = currentLinkedAccount.id;

        exportUrl = "drupal/export";
        break;
      default:
        break;
    }

    this.setState({
      exportModalVisible: false,
      exportingDoc: true,
      exportSuccess: true,
    });

    api
      .exportDocument(this.props.token, exportBody, exportUrl)
      .then((result) => {
        this.setState(
          {
            exportingDoc: false,
            currentLinkedAccountIndex: -1,
            currentLinkedAccount: "",
            currentItem: {
              alternate_link: "",
              title: "",
              id: "",
            },
            resetExportTable: true,
          },
          () => {
            this.props.reRenderExportTable(this.state.resetExportTable);
          }
        );
      })
      .catch((e) => {
        console.log("This is the e", e);
        // alert("error occured. Please try again");
      });
  };
  onClickExport = (id) => {
    console.log("this is data index", id);
    const { data } = this.state;
    this.setState({
      docSelectModalVisible: false,
      dataKey: id,
      currentItem: data.notion_doc_list.find((i: any) => i.id === id),
      currentItemIndex: data.notion_doc_list.findIndex((i: any) => i.id === id),
    });
  };
  searchText = (e) => {
    this.setState({
      searchItem: this.state.searchText,
    });
  };
  updateSearchText = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        searchItem: e.target.value,
      },
      () => {
        console.log("this is searchText", this.state.searchText);
      }
    );
  };
  keyPressEnter = (e) => {
    if (e.key === "Enter") {
      this.searchText(e);
    }
  };
  onCancelExportSuccess = () => {
    this.setState({
      exportSuccess: false,
    });
  };

  render(): any {
    const {
      apiLoading,
      resetDropdown,
      currentPlatform,
      addWebsiteModalVisible,
      exportingDoc,
      exportModalVisible,
      data,
      currentLinkedAccount,
      linkedAccountsList,
      currentItem,
      currentItemIndex,
      shopifyBlogList,
      docSelectModalVisible,
    } = this.state;
    console.log("this is currentItem", currentItem.title.length);

    const menu = (
      <Menu>
        <Menu.Item
          key="1"
          onClick={() => this.props.history.push("/account")}
          style={{ fontSize: "16px" }}
        >
          <Link to="/account">Account</Link>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <Link to="#">Settings</Link>
        </Menu.Item> */}
        <Menu.Item key="3" style={{ fontSize: "16px" }} onClick={this.logout}>
          <Link to="#">Log out</Link>
        </Menu.Item>
      </Menu>
    );
    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        width: "100%",
        key: "title",
        render: (text, record, index) => (
          <div className="doc-select-table-title-container">
            <div
              className="title-icon-container"
              onClick={() => {
                this.onClickExport(record.id);
              }}
            >
              <img src={FileIcon} alt="doc-icon" />
            </div>
            <p
              className="table-title-row"
              onClick={() => {
                this.onClickExport(record.id);
              }}
            >
              {text}
            </p>
          </div>
        ),
      },
      {
        title: "Last modified",
        dataIndex: "last_edited_time",
        key: "last_edited_time",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div style={{ color: "#676A6E", fontSize: "16px" }}>
              {moment(text).format("MMM DD, YYYY")}
            </div>
          );
        },
      },
      // {
      //   title: "Author",
      //   dataIndex: "owner_name",
      //   width: "17%",
      //   key: "owner_name",
      //   render: (text) => (
      //     <div className="doc-select-table-author-container">
      //       <div className="author-icon-container">
      //         <p>{getInitials(text, " ")}</p>
      //       </div>
      //       <p className="table-title-author">
      //         {firstLetterCapital(text, " ")}
      //       </p>
      //     </div>
      //   ),
      // },
      // {
      //   title: "Last modified",
      //   dataIndex: "last_modifying_user_name",
      //   key: "last_modifying_user_name",
      //   width: "38%",
      //   render: (text, record, index) => {
      //     return (
      //       <>
      //         <p className="table-title-last-modified">
      //           {moment(Date.now()).format("MM.DD.YY") + " "}
      //           by {record.last_modifying_user_name || record.owner_name}
      //         </p>
      //       </>
      //     );
      //   },
      // },
    ];

    let newData = this.state.data?.notion_doc_list?.filter(
      (fi) => fi.title !== null
    );

    console.log(data.notion_doc_list, "iii");
    return (
      <>
        {(this.props.profile?.user_type === "TRIAL" ||
          this.props.profile?.user_type === "PAYMENT_PENDING") && (
          <div className="payment-banner">
            <p>
              You are using trial account. You have{" "}
              {this.props.profile?.remaining_export} remaining exports.{" "}
              <span onClick={() => this.props.history.push("/plans")}>
                Upgrade your Account Now
              </span>
            </p>
          </div>
        )}
        <div className="layout-menu-header">
          <div className="logo">
            <Link to="/documents" className="org-title">
              <img
                src={BrandLogo}
                alt="Brand Logo"
                width="150px"
                height="25px"
                style={{
                  lineHeight: "22px",
                  marginBottom: "3px",
                }}
              />
            </Link>
          </div>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={[this.props.location.pathname]}
            defaultSelectedKeys={[this.state.current]}
            style={{ lineHeight: "60px" }}
            onClick={this.handleClick}
          >
            <Menu.Item key="/documents">
              <Link to="/documents">Notion Docs</Link>
            </Menu.Item>
            <Menu.Item key="/exports">
              <Link to="/exports">Exports</Link>
            </Menu.Item>
            <Menu.Item key="/websites">
              <Link to="/websites">Sites</Link>
            </Menu.Item>
          </Menu>
          <div className="avatar-icon" id="user">
            <DefaultButton
              title="New Exports"
              scale="small"
              color="purple"
              onClick={this.onClickAddNewExport}
              className="medium-font border-none margin-right-30"
            />

            <NewExport
              token={this.props.token}
              exportModalVisible={this.state.exportModalVisible}
              setExportModalVisible={(d: any) =>
                this.setState({ exportModalVisible: d })
              }
            />

            <Dropdown
              overlay={menu}
              getPopupContainer={() =>
                document.getElementById("user") || document.body
              }
              placement="bottomCenter"
              overlayClassName="avatar-menu"
            >
              <Avatar style={{ cursor: "pointer" }}>
                {getInitials(this.state.username, " ")}
              </Avatar>
            </Dropdown>
          </div>
        </div>
      </>
    );
  }
}

interface CurrentState {
  nextBtn?: any;
  tableLoading?: boolean;
  searchTableData?: any;
  paginationTableData?: any;
  searchText: string;
  searchItem: any;
  current: string;
  username?: string;
  modifiedInitialState?: string;
  data?: any;
  pagination?: any;
  exportModalVisible?: boolean;
  apiLoading?: boolean;
  linkedAccountsList: {
    id?: number;
    url?: string;
    cms_domain?: string;
  }[];

  selectSiteKey: any;
  dataKey: number;

  currentItem: {
    alternate_link: string;
    title: string;
    id: string;
  };
  currentItemIndex: number;

  currentLinkedAccount?: any;
  currentLinkedAccountIndex: number;

  shopifyBlogList: {
    id: string;
    handle: string;
    title: string;
  }[];
  shopifyBlogId?: string;
  exportSuccess?: boolean;
  exportingDoc?: boolean;
  currentPlatform: string;
  addWebsiteModalVisible?: boolean;
  placeholderError: boolean;
  selectDocError: boolean;
  docSelectModalVisible?: boolean;
  resetDropdown: boolean;
  addSuccessWebsite: boolean;
  resetSiteTable: boolean;
  resetExportTable: boolean;
}

interface Props extends RouteComponentProps<any> {
  token: string;
  profile: any;
  resetExportTableState?: Boolean;
  exportModalState: Boolean;
  logout: any;
  reRenderSiteTable: any;
  reRenderExportTable: any;
  openExportModal?: any;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
  reRenderSiteTable: reRenderSiteTable,
  reRenderExportTable: reRenderExportTable,
  openExportModal: openExportModal,
  logout: logout,
};

const mapStateToProps = (state) => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
      exportModalState: state.selfReducers.exportModalState,
    };
  } else {
    return {};
  }
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TableMenu)
);
