import React, { Component } from "react";
import "./notionconnect.scss";
import { Dropdown, Menu, Modal, Row } from "antd";
import classNames from "classnames";
import DefaultHeader from "../../components/_common/Header/DefaultHeader";
import Paragraph from "../../components/_common/paragraph/paragraph";
import CardMenu from "../../components/_common/cardmenu/CardMenu";
import WordPress from "../../assets/icons/website/home page/wordpress-white-xl.svg";
import Shopify from "../../assets/icons/website/home page/shopify-white-xl.svg";
import Medium from "../../assets/icons/website/home page/medium-white-xl.svg";
import BigCommerce from "../../assets/icons/website/home page/big-commerce-white-xl.svg";
import Drupal from "../../assets/icons/website/home page/drupal-white-xl.svg";
import { Dropdown1 } from "../../components/_common/dropdown/Dropdown1";
import WordpressForm from "../../components/_common/form/wordpress/WordpressForm";
import ReactLoading from "react-loading";
import * as CONSTANTS from "../../constants";
import SuccessModal from "../../components/_common/modal/SuccessModal/SuccessModal";
import ShopifyForm from "../../components/_common/form/shopify/ShopifyForm";
import MediumForm from "../../components/_common/form/medium/Medium";
import DrupalForm from "../../components/_common/form/Drupal/DrupalForm";
import BigCommerceForm from "../../components/_common/form/bigcommerce/BigCommerceForm";
import QuestionMark from "../../assets/images/question_mark.svg";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { saveDetailsOfMe, saveServerToken } from "../../redux-stuffs/actions";
import queryString from "query-string";
import NotionPress from "../../assets/images/NotionPress.png";
import Header from "../../components/_common/Typography/Header";
import DefaultButton from "../../components/_common/button/Button";

import { connect } from "react-redux";
import api from "../../api";
import { captureUrlCode } from "../../utils/CommonFunctions";
import { compose } from "redux";

const menu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="#">Export Help</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="#">Tutorials</Link>
    </Menu.Item>
    <Menu.Item key="3">
      <Link to="#">Support</Link>
    </Menu.Item>
  </Menu>
);

class MainPage extends Component<Props, InitialState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPlatform: CONSTANTS.DRUPAL,
      // currentPlatform: '',
      visible: false,
      apiLoading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.setState({
      apiLoading: true,
    });

    if (this.props.profile.can_export === true) {
      const queryParam = queryString.parse(this.props.history.location?.search);

      if (queryParam && queryParam.code) {
        api
          .userConnectNotionAccount(this.props.serverToken, queryParam.code)
          .then((result) => {
            api.getUserInfoAfterLogin(this.props.serverToken).then((res) => {
              this.props.saveDetailsOfMe(res);

              if (res.notion_connect === true) {
                this.props.history.push("/documents");
              }

              this.setState({
                apiLoading: false,
              });
            });
          })
          .catch((err) => {
            err.then((e) => {
              this.setState({
                apiLoading: false,
                error: e.message,
              });
            });
            console.log("An error occurred while connecting notion account");
          });
      } else {
        if (this.props.profile.notion_connect === true) {
          this.props.history.push("/documents");
        }
        this.setState({
          apiLoading: false,
        });
      }
    } else {
      this.props.history.push("/plans");
    }
  }

  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    console.log("this is handlecancel");
  };
  handleSuccessModalCancel = () => {
    this.props.history.push({
      pathname: "/table",
    });
    this.setState({
      visible: false,
    });
  };

  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        visible: true,
        currentPlatform: val,
      },
      () => {}
    );
  };

  getInputForm = () => {
    const { currentPlatform } = this.state;
    const { serverToken } = this.props;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            handleCancel={this.handleCancel}
            token={serverToken}
            className=""
            success={this.onSuccessfulAddition}
          />
        );
      // case CONSTANTS.SHOPIFY:
      //     return (
      //         <ShopifyForm
      //             handleCancel={this.handleCancel}
      //             success={this.onSuccessfulAddition}
      //         />
      //     );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.DRUPAL:
        return (
          <DrupalForm
            token={serverToken}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.BIG_COMMERCE:
        return (
          <BigCommerceForm
            token={serverToken}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.SUCCESS:
        return <SuccessModal handleCancel={this.handleCancel} />;
      default:
        return "Nothing selected";
    }
  };

  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState({
      currentPlatform: CONSTANTS.SUCCESS,
    });
  };

  render(): any {
    const { currentPlatform, visible, apiLoading } = this.state;

    return apiLoading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "300px",
        }}
      >
        <ReactLoading type="spin" color="#364699" height={25} width={25} />
      </div>
    ) : (
      <div id="deleteModalSuccess" style={{ position: "relative" }}>
        <Modal
          maskClosable={false}
          getContainer={() =>
            document.getElementById("deleteModalSuccess") || document.body
          }
          wrapClassName="delete-success-modal-container"
          title={null}
          width={746}
          visible={true}
          closable={false}
          footer={null}
        >
          <div className="success-modal main-container">
            <div className="inner-bottom-container">
              <div>
                <div className="success-body-top">
                  <img
                    src={require("../../assets/images/confetti-left.svg")}
                    alt="confetti-left"
                  />
                  <div>
                    <Header text="Connect Your Notion Account " />
                    <div style={{ textAlign: "center" }}>
                      <DefaultButton
                        onClick={() =>
                          (window.location.href =
                            process.env.REACT_APP_NOTION_LOGIN_URL)
                        }
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={NotionPress}
                              alt="driveIcon"
                              width="20px"
                              height="18px"
                              style={{ marginRight: "5px" }}
                            />{" "}
                            Select Workspace
                          </span>
                        }
                        scale="medium"
                        color="white"
                        className="medium-font button-width-150"
                      />
                      {this.state.error && (
                        <p style={{ marginTop: "20px", color: "red" }}>
                          {this.state.error}. Please Try Again
                        </p>
                      )}
                    </div>
                  </div>
                  <img
                    src={require("../../assets/images/confetti-right.svg")}
                    alt="confetti-right"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

interface InitialState {
  currentPlatform: string;
  visible: boolean;
  apiLoading: boolean;
  error: any;
}

interface Props extends RouteComponentProps<any> {
  title: string;
  serverToken: string;
  saveServerToken: any;
  saveDetailsOfMe: any;
  history: any;
  params: any;
  profile: any;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => ({
  serverToken: state?.selfReducers?.serverToken,
  profile: state?.selfReducers?.profile,
});
export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps))(MainPage)
);
