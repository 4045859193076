import {
  ADMIN_LOGOUT,
  IS_FREE_TRIAL_PLAN,
  LOGOUT,
  RESET_EXPORT_TABLE,
  RESET_SITE_TABLE,
  SAVE_ADMIN_TOKEN,
  SAVE_DETAILS_OF_ME,
  SAVE_SERVER_TOKEN,
  OPEN_EXPORT_MODAL,
} from "../../constants/ActionTypes";

/*export const saveDetailsOfMe = (details) => dispatch => {
    dispatch({
        type: ,
        payload: details
    });
    return Promise.resolve();
    // return {type: SAVE_DETAILS_OF_ME, payload};
};*/

/*export const saveDetailsOfMe = (details) => dispatch => {
    return {type: SAVE_DETAILS_OF_ME, details};
}*/

export function saveDetailsOfMe(details) {
  console.log("This is the details", details);
  return { type: SAVE_DETAILS_OF_ME, payload: details };
}

export function saveServerToken(details) {
  return { type: SAVE_SERVER_TOKEN, payload: details };
}

export function openExportModal(state) {
  return { type: OPEN_EXPORT_MODAL, payload: state };
}

export function logout() {
  return { type: LOGOUT };
}

export function adminLogout() {
  return { type: ADMIN_LOGOUT };
}

export function saveAdminToken(token) {
  return { type: SAVE_ADMIN_TOKEN, payload: token };
}

export const isFreeTrial = (val) => (dispatch) => {
  dispatch({
    type: IS_FREE_TRIAL_PLAN,
    payload: val,
  });
  return Promise.resolve();
  // return {type: IS_FREE_TRIAL_PLAN, payload: val};
};

export function reRenderSiteTable(resetTableState) {
  console.log("This is resetTableState", resetTableState);

  return {
    type: RESET_SITE_TABLE,
    payload: resetTableState,
  };
}

export function reRenderExportTable(resetExportTableState) {
  console.log("This is resetTableState", resetExportTableState);

  return {
    type: RESET_EXPORT_TABLE,
    payload: resetExportTableState,
  };
}
