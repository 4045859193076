import React from "react";
import { Route, Switch } from "react-router-dom";

import "./style.scss";
import "./index.css";
import NotionConnect from "../../containers/OnBoarding/NotionConnect";
import DemoComponent from "../_common/Demo/DemoComponent";
import Account from "../_common/account/Account";
import RedirectAuth0 from "../../containers/Redirect/RedirectAuth0";
import RedirectShopify from "../../containers/Redirect/RedirectShopify";
import RedirectMedium from "../../containers/Redirect/RedirectMedium";
import Plans from "../_common/plans";
import LoginRouting from "../../routes";
import NotFound from "../../containers/NotFound/NotFound";
import PaymentFailed from "../_common/payment/error";
import WebflowRedirect from "../_common/form/webflow/WebflowRedirect";
import { IntercomProvider } from "react-use-intercom";

function App(): JSX.Element {
  return (
    <Switch>
      {/*<Route component={DemoComponent} path="/table"/>*/}
      {/*<LoginRouting exact component={MainPage} path="/onboarding" />*/}
      <Route exact component={RedirectAuth0} path="/redirect-auth0" />
      <LoginRouting
        exact
        component={RedirectShopify}
        path="/redirect-shopify"
      />
      <LoginRouting exact component={RedirectMedium} path="/redirect-medium" />
      <LoginRouting exact component={NotionConnect} path="/notion-connect" />
      <LoginRouting exact component={Plans} path="/plans" />
      <Route exact component={PaymentFailed} path="/payment-failure" />
      <LoginRouting
        exact
        component={WebflowRedirect}
        path="/redirect-webflow"
      />
      <LoginRouting
        exact
        component={DemoComponent}
        path={["/documents", "/exports", "/websites", "/account"]}
      />
      <Route component={NotFound} path="*" />
    </Switch>
  );
}

export default function AppPage(props: any) {
  return <App {...props} />;
}
