import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import SelfReducers from './SelfReducers';
import isFreeTrial from './IsFreeTrial';


const reducers = combineReducers({
    routing: routerReducer,
    selfReducers: SelfReducers,
    isFreeTrial: isFreeTrial
});

export default reducers;
