import React from "react";
import "./button.scss";
import { Button } from "antd";
import classNames from "classnames";

export default function DefaultButton(props: Props): JSX.Element {
  const { title, color, scale, onClick, className, style, disabled } = props;

  return (
    <Button
      onClick={onClick}
      className={classNames(color, scale, className)}
      style={{ ...style }}
      disabled={disabled}
    >
      {title}
    </Button>
  );
}

interface Props {
  title: any;
  className?: string;
  color?: "purple" | "green" | "grey" | "white" | "skyblue";
  scale?: "large" | "medium" | "small" | "extra-small";
  onClick?: any;
  style?: any;
  disabled?: any;
}
