import React, { Component } from "react";
import "./rightdrawer.scss";
import { Drawer } from "antd";
import DefaultButton from "../button/Button";

export default class RightDrawer extends Component<Props, visibleState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      visible: localStorage.getItem("drawer") ? false : true,
    };
    localStorage.setItem("drawer", "false");
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render(): any {
    return (
      <>
        <Drawer
          placement="right"
          width={420}
          maskClosable={true}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          className="right-drawer"
        >
          <h2 className="drawer-body-header">Just so you know </h2>
          <p className="drawer-body-privacy">Privacy matters to us</p>
          <div className="drawer-body-description">
            <p className="inner-paragraph">
              NotionPress does{" "}
              <span style={{ textDecoration: "underline" }}>NOT</span> store any
              of your Notion Doc content on our servers. We also don’t sell your
              personal data to advertisers.
            </p>
            <p className="inner-paragraph">
              If you would like to know more about how we handle your data,
              visit our data and privacy page.
            </p>
            <DefaultButton
              onClick={this.onClose}
              title="Close"
              scale="large"
              color="purple"
              className="medium-font"
            />
          </div>
        </Drawer>
      </>
    );
  }
}

interface visibleState {
  visible: boolean;
}

interface Props {
  title?: string;
  className?: string;
  description?: string;
  logoSrc?: string;
  contentType?: string;
}
