export const firstLetterCapital = (string, delimiter) => {
    if (!string) {
        return '';
    }

    if (!delimiter) {
        delimiter = ' ';
    }

    let splitted = string.split(delimiter);
    let formattedWord = '';
    for (let j = 0; j < splitted.length; j++) {
        formattedWord += splitted[j].charAt(0)
            .toUpperCase() + splitted[j].slice(1)
            .toLowerCase() + ' ';
    }
    formattedWord = formattedWord.slice(0, -1);

    return formattedWord;
};


export const getInitials = (name, delimeter) => {

    if (name) {

        var array = name.split(delimeter);

        switch (array.length) {

            case 1:

                return array[0].charAt(0).toUpperCase();
            default:
                return array[0].charAt(0).toUpperCase() + array[array.length - 1].charAt(0).toUpperCase();
        }

    }
    return false;
};

export const moveCursorToEnd = (el) => {
    console.log("this is el.selectionStart", el.selectionStart);
    if (typeof el.selectionStart == "number") {
        el.selectionStart = el.selectionEnd = el.value.length;
    } else if (typeof el.createTextRange != "undefined") {
        el.focus();
        let range = el.createTextRange();
        range.collapse(false);
        range.select();
    }
};

export const removeLastSlash = (url) => {
    return url.replace(/\/$/, "");
};


export const captureUrlCode = (url)=>{
    return url.substring(0,url.indexOf('&s')).split('?code=').filter(Boolean)[0];
}