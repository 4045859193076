import React, {Component} from "react";
import './style.scss';

class Paragraph extends Component<Props> {
    static defaultProps: {};

    render(): any {
        const {text, fontSize, style} = this.props;

        return (
            <p style={{...style, fontSize}} className="default-paragraph">
                {text}
            </p>
        );
    }
}

interface Props {
    text: string;
    fontSize?: number;
    style?: any;
}

Paragraph.defaultProps = {
    fontSize: 17
};

export default Paragraph;