import React, { useEffect, useState } from "react";
import SearchIcon from "../../../assets/images/table/icon_search.svg";
import FileIcon from "../../../assets/images/table/icon_gdoc.svg";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux-stuffs/store/store";
import { Input, Icon } from "antd";
import api from "../../../api";

const SearchBar = ({
  setNextBtn,
  loading,
  updateTable,
  setSearchTableData,
  searchTableData,
  setSearchText,
  searchText,
  paginationTableData,
  setSearchItem,
  setPaginationTableData,
  searchItem,
}) => {
  const { profile, serverToken } = useSelector<RootState, any>(
    (state) => state?.selfReducers
  );

  //   const [text, setText] = useState("");
  const [debouncedText, setDebouncedText] = useState(searchText);
  const [close, setClose] = useState(false);

  // update 'term' value after 1 second from the last update of 'debouncedTerm'
  useEffect(() => {
    const timer = setTimeout(
      () => setSearchText(debouncedText),
      close === false ? 500 : 0
    );
    return () => clearTimeout(timer);
  }, [debouncedText, close]);

  useEffect(() => {
    if (searchItem) {
      setDebouncedText("");
    }
  }, [searchItem]);

  console.log(searchTableData, "search");

  // submit a new search
  useEffect(() => {
    if (searchText !== "") {
      loading(true);

      api.getGoogleDocList(serverToken, false, searchText).then((res) => {
        setNextBtn(0);
        setSearchTableData({
          pageCount: Math.ceil(res.notion_doc_list.length / 25),
          currentIndex: 0,
          data: [
            {
              id: 1,
              next_cursor: res.next_cursor,
              notion_doc_list: res.notion_doc_list,
              has_more: res.has_more,
            },
          ],
        });

        updateTable({
          ...res,
          id: 1,
          notion_doc_list: res.notion_doc_list.slice(0, 25),
        });

        loading(false);
      });
    } else {
      loading(true);
      setSearchTableData([]);
      api.getGoogleDocList(serverToken).then((res) => {
        setNextBtn(0);

        setPaginationTableData({
          pageCount: Math.ceil(res.notion_doc_list.length / 25),
          currentIndex: 0,
          data: [
            {
              id: 1,
              next_cursor: res.next_cursor,
              notion_doc_list: res.notion_doc_list,
              has_more: res.has_more,
            },
          ],
        });

        updateTable({
          ...res,
          id: 1,
          notion_doc_list: res.notion_doc_list.slice(0, 25),
        });
        loading(false);
        setSearchText("");
      });
    }
  }, [searchText]);

  return (
    <Input
      addonBefore={<img src={SearchIcon} alt="search-box" />}
      addonAfter={
        debouncedText && (
          <Icon
            type="close"
            style={{ fontSize: "13px", cursor: "pointer" }}
            onClick={async () => {
              setClose(true);
              setDebouncedText("");
            }}
          />
        )
      }
      onChange={(e) => {
        setClose(false);
        setSearchItem(false);
        setDebouncedText(e.target.value);
      }}
      value={debouncedText}
      // onKeyPress={this.keyPressEnter}
      placeholder="Search your Notion docs"
    />
  );
};

export default SearchBar;
