import React, { useEffect } from "react";
import queryString from "query-string";
import { useHistory } from "react-router";
import api from "../../../../api";
import { RootState } from "../../../../redux-stuffs/store/store";
import { useSelector, useDispatch } from "react-redux";

const WebflowRedirect = () => {
  const history = useHistory();

  const { profile, serverToken } = useSelector<RootState, any>(
    (state) => state?.selfReducers
  );

  useEffect(() => {
    const queryParam = queryString.parse(history.location?.search);
    if (queryParam !== null && queryParam !== undefined) {
      api
        .getWebFlowToken(serverToken, queryParam.code)
        .then((res) =>
          history.push({ pathname: "/websites", state: { id: res.id } })
        );
    }
  }, []);

  return <div>WebflowRedirect</div>;
};

export default WebflowRedirect;
