import React, {Component} from "react";
import "./style.scss";
import {Steps} from 'antd';
import classNames from 'classnames';
import DrupalFirstForm from "./DrupalFirstForm";
import DrupalSecondForm from "./DrupalSecondForm";
import {Link} from "react-router-dom";

const {Step} = Steps;


class DrupalForm extends Component<Props, VisibleState> {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            website: "",
            username: "",
            password: "",
        }
    }

    handleChangeDrupalWebsite = (value) => {
        this.setState({
            website: value
        }, () => {
            console.log("this is handleChangeWebsite ", this.state.website);

        });
    };
    handleChangeDrupalUsername = (value) => {
        this.setState({
            username: value
        }, () => {
            console.log("this is handleChangeWebsite ", this.state.username);

        });
    };
    handleChangeDrupalPassword = (value) => {
        this.setState({
            password: value
        }, () => {
            console.log("this is handleChangeWebsite ", this.state.password);

        });
    };
    changeStep = (step) => {
        this.setState({
            current: step
        });
    };

    render(): any {
        const {token} = this.props;
        const {website, username, password} = this.state;
        const values = {website, username, password};

        const {current} = this.state;
        return (
            <div className="drupal-main-container">

                <div className="drupal-form-container">
                    <div className="steps-container">
                        <Steps size="small" current={current} type="navigation">
                            <Step icon={<></>} title="STEP 1" className="single-step active-step"/>
                            <Step icon={<></>} title="STEP 2"
                                  className={classNames("single-step", current === 1 ? "active-step" : "inactive-step", "step-2")}/>
                        </Steps>
                    </div>
                    <div className="link-container">
                        {current === 1 ? <p className="video-tutorial"> Watch video tutorial <Link to="#">here</Link>
                        </p> : ""}
                    </div>
                </div>

                {current === 0 ?
                    <DrupalFirstForm handleChangeDrupalWebsite={this.handleChangeDrupalWebsite}
                                     handleChangeDrupalPassword={this.handleChangeDrupalPassword}
                                     handleChangeDrupalUsername={this.handleChangeDrupalUsername}
                                     values={values} token={token} changeStep={this.changeStep}
                                     handleCancel={this.props.handleCancel}
                    />
                    :
                    <DrupalSecondForm changeStep={this.changeStep} success={this.props.success}/>
                }

            </div>
        );
    }
}

interface Props {
    success?: any;
    handleCancel?: any;
    token: string;

}

interface VisibleState {
    current: number;
    website?: string,
    username?: string,
    password?: string,
}

export default DrupalForm;