import React, {Component} from "react";
import {Form, Input, Row} from "antd";
import "./style.scss"
import Button from "../../button/Button";
import {WORDPRESS} from "../../../../constants";
import Spinner from 'react-spinner-material';

import {Link} from "react-router-dom";
import {FormComponentProps} from "antd/es/form";
import {moveCursorToEnd} from "../../../../utils/CommonFunctions";
import api from '../../../../api/index';

class BigCommerceSecondForm extends Component<Props, bigCommerceFormState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            username: "",
            password: "",
            website: "",
            api_path: "",
            access_token: "",
            client_secret: "",
            client_id: "",
        }
    };

    addSite = () => {
        this.props.success(WORDPRESS);
    };
    onBlurBigCommerceWebsite = (value) => {
        console.log("value", value.length);
        if (value.length === 0) {
            this.setState({
                website: "",
            }, () => {
                console.log("empty website", this.state.website)
            })
        } else {
            this.setState({
                    website: value
                }, () => {
                    console.log("else of onblurWesbite", this.state.website)
                }
            )
        }
    };
    onFocusBigCommerceWebsite = (e) => {
        const {website} = this.props.values;
        console.log("website length in setWebsite", website.length);
        moveCursorToEnd(document.getElementsByTagName("input")[0]);
        if (website.length === 0) {

            this.props.form.setFieldsValue({
                website: "https://www."
            }, () => {
                moveCursorToEnd(document.getElementsByTagName("input")[0]);
            });
        }
    };
    onChangeBigCommerceWebsite = (e) => {
        this.setState({
            ...this.state,
            website: e.target.value
        }, () => {
            this.props.handleChangeWebsite(this.state.website);
        })

    };

    onChangeApiPath = (e) => {
        this.setState({
            api_path: e.target.value
        }, () => {
            this.props.handleChangeApiPath(this.state.api_path)
        });

    };
    onChangeAccessToken = (e) => {
        this.setState({
            access_token: e.target.value
        }, () => {
            this.props.handleChangeAccessToken(this.state.access_token)
        });

    };
    onChangeClientSecret = (e) => {
        this.setState({
            client_secret: e.target.value
        }, () => {
            this.props.handleChangeClientSecret(this.state.client_secret)
        });

    };
    onChangeClientId = (e) => {
        this.setState({
            client_id: e.target.value
        }, () => {
            this.props.handleChangeClientId(this.state.client_id)
        });

    };

    bigCommerceFormSubmit = (e) => {
        e.preventDefault();
        const {token} = this.props;

        this.props.form.validateFields((err, values) => {
            if (!err) {


                this.setState({
                    loading: true
                }, () => {
                    console.log("This is the values", values);
                });
                let {client_id, client_secret, api_path, access_token, website} = values;

                let body = {
                    client_id: client_id,
                    client_secret: client_secret,
                    api_path: api_path,
                    access_token: access_token,
                    website_url: website,
                };
                api.addBigCommerceSite(body, token)
                    .then(res => {
                        console.log("result from bigcommerce", res);
                        if (res.status === 208) {

                        } else if (res.status === 200) {

                        } else {
                        }
                    })
                    .catch(err => {
                        console.log("Error on submit Bigcommerce Form", err);
                    });
                setTimeout(() => {
                    console.log(values);
                    this.addSite();
                }, 3000)

            }
        })
    };

    render(): any {
        const {getFieldDecorator} = this.props.form;
        const {website, loading} = this.state;
        const {values} = this.props;
        console.log("API website value from the props", values.website);

        return (
            <div className="bigcommerce-form-container">
                <Form className="form-input" onSubmit={this.bigCommerceFormSubmit}>
                    <Form.Item
                        help={"E.g. https://www.mysite.com"}
                    >
                        {getFieldDecorator('website', {
                            initialValue: `${values.website}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter Website',
                                },
                            ],
                        })(<Input defaultValue="mysite" onBlur={(e) => this.onBlurBigCommerceWebsite(e.target.value)}
                                  value={website} onMouseUp={this.onFocusBigCommerceWebsite}
                                  onChange={this.onChangeBigCommerceWebsite}
                                  placeholder="Enter your website url" className="form-input ant-input underline"/>
                        )}
                    </Form.Item>
                    <div className="form-descritption-container">
                        <p className="ant-form-description grey-1"> Fill in your BC site’s API details </p>
                        <p className="video-tutorial"> Watch video tutorial <Link to="#">here</Link>
                        </p>
                    </div>
                    <Form.Item className="form-username">
                        {getFieldDecorator('api_path', {
                            initialValue: `${values.api_path}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter your Username!',
                                },
                            ],
                        })(<Input onChange={this.onChangeApiPath} placeholder="API path"
                                  className="form-input ant-input username underline"/>)}
                    </Form.Item>
                    <Form.Item className="form-username">
                        {getFieldDecorator('access_token', {
                            initialValue: `${values.access_token}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter your Username!',
                                },
                            ],
                        })(<Input onChange={this.onChangeAccessToken} placeholder="Access Token"
                                  className="form-input ant-input username underline"/>)}
                    </Form.Item>
                    <Form.Item className="form-username">
                        {getFieldDecorator('client_id', {
                            initialValue: `${values.client_id}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter your Username!',
                                },
                            ],
                        })(<Input onChange={this.onChangeClientId} placeholder="Client ID"
                                  className="form-input ant-input username underline"/>)}
                    </Form.Item>

                    <Form.Item className="form-password">
                        {getFieldDecorator('client_secret', {
                            initialValue: `${values.client_secret}`,
                            rules: [{},
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                }
                            ],
                        })(<Input onChange={this.onChangeClientSecret} placeholder="Client Secret" type="password"
                                  className="form-input ant-input password underline"/>)}
                    </Form.Item>
                    <div style={{display: loading ? "block" : "none", marginBottom: "45px"}}>
                        <Spinner size={20} spinnerColor={"#364699"} spinnerWidth={2} visible={true} value="completed"/>
                    </div>

                    {/*<Form.Item>*/}
                    {/*    <Input placeholder="Client Secret" className="form-input ant-input password underline"/>*/}
                    {/*</Form.Item>*/}
                    <Row style={{display: "inline-flex", marginBottom: "50px"}}>
                        <Button title="Back" scale="medium" color="grey"
                                className="medium-font margin-right-10 border-grey"
                                onClick={() => this.props.changeStep(0)}/>
                        <Button title="Save & done" scale="medium" color="purple" className="medium-font border-none"
                                onClick={this.bigCommerceFormSubmit}/>
                    </Row>
                </Form>
            </div>
        );
    }
}

interface Props extends FormComponentProps {
    title?: string;
    className?: string;
    success?: any;
    changeStep?: any;
    token: string;
    values?: any;
    handleChangeWebsite?: any;
    handleChangeApiPath?: any;
    handleChangeClientId?: any;
    handleChangeAccessToken?: any;
    handleChangeClientSecret?: any;
}

interface bigCommerceFormState {
    username: string;
    password: string;
    website?: any;
    loading?: Boolean;
    api_path?: string;
    access_token?: string;
    client_secret?: string;
    client_id?: string;
}

export default Form.create<Props>()(BigCommerceSecondForm);
