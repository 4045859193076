import React, { Component } from "react";
import "./table.scss";
import DefaultHeader from "../Header/DefaultHeader";
import DefaultButton from "../button/Button";
import SiteTable from "./SiteTable";
import * as CONSTANTS from "../../../constants";
import { Modal, Row } from "antd";
import { Dropdown1 } from "../dropdown/Dropdown1";
import classNames from "classnames";
import WordpressForm from "../form/wordpress/WordpressForm";
import ShopifyForm from "../form/shopify/ShopifyForm";
import MediumForm from "../form/medium/Medium";
import DrupalForm from "../form/Drupal/DrupalForm";
import BigCommerceForm from "../form/bigcommerce/BigCommerceForm";
import SuccessModal from "../modal/SuccessModal/SuccessModal";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import WebFlowForm from "../form/webflow/WebFlowForm";

class SiteDoc extends Component<ComponentProps, stateStatus> {
  constructor(props) {
    super(props);
    this.state = {
      currentPlatform: CONSTANTS.WORDPRESS,
      selectSiteKey: "",
      addWebsiteModalVisible: false,
      addSuccessStatus: false,
      resetDropdown: false,
    };
  }

  componentDidMount() {
    console.log(this.props.history.location.state, ";");
    if (this.props.history.location.state?.id !== undefined) {
      this.setState({
        currentPlatform: CONSTANTS.WEB_FLOW,
        addWebsiteModalVisible: true,
      });
    }
  }

  onClickAddWebsite = (e) => {
    e.preventDefault();
    console.log("on click add website");
    this.setState({
      addWebsiteModalVisible: true,
    });
  };
  resetDropDownFunction = (data) => {
    this.setState({
      resetDropdown: data,
    });
  };
  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        currentPlatform: val,
      },
      () => {}
    );
  };
  handleCancel = () => {
    this.setState(
      {
        addWebsiteModalVisible: false,
        resetDropdown: true,
      },
      () => {
        this.setState({
          currentPlatform: CONSTANTS.WORDPRESS,
        });
        this.props.history.replace();
      }
    );
  };
  handleOk = () => {};
  chengeSuccessStatus = (data) => {
    this.setState(
      {
        addSuccessStatus: data,
      },
      () => {
        console.log(
          "this is addSuccessStatus after function call",
          this.state.addSuccessStatus
        );
      }
    );
  };
  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState({
      currentPlatform: CONSTANTS.SUCCESS,
      addSuccessStatus: true,
    });
  };
  getInputForm = () => {
    const { token } = this.props;
    const { currentPlatform } = this.state;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            token={token}
            handleCancel={this.handleCancel}
            className=""
            success={this.onSuccessfulAddition}
          />
        );
      // case CONSTANTS.SHOPIFY:
      //     return (
      //         <ShopifyForm
      //             handleCancel={this.handleCancel}
      //             success={this.onSuccessfulAddition}
      //         />
      //     );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.DRUPAL:
        return (
          <DrupalForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.WEB_FLOW:
        // return (
        //   <BigCommerceForm
        //     token={token}
        //     handleCancel={this.handleCancel}
        //     success={this.onSuccessfulAddition}
        //   />
        // );
        return (
          <WebFlowForm
            success={this.onSuccessfulAddition}
            handleCancel={this.handleCancel}
          />
        );
      case CONSTANTS.SUCCESS:
        return <SuccessModal handleCancel={this.handleCancel} />;
      default:
        return "Nothing selected";
    }
  };

  render(): any {
    console.log(this.props.history.location, ";");

    const {
      currentPlatform,
      addWebsiteModalVisible,
      resetDropdown,
      addSuccessStatus,
    } = this.state;
    console.log("this is currentPlatform before dropdown", currentPlatform);
    return (
      <div className="sites-table-container">
        <div className="drive-detail-container">
          <div className="drive-email-section">
            <DefaultHeader title="Connected Sites" className="header-2" />
          </div>
          <div className="browse-drive">
            <DefaultButton
              title={
                <span style={{ display: "flex", justifyContent: "center" }}>
                  Add a Website
                </span>
              }
              scale="small"
              color="white"
              onClick={(e) => this.onClickAddWebsite(e)}
              className="medium-font"
            />
          </div>
        </div>
        <SiteTable
          addSuccessStatus={addSuccessStatus}
          chengeSuccessStatus={this.chengeSuccessStatus}
          onClickAddWebsite={(e) => this.onClickAddWebsite(e)}
        />
        {addWebsiteModalVisible && (
          <Modal
            maskClosable={false}
            title={
              currentPlatform !== CONSTANTS.SUCCESS ? (
                <Row id="modal" className="display-flex-modal">
                  <div className="title-content">
                    <p className="modal-title grey-1" id="modalTitle">
                      Connect a new{" "}
                    </p>

                    <Dropdown1
                      className="purple-1"
                      title={currentPlatform}
                      resetDropdown={resetDropdown}
                      resetDropDownFunction={this.resetDropDownFunction}
                      changePlatform={this.changePlatform}
                    />

                    <p className="modal-title grey-1"> site </p>
                  </div>
                </Row>
              ) : null
            }
            width={746}
            visible={addWebsiteModalVisible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
            className={classNames(
              "docpress-modal",
              currentPlatform === CONSTANTS.SUCCESS ? "padding-0-modal" : null
            )}
          >
            {this.getInputForm()}
          </Modal>
        )}
      </div>
    );
  }
}

interface stateStatus {
  currentPlatform: string;
  selectSiteKey: any;
  addWebsiteModalVisible?: boolean;
  addSuccessStatus?: boolean;
  resetDropdown: boolean;
}

interface Props {
  token: string;
  selfReducers: Object;
}

interface ComponentDispatchProps {}

interface ComponentStateProps {
  token: string;
}

interface ComponentOwnProps {
  history: any;
}

type ComponentProps = ComponentStateProps &
  ComponentDispatchProps &
  ComponentOwnProps;

const mapDispatchToProps: ComponentDispatchProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};
const mapStateToProps = (state): ComponentStateProps => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
    };
  } else {
    return {
      token: "",
    };
  }
};

export default withRouter(
  connect<
    ComponentStateProps,
    ComponentDispatchProps,
    ComponentOwnProps,
    stateStatus
  >(
    mapStateToProps,
    mapDispatchToProps
  )(SiteDoc)
);
