import React, { Component } from "react";
import { Form, Icon, Input, Row, message, Steps, Progress } from "antd";
import "./style.scss";
import Button from "../../button/Button";
import { WORDPRESS } from "../../../../constants";
import Spinner from "react-spinner-material";
import { FormComponentProps } from "antd/es/form";
import { moveCursorToEnd } from "../../../../utils/CommonFunctions";
import api from "../../../../api/index";
import classNames from "classnames";
import axios from "axios";

const { TextArea } = Input;
const { Step } = Steps;

class WordpressForm extends Component<Props, FormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: "poudelanuj",
      password: "power_series",
      website: "",
      loading: false,
      current: 0,
      downloadStart: false,
      downloadPercent: 0,
      userNameStatus: false,
      userNameMatch: false,
      passwordStatus: false,
      passwordMatch: false,
    };
  }

  onBlurWordpressWebsite = (value) => {
    if (value.length === 0) {
      this.setState(
        {
          website: "",
        },
        () => {
          console.log("empty website", this.state.website);
        }
      );
    } else {
      this.setState(
        {
          website: value,
        },
        () => {
          console.log("else of onblurWesbite", this.state.website);
        }
      );
    }
  };

  onFocusWordpressWebsite = (e) => {
    const { website } = this.state;
    console.log("onFocus website value", website);
    // moveCursorToEnd(document.getElementsByTagName("input")[0]);

    if (website.length === 0) {
      this.props.form.setFieldsValue(
        {
          website: "https://www.",
        }
        // () => {
        //   moveCursorToEnd(document.getElementsByTagName("input")[0]);
        // }
      );
    }
  };

  onChangeWordpressWebsite = (e) => {
    this.setState(
      {
        ...this.state,
        website: e.target.value,
      },
      () => {
        console.log("this is onChange website state", this.state.website);
      }
    );
  };

  onChangeUserName = (e) => {
    e.preventDefault();
    this.setState({
      username: e.target.value,
    });
  };
  onChangePassword = (e) => {
    e.preventDefault();
    this.setState({
      password: e.target.value,
    });
  };

  wordPressFormSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("error", err);
      const { token } = this.props;
      console.log("This is the token", token);

      if (!err) {
        this.setState(
          {
            loading: true,
          },
          () => {
            console.log("This is the values", values);

            let { jwt_token } = values;
            let body = {
              word_press_url: this.state.website,
              jwt_token: jwt_token,
              /*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                word_press_account_name: username,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                word_press_account_password: password,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        */
            };

            console.log("This is the body", body);

            api
              .addWordpressAccount(body, token)
              .then((res) => {
                console.log("This is the res", res);
                this.setState({
                  loading: false,
                  userNameStatus: true,
                  userNameMatch: true,
                  passwordStatus: true,
                  passwordMatch: true,
                });
                this.addSite();
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                  userNameMatch: false,
                  userNameStatus: true,
                  passwordMatch: false,
                  passwordStatus: true,
                });
                err.then((res) => {
                  this.props.form.setFields({
                    jwt_token: {
                      value: this.props.form.getFieldValue("jwt_token"),
                      errors: [
                        new Error(
                          res.data
                            ? res.data
                            : "Invalid website name or JWT token. Please try again."
                        ),
                      ],
                    },
                  });
                });
              });
            return;
          }
        );

        return;
        /*
                                                                                                                                                                                                                                                                                                                                setTimeout(() => {
                                                                                                                                                                                                                                                                                                                                    axios.get("https://www.mocky.io/v2/5ddb7af43100007a006060b1").then(res => {
                                                                                                                                                                                                                                                                                                                                        this.setState({
                                                                                                                                                                                                                                                                                                                                            responsedUsername: res.data.username,
                                                                                                                                                                                                                                                                                                                                            responsedPassword: res.data.password
                                                                                                                                                                                                                                                                                                                                        }, () => {
                                                                                                                                                                                                                                                                                                                                            if (this.state.username !== this.state.responsedUsername) {
                                                                                                                                                                                                                                                                                                                                                this.props.form.setFields({
                                                                                                                                                                                                                                                                                                                                                    username: {
                                                                                                                                                                                                                                                                                                                                                        value: this.props.form.getFieldValue('username'),
                                                                                                                                                                                                                                                                                                                                                        errors: [new Error('Username not found')],
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                });
                                                                                                                                                                                                                                                                                                                                                this.setState({
                                                                                                                                                                                                                                                                                                                                                    loading: false,
                                                                                                                                                                                                                                                                                                                                                    userNameStatus: true,
                                                                                                                                                                                                                                                                                                                                                    userNameMatch: false,
                                                                                                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                                                                                                            } else {
                                                                                                                                                                                                                                                                                                                                                this.setState({
                                                                                                                                                                                                                                                                                                                                                    userNameStatus: true,
                                                                                                                                                                                                                                                                                                                                                    userNameMatch: true
                                                                                                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                            if (this.state.password !== this.state.responsedPassword) {
                                                                                                                                                                                                                                                                                                                                                console.log("this is state password", this.state.password !== this.state.responsedPassword);
                                                                                                                                                                                                                                                                                                                                                this.props.form.setFields({
                                                                                                                                                                                                                                                                                                                                                    password: {
                                                                                                                                                                                                                                                                                                                                                        value: this.props.form.getFieldValue('password'),
                                                                                                                                                                                                                                                                                                                                                        errors: [new Error('Password incorrect. Please try again.')],
                                                                                                                                                                                                                                                                                                                                                    },
                                                                                                                                                                                                                                                                                                                                                });
                                                                                                                                                                                                                                                                                                                                                this.setState({
                                                                                                                                                                                                                                                                                                                                                    loading: false,
                                                                                                                                                                                                                                                                                                                                                    passwordStatus: true,
                                                                                                                                                                                                                                                                                                                                                    passwordMatch: false
                                                                                                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                                                                                                            } else {
                                                                                                                                                                                                                                                                                                                                                this.setState({
                                                                                                                                                                                                                                                                                                                                                    passwordStatus: true,
                                                                                                                                                                                                                                                                                                                                                    passwordMatch: true
                                                                                                                                                                                                                                                                                                                                                })
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                            if (this.state.username === this.state.responsedUsername && this.state.password === this.state.responsedPassword) {
                                                                                                                                                                                                                                                                                                                                                this.setState({
                                                                                                                                                                                                                                                                                                                                                    loading: false,
                                                                                                                                                                                                                                                                                                                                                    userNameStatus: true
                                                                                                                                                                                                                                                                                                                                                });
                                                                                                                                                                                                                                                                                                                                                setTimeout(() => {
                                                                                                                                                                                                                                                                                                                                                    this.addSite();
                                                                                                                                                                                                                                                                                                                                                }, 2000)
                                                                                                                                                                                                                                                                                                                                            }
                                                                                                                                                                                                                                                                                                                                        });
                                                                                                                                                                                                                                                                                                                                    });
                                                                                                                                                                                                                                                                                                
                                                                                                                                                                                                                                                                                                                                }, 3000)
                                                                                                                                                                                                                                                                                                                */
      }
    });
  };

  addSite = () => {
    this.props.success(WORDPRESS);
  };

  checkUsername = (e) => {
    e.preventDefault();
    if (this.state.username !== "user") {
      this.props.form.setFields({
        username: {
          value: this.props.form.getFieldValue("username"),
          errors: [new Error("Username not found")],
        },
      });
    }
  };
  checkPassword = (e) => {
    e.preventDefault();
    if (this.state.password !== "pass1234") {
      this.props.form.setFields({
        password: {
          value: this.props.form.getFieldValue("password"),
          errors: [new Error("Password Error")],
        },
      });
    }
  };
  userNameFocus = (e) => {
    this.setState({
      userNameStatus: false,
      passwordStatus: false,
    });
  };
  passWordFocus = (e) => {
    this.setState({
      userNameStatus: false,
      passwordStatus: false,
    });
  };

  render(): any {
    const { getFieldDecorator } = this.props.form;
    const { website, loading, userNameMatch, passwordMatch } = this.state;
    let userStatusIcon, passwordStatusIcon;
    if (userNameMatch === true) {
      userStatusIcon = <Icon type="check" style={{ color: "green" }} />;
    } else {
      userStatusIcon = <Icon type="cross" style={{ color: "red" }} />;
    }
    if (passwordMatch === true) {
      passwordStatusIcon = <Icon type="check" style={{ color: "green" }} />;
    } else {
      passwordStatusIcon = <Icon type="cross" style={{ color: "red" }} />;
    }

    return (
      <div className="wordpress-form-container">
        <div className="steps-container">
          <Steps size="small" current={this.state.current} type="navigation">
            <Step
              icon={<></>}
              title="STEP 1"
              className={classNames(
                "single-step",
                this.state.current === 0 ? "active-step" : "inactive-step"
              )}
            />
            <Step
              icon={<></>}
              title="STEP 2"
              className={classNames(
                "single-step",
                this.state.current === 1 ? "active-step" : "inactive-step",
                "step-2"
              )}
            />
            <Step
              icon={<></>}
              title="STEP 3"
              className={classNames(
                "single-step",
                this.state.current === 2 ? "active-step" : "inactive-step"
              )}
            />
          </Steps>
          {this.state.current === 0 ? (
            <Form className="form-input">
              <p
                className="ant-form-description grey-1"
                style={{ marginBottom: "10px" }}
              >
                {" "}
                Enter your website URL{" "}
              </p>
              <Form.Item help={"E.g. https://www.mysite.com"}>
                {getFieldDecorator("website", {
                  rules: [
                    {
                      required: true,
                      message: "Please Enter Website",
                    },
                  ],
                })(
                  <Input
                    name="wordpressWebsite"
                    autoComplete="on"
                    onBlur={(e) => this.onBlurWordpressWebsite(e.target.value)}
                    value={website}
                    id="wordpressWebsite"
                    onMouseUp={this.onFocusWordpressWebsite}
                    onChange={this.onChangeWordpressWebsite}
                    placeholder="website URL"
                    className="form-input ant-input"
                  />
                )}
              </Form.Item>
              <Row
                style={{
                  display: "inline-flex",
                  marginTop: "80px",
                }}
              >
                <Button
                  title="Cancel"
                  scale="medium"
                  color="grey"
                  className="medium-font margin-right-10 border-grey"
                  onClick={() => {
                    this.setState({ website: "" });
                    this.props.handleCancel();
                  }}
                />
                <Button
                  title="Next"
                  scale="medium"
                  color="purple"
                  className="medium-font border-none wp-next"
                  onClick={() => {
                    this.props.form.validateFields((err, values) => {
                      if (!err) {
                        this.setState({ website: values.website, current: 1 });
                      }
                    });
                  }}
                />
              </Row>

              {/*
                                <Form.Item className="form-username">
                                    {getFieldDecorator('username', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Please Enter your Username!',
                                            },
                                        ],
                                    })(<Input
                                        placeholder="Username" onFocus={this.userNameFocus}
                                        suffix={this.state.userNameStatus ? userStatusIcon : null}
                                        onChange={this.onChangeUserName}
                                        className="form-input ant-input username"/>)}

                                </Form.Item>
                                <Form.Item className="form-password">
                                    {getFieldDecorator('password', {
                                        rules: [{},
                                            {
                                                required: true,
                                                message: 'Please input your password!',
                                            }
                                        ],
                                    })(<Input placeholder="Password" onFocus={this.passWordFocus}
                                              type="password" suffix={this.state.passwordStatus ? passwordStatusIcon : null}
                                              onChange={this.onChangePassword}
                                              className="form-input ant-input password"/>)}
                                </Form.Item>
            */}

              {/* <Row style={{ display: "inline-flex", marginBottom: "50px" }}>
                        <Button
                          title="Cancel"
                          scale="medium"
                          color="grey"
                          className="medium-font margin-right-10 border-grey"
                          onClick={this.props.handleCancel}
                        />
                        <Button
                          title="Add Site"
                          scale="medium"
                          color="purple"
                          className="medium-font border-none"
                          onClick={this.wordPressFormSubmit}
                        />
                      </Row> */}
            </Form>
          ) : this.state.current === 1 ? (
            <div style={{ marginTop: "60px" }}>
              <p
                className="ant-form-description grey-1"
                style={{
                  marginBottom: this.state.downloadStart ? "5px" : "30px",
                }}
              >
                Please download the NotionPress plugin and install and activate
                in Wordpress.
              </p>
              <div style={{ textAlign: "center", marginBottom: "40px" }}>
                {this.state.downloadStart && (
                  <Progress
                    percent={this.state.downloadPercent}
                    status="active"
                    strokeColor={"#364699"}
                  />
                )}
                <Button
                  title="NotionPress Plugin"
                  scale="medium"
                  color="purple"
                  disabled={this.state.downloadStart === true}
                  className="medium-font border-none wp-next"
                  onClick={async () => {
                    this.setState({ downloadStart: true });
                    await axios({
                      url:
                        process.env.REACT_APP_SERVER_URL +
                        "/api/v1/users/plugin",
                      method: "GET",
                      headers: {
                        Authorization: "Bearer " + this.props.token,
                      },
                      responseType: "blob", // important
                      onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        ); // you can use this to show user percentage of file downloaded
                        this.setState({ downloadPercent: percentCompleted });
                      },
                    }).then((res: any) => {
                      this.setState({ downloadStart: false });
                      let link = document.createElement("a");
                      let url = window.URL.createObjectURL(res.data);
                      link.href = url;
                      link.download = "NotionPress Plugin.zip";
                      link.click();
                      this.setState({ loading: false });
                    });
                    // await api
                    //   .downloadWordpressPlugin(this.props.token)
                    //   .then((res) => {
                    //
                    //   });
                    // window.open("https://notionpress.io")
                    // window.open("https://drive.google.com/file/d/13MkTRM0ZPOmeQQAIhgvdjiMauYtcqb0y/view?usp=sharing")
                  }}
                />
              </div>
              <div
                style={{
                  display: loading ? "block" : "none",
                }}
              >
                <Spinner
                  size={20}
                  spinnerColor={"#364699"}
                  spinnerWidth={2}
                  visible={true}
                  value="completed"
                />
              </div>
              <Row
                style={{
                  display: "inline-flex",
                  marginTop: "35px",
                }}
              >
                <Button
                  title="Cancel"
                  scale="medium"
                  color="grey"
                  className="medium-font margin-right-10 border-grey"
                  onClick={() => {
                    this.setState({ website: "" });
                    this.props.handleCancel();
                  }}
                />
                <Button
                  title="Next"
                  scale="medium"
                  color="purple"
                  className="medium-font border-none wp-next"
                  onClick={() => {
                    this.setState({ current: 2 });
                  }}
                />
              </Row>
            </div>
          ) : (
            <Form className="form-input">
              <p className="ant-form-description grey-1">
                {" "}
                Please enter the JWT token shown on NotionPress plugin page in
                wordpress{" "}
              </p>

              <Form.Item className="form-username">
                {getFieldDecorator("jwt_token", {
                  rules: [
                    {
                      required: true,
                      message: "Please Enter JWT token!",
                    },
                  ],
                })(
                  <TextArea
                    rows={4}
                    cols={5}
                    placeholder="Enter JWT token"
                    className="form-input ant-input jwt-token"
                  />
                )}
              </Form.Item>
              <div
                style={{
                  display: loading ? "block" : "none",
                  marginBottom: "45px",
                }}
              >
                <Spinner
                  size={20}
                  spinnerColor={"#364699"}
                  spinnerWidth={2}
                  visible={true}
                  value="completed"
                />
              </div>
              <Row
                style={{
                  display: "inline-flex",
                  marginTop: "30px",
                }}
              >
                <Button
                  title="Cancel"
                  scale="medium"
                  color="grey"
                  className="medium-font margin-right-10 border-grey"
                  onClick={() => {
                    this.setState({ website: "" });
                    this.props.handleCancel();
                  }}
                />
                <Button
                  title="Add Site"
                  scale="medium"
                  color="purple"
                  className="medium-font border-none wp-next"
                  onClick={this.wordPressFormSubmit}
                />
              </Row>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

interface Props extends FormComponentProps {
  title: string;
  className: string;
  success?: any;
  changeStep?: any;
  handleCancel?: any;
  token: string;
}

interface FormState {
  username: string;
  password: string;
  website?: any;
  loading?: Boolean;
  downloadPercent?: any;
  downloadStart?: any;
  current?: any;
  userNameStatus?: Boolean;
  userNameMatch?: Boolean;
  passwordStatus?: Boolean;
  passwordMatch?: Boolean;
}

export default Form.create<Props>()(WordpressForm);
