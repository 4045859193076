import React, {Component} from "react";
import {Form, Icon, Input, Row} from "antd";
import "./style.scss"
import Button from "../../button/Button";
import {WORDPRESS} from "../../../../constants";
import {FormComponentProps} from "antd/es/form";
import Spinner from 'react-spinner-material';
import {moveCursorToEnd} from "../../../../utils/CommonFunctions";
import api from '../../../../api/index';

class DrupalFirstForm extends Component<Props, drupalFormState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            username: "",
            password: "",
            website: "",
            userNameStatus: false,
            userNameMatch: false,
            passwordStatus: false,
            passwordMatch: false,
        }
    };

    addSite = () => {
        this.props.success(WORDPRESS);
    };
    onBlurDrupalWebsite = (value) => {
        /*
                console.log("value", value.length);
        */
        if (value.length === 0) {
            this.setState({
                website: "",
            }, () => {
                console.log("empty website", this.state.website)
            })
        } else {
            this.setState({
                    website: value
                }, () => {
                    console.log("else of onblurWesbite", this.state.website)
                }
            )
        }
    };
    onFocusDrupalWebsite = (e) => {
        const {website} = this.props.values;
        moveCursorToEnd(document.getElementsByTagName("input")[0]);
        console.log("website length in setWebsite", website.length);
        if (website.length === 0) {

            this.props.form.setFieldsValue({
                website: "https://www."
            }, () => {
                moveCursorToEnd(document.getElementsByTagName("input")[0]);
            });
        }
    };
    onFocusDrupalUserName = (e) => {
        this.setState({
            userNameStatus: false,
            passwordStatus: false,
        })
    };
    onFocusDrupalPassWord = (e) => {
        this.setState({
            userNameStatus: false,
            passwordStatus: false
        })
    };

    onChangeDrupalWebsite = (e) => {
        this.setState({
            ...this.state,
            website: e.target.value
        }, () => {
            this.props.handleChangeDrupalWebsite(this.state.website);
        })

    };
    onChangeDrupalUsername = (e) => {
        this.setState({
            username: e.target.value
        }, () => {
            this.props.handleChangeDrupalUsername(this.state.username);
        });
    };
    onChangeDrupalPassword = (e) => {
        this.setState({
            password: e.target.value
        }, () => {
            this.props.handleChangeDrupalPassword(this.state.password);
        })
    };

    drupalFormSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            console.log("error", err);
            const {token} = this.props;


            if (!err) {
                this.setState({
                    loading: true
                }, () => {
                    console.log("this is values from docpress", values);
                });
                let {website, username, password} = values;

                let body = {
                    drupal_url: website,
                    drupal_account_name: username,
                    drupal_account_password: password,
                };
                console.log("this is body", body);

                api.getAddDrupalAccount(body, token)
                    .then(res => {
                        if (res.status === 200) {
                            this.setState({
                                loading: false,
                                userNameStatus: true,
                                userNameMatch: true,
                                passwordStatus: true,
                                passwordMatch: true
                            }, () => {
                                setTimeout(() => {
                                    console.log(values);
                                    this.props.changeStep(1)
                                }, 2000);
                            });
                        } else if (res.status === 409) {
                            // alert("the site already added");
                            this.setState({
                                loading: false,
                            });
                        } else {
                            this.setState({
                                loading: false,
                                userNameMatch: false,
                                userNameStatus: true,
                                passwordMatch: false,
                                passwordStatus: true,
                            });
                            this.props.form.setFields({
                                password: {
                                    value: this.props.form.getFieldValue('password'),
                                    errors: [new Error('Username or Password is Incorrect. Please try again.')],
                                },
                            });
                        }

                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            userNameMatch: false,
                            userNameStatus: true,
                            passwordMatch: false,
                            passwordStatus: true,
                        });

                        console.log("this is error", err);
                    });


                /*                setTimeout(()=>{
                                    console.log(values);
                                    this.props.changeStep(1)
                                },2000);*/
            }
        })
    };

    render(): any {
        const {getFieldDecorator} = this.props.form;
        const {values} = this.props;

        const {website, loading, userNameMatch, passwordMatch} = this.state;
        let userStatusIcon, passwordStatusIcon;
        if (userNameMatch === true) {
            userStatusIcon = <Icon type="check" style={{color: "green"}}/>
        } else {
            userStatusIcon = <Icon type="cross" style={{color: "red"}}/>
        }
        if (passwordMatch === true) {
            passwordStatusIcon = <Icon type="check" style={{color: "green"}}/>
        } else {
            passwordStatusIcon = <Icon type="cross" style={{color: "red"}}/>
        }

        return (
            <div className="drupal-form-container">
                <Form className="form-input" onSubmit={this.drupalFormSubmit}>
                    <Form.Item
                        help={"E.g. https://www.mysite.com"}
                    >
                        {getFieldDecorator('website', {
                            initialValue: `${values.website}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter Website',
                                },
                            ],
                        })(<Input defaultValue="mysite" onBlur={(e) => this.onBlurDrupalWebsite(e.target.value)}
                                  value={website} onMouseUp={this.onFocusDrupalWebsite}
                                  onChange={this.onChangeDrupalWebsite}
                                  placeholder="Enter your website url" className="form-input ant-input underline"/>
                        )}
                    </Form.Item>
                    <p className="ant-form-description grey-1"> Add your Drupal credentials </p>

                    <Form.Item className="form-username">
                        {getFieldDecorator('username', {
                            initialValue: `${values.username}`,
                            rules: [
                                {
                                    required: true,
                                    message: 'Please Enter your Username!',
                                },
                            ],
                        })(<Input placeholder="Username"
                                  onFocus={this.onFocusDrupalUserName}
                                  onChange={this.onChangeDrupalUsername}
                                  suffix={this.state.userNameStatus ? userStatusIcon : null}
                                  className="form-input ant-input username underline"/>)}

                    </Form.Item>
                    <Form.Item className="form-password">
                        {getFieldDecorator('password', {
                            initialValue: `${values.password}`,
                            rules: [{},
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                }
                            ],
                        })(<Input placeholder="Password" type="password" onFocus={this.onFocusDrupalPassWord}
                                  suffix={this.state.passwordStatus ? passwordStatusIcon : null}
                                  onChange={this.onChangeDrupalPassword}
                                  className="form-input ant-input password underline"/>)}
                    </Form.Item>
                    <div style={{display: loading ? "block" : "none", marginBottom: "45px"}}>
                        <Spinner size={20} spinnerColor={"#364699"} spinnerWidth={2} visible={true} value="completed"/>
                    </div>

                    <Row style={{display: "inline-flex", marginBottom: "50px"}}>
                        <Button title="Cancel" scale="medium" color="grey"
                                className="medium-font margin-right-10 border-grey" onClick={this.props.handleCancel}/>
                        <Button title="Save & Next" scale="medium" color="purple" className="medium-font border-none"
                                onClick={this.drupalFormSubmit}/>
                    </Row>
                </Form>
            </div>
        );
    }
}

interface Props extends FormComponentProps {
    title?: string;
    className?: string;
    success?: any;
    changeStep?: any;
    handleCancel?: any;
    token: string;
    values: any;
    handleChangeDrupalWebsite?: any;
    handleChangeDrupalPassword?: any;
    handleChangeDrupalUsername?: any;
}

interface drupalFormState {
    username: string;
    password: string;
    website?: any;
    loading?: Boolean;
    userNameStatus?: Boolean;
    userNameMatch?: Boolean;
    passwordStatus?: Boolean;
    passwordMatch?: Boolean

}

export default Form.create<Props>()(DrupalFirstForm);
