import React from "react";

import { connect } from "react-redux";
import api from "../../api/index";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../redux-stuffs/actions/SelfActions";
import "./redirect.scss";
import ReactLoading from "react-loading";

// import {authAction} from "../redux-stuffs/actions/auth_action";

class RedirectMedium extends React.Component<Props, InitialState> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      hasError: false,
      showError: false,
    };
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    let results = regex.exec(this.props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  componentDidMount() {
    const code = this.getUrlParameter("code");
    let body = {
      code: code,
      redirect_uri: "https://app.notionpress.tk/redirect-meduim",
    };

    api
      .registerUrlMedium(body, this.props.token)
      .then((result) => {
        if (result.ok) {
          this.props.history.push({
            pathname: "/websites",
          });
        } else {
          this.setState({
            showError: true,
            errorMessage: result.message,
          });
        }
      })
      .catch((err) => {
        console.log("This is the err", err);
        // err = err.json();
        err.then((errMsg) => {
          this.setState({
            showError: true,
            errorMessage: errMsg.message,
          });
        });
      });
  }

  cancelError = () => {
    this.setState({
      showError: false,
    });
    this.props.history.push({
      pathname: "/websites",
    });
  };

  render() {
    const { showError, errorMessage } = this.state;

    if (showError) {
      return <div>{errorMessage}</div>;
    }

    return (
      <div className="row redirect-page-main-div">
        <div className="loading-icon" style={{ textAlign: "center" }}>
          <ReactLoading type="spin" color="#364699" height={50} width={50} />
        </div>
      </div>
    );
  }
}

interface InitialState {
  hasError: boolean;
  showError: boolean;
  errorMessage: string;
}

interface Props {
  title: string;
  location: any;
  saveServerToken: any;
  saveDetailsOfMe: any;
  history: any;
  token: string;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
    };
  } else {
    return {};
  }
};

interface Props extends RouteComponentProps<any> {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RedirectMedium)
);
