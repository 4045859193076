import React, {Component} from "react";
import {Form, Row} from "antd";
import "./style.scss"
import Button from "../../button/Button";

export default class BigCommerceFirstForm extends Component<Props> {

    render(): any {
        return (
            <div className="bigcommerce-form-container">
                <Form className="form-input">
                    <ol style={{marginBottom: "256px"}}>
                        <p className="bigcommerce-form-steps-description">Complete the following steps in your BC admin
                            account</p>
                        <li><p className="bigcommerce-form-list"> Click “Advanced Settings" </p></li>
                        <li><p className="bigcommerce-form-list">Set content setting to “Modify”</p></li>
                        <li><p className="bigcommerce-form-list">Click Save</p></li>
                    </ol>


                    <Row style={{display: "inline-flex", marginBottom: "50px", position: "absolute", bottom: "0"}}>
                        <Button title="Cancel" scale="medium" color="grey"
                                className="medium-font margin-right-10 border-grey" onClick={this.props.handleCancel}/>
                        <Button title="Next" scale="medium" color="purple" className="medium-font border-none"
                                onClick={() => this.props.changeStep(1)}/>
                    </Row>
                </Form>
            </div>
        );
    }
}

interface Props {
    title?: string;
    className?: string;
    success?: any;
    changeStep?: any
    handleCancel?: any

}

