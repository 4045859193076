import React, { useEffect, useState } from "react";
import "./index.css";
import "./common/theme/theme.less";
import { connect } from "react-redux";
import { compose } from "redux";
import { Route } from "react-router";
import api from "./api";
import { useSelector, useDispatch } from "react-redux";
import { saveDetailsOfMe } from "./redux-stuffs/actions";

const LoginRouting = ({
  component: Component,
  serverToken,
  can_export,
  notion_connect,
  ...rest
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (serverToken !== undefined || serverToken !== null) {
      api.getUserInfoAfterLogin(serverToken).then((res) => {
        dispatch(saveDetailsOfMe(res));
      });
    }
  }, [serverToken]);

  return (
    <Route
      {...rest}
      render={(props) => {
        //token check here
        if (serverToken === undefined || serverToken === null) {
          return (
            <Route
              component={() => {
                window.location.href = process.env.REACT_APP_AUTH0_LOGIN_PAGE;
                return null;
              }}
            />
          );
        } else {
          //stripe account check
          if (can_export === false) {
            //path accessible without stripe account
            if (
              ["/plans", "/notion-connect", "/payment-failure"].includes(
                rest.path
              )
            ) {
              return <Component {...props} />;
            } else {
              //redirectt
              props.history.push("/plans");
            }
          } else {
            if (notion_connect === false) {
              if (rest.path === "/notion-connect") {
                return <Component {...props} />;
              } else {
                props.history.push("/notion-connect");
              }
            } else {
              if (rest.path === "/plans") {
                props.history.push("/documents");
              } else {
                return <Component {...props} />;
              }
            }
          }
        }
      }}
    />
  );
};
const mapStateToProps = (state) => ({
  serverToken: state.selfReducers?.serverToken,
  can_export: state.selfReducers?.profile?.can_export,
  notion_connect: state.selfReducers?.profile?.notion_connect,
});

export default compose(connect(mapStateToProps, null))(LoginRouting);
