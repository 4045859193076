import React from "react";
// import '../../../../assets/css/self_css.css';
import {connect} from "react-redux";
import api from "../../api/index";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {
    saveDetailsOfMe,
    saveServerToken,
} from "../../redux-stuffs/actions/SelfActions";
import ReactLoading from "react-loading";
import "./redirect.scss";

class RedirectAuth0 extends React.Component<Props, InitialState> {
    constructor(props) {
        super(props);
        console.log("from ctr");
        this.state = {
            hasError: false,
            errorMessage: "",
        };
    }

    getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

        let results = regex.exec(this.props.location.search);
        return results === null
            ? ""
            : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    componentDidMount() {
        const token = this.getUrlParameter("token");
        const error = this.getUrlParameter("error");

        if (token && token.substring(0, 2) === "ey") {
            localStorage.setItem("accessToken", token);
            this.props.saveServerToken(token);
            api
                .getUserInfoAfterLogin(token)
                .then((result) => {
                    this.props.saveDetailsOfMe(result);

                    this.props.history.push("/plans");
                })
                .catch((err) => {
                    console.log("This is the err", err);
                });
        } else if (
            this.props.serverToken === undefined ||
            this.props.serverToken === null
        ) {
            window.location.href = process.env.REACT_APP_AUTH0_LOGIN_PAGE;
        } else {
            this.props.history.push("/plans");
        }
    }

    render() {
        return (
            <div className="row redirect-page-main-div">
                <div className="loading-icon" style={{textAlign: "center"}}>
                    <ReactLoading type="spin" color="#364699" height={50} width={50}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    saveDetailsOfMe: saveDetailsOfMe,
    saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => ({
    serverToken: state?.selfReducers?.serverToken,
});

interface InitialState {
    hasError: boolean;
    errorMessage: string;
}

interface Props {
    title: string;
    location: any;
    saveServerToken: any;
    saveDetailsOfMe: any;
    serverToken: string;
    history: any;
}

interface Props extends RouteComponentProps<any> {
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RedirectAuth0)
);
