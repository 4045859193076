import React, { Component } from "react";
import "./table.scss";
import DefaultHeader from "../Header/DefaultHeader";
import NotionPress from "../../../assets/images/NotionPress.png";
import DefaultButton from "../button/Button";
import NotionTable from "./NotionTable";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import api from "../../../api/index";
import { RouteComponentProps } from "react-router-dom";
import { Icon } from "antd";

class NotionDoc extends Component<Props, stateStatus> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectSiteKey: "",
      email: "",
      data: [],
      modifiedInitialState: "LAST_MODIFIED",
      apiLoading: true,
      notionUserData: [],
      refresh: false,
    };
  }

  handleLoadingUpdate = (bool) => {
    this.setState({
      apiLoading: bool,
    });
  };

  handleTableData = (data) => {
    this.setState({
      data: data,
    });
  };

  refreshTable() {
    // api
    //   .getGoogleDocList(this.props.token)
    //   .then((res) => {
    //     console.log("This is doclist res", res);
    //     this.setState({
    //       data: res,
    //       apiLoading: false,
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     this.setState({
    //       apiLoading: false,
    //     });
    //   });
  }

  componentDidMount() {
    // this.setState({ apiLoading: true });
    api
      .getUserNotionInfo(this.props.token)
      .then((res) => {
        console.log("This is Notion User  res", res);
        this.setState({
          notionUserData: res,
          email: this.props.profile.email,
          // apiLoading: false,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  render(): any {
    const { email, notionUserData } = this.state;
    return (
      <div className="table-container">
        <div className="drive-detail-container">
          <div className="drive-email-section">
            <DefaultHeader title="Notion Documents" className="header-2" />
            <p className="drive-email">
              {notionUserData.length ? (
                <>Notion account: {notionUserData[0]?.email}</>
              ) : (
                ""
              )}
            </p>
          </div>
          <div className="browse-drive">
            <DefaultButton
              title={
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Icon type="reload" />
                  <div>Refresh Table</div>
                </span>
              }
              scale="small"
              color="white"
              className="medium-font button-width-150"
              onClick={() => {
                this.setState({ refresh: !this.state.refresh });
              }}
            />
            <DefaultButton
              title={
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={NotionPress}
                    alt="driveIcon"
                    width="20px"
                    height="18px"
                    style={{ marginRight: "5px" }}
                  />{" "}
                  Select Workspace
                </span>
              }
              scale="small"
              color="white"
              onClick={() =>
                (window.location.href = process.env.REACT_APP_NOTION_LOGIN_URL)
              }
              className="medium-font"
            />
          </div>
        </div>
        <NotionTable
          tableData={this.state.data}
          apiLoading={this.state.apiLoading}
          loadingUpdate={this.handleLoadingUpdate}
          updateTable={this.handleTableData}
          refresh={this.state.refresh}
          setRefresh={(b: boolean) => this.setState({ refresh: b })}
        />
      </div>
    );
  }
}

interface stateStatus {
  selectSiteKey: any;
  email?: any;
  data?: any;
  modifiedInitialState?: string;
  apiLoading?: boolean;
  notionUserData?: any;
  refresh?: boolean;
}

interface Props {
  token: string;
  profile: any;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
    };
  } else {
    return {};
  }
};

interface Props extends RouteComponentProps<any> {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotionDoc)
);
