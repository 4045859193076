import React, { Component } from "react";
import "./innernavbar.scss";
import { Modal, Select, Table as AntTable } from "antd";
import DefaultButton from "../button/Button";
import api from "../../../api";
import PlusImage from "../../../assets/icons/website/home page/faq-plus-purple.svg";
import * as CONSTANTS from "../../../constants";
import { SHOPIFY, WORDPRESS } from "../../../constants";
import { Dropdown1 } from "../dropdown/Dropdown1";
import classNames from "classnames";
import FileIcon from "../../../assets/images/table/icon_gdoc.svg";
import WordpressForm from "../form/wordpress/WordpressForm";
import ShopifyForm from "../form/shopify/ShopifyForm";
import MediumForm from "../form/medium/Medium";
import DrupalForm from "../form/Drupal/DrupalForm";
import BigCommerceForm from "../form/bigcommerce/BigCommerceForm";
import SuccessModal from "../modal/SuccessModal/SuccessModal";
import PlusButton from "../../../assets/icons/website/home page/faq-plus.svg";
import BackButton from "../../../assets/images/icon_arrow-left.svg";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import moment from "moment";
import ErrorImage from "../../../assets/images/empty-state/empty-state_Gdocs.svg";
import Header from "../Typography/Header";
import ReactLoading from "react-loading";
import CursorPagination from "../table/cursorPagination";
import SearchBar from "../table/searchBar";
import WebFlowForm from "../form/webflow/WebFlowForm";

const { Option } = Select;

class NewExport extends Component<Props, CurrentState> {
  constructor(props) {
    super(props);
    this.state = {
      nextBtn: 0,
      searchText: "",
      searchItem: false,
      // data: this.props.tableData,
      // tableLoading: false,
      paginationTableData: [],
      searchTableData: [],
      current: "/demo/gdocs",
      username: "",
      data: [],
      pagination: 25,
      modifiedInitialState: "LAST_MODIFIED",
      selectSiteKey: "",
      placeholderError: false,
      selectDocError: false,
      dataKey: 1,
      apiLoading: false,
      linkedAccountsList: [
        {
          id: undefined,
          url: undefined,
          cms_domain: undefined,
        },
      ],

      currentItem: {
        alternate_link: "",
        title: "",
        id: "",
      },
      currentItemIndex: -1,

      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
      exportSuccess: false,
      exportingDoc: false,
      currentPlatform: CONSTANTS.WORDPRESS,

      addWebsiteModalVisible: false,
      // exportModalVisible: false,
      docSelectModalVisible: false,
      resetDropdown: false,
      addSuccessWebsite: false,
      resetSiteTable: false,
      resetExportTable: false,
    };
  }

  onClickAddNewExport = () => {
    this.setState({
      tableLoading: true,
      // exportModalVisible: true,
    });
    this.props.setExportModalVisible(true);

    api
      .getLinkedAccounts(this.props.token)
      .then((res) => {
        console.log("This is the account res", res);
        this.setState({
          linkedAccountsList: res,
        });
      })
      .catch((error) => {
        console.log("this is error", error);
        // alert(error);
      });

    api
      .getGoogleDocList(this.props.token)
      .then((res) => {
        this.setState({
          tableLoading: false,
          paginationTableData: {
            pageCount: Math.ceil(res.notion_doc_list.length / 25),
            currentIndex: 0,
            data: [
              {
                id: 1,
                next_cursor: res.next_cursor,
                notion_doc_list: res.notion_doc_list,
                has_more: res.has_more,
              },
            ],
          },
        });
        console.log("This is doclist res", res);
        this.setState({
          data: {
            ...res,
            id: 1,
            notion_doc_list: res.notion_doc_list.slice(0, 25),
          },
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  onClickDocSelectDiv = () => {
    this.setState(
      {
        docSelectModalVisible: true,
        searchItem: "",
        searchText: "",
      },
      () => {
        console.log(
          "this is docSelectModalVisible",
          this.state.docSelectModalVisible
        );
      }
    );
  };
  onCancelSelectDocModal = () => {
    this.setState({
      docSelectModalVisible: false,
    });
  };

  componentDidMount() {
    api
      .getLinkedAccounts(this.props.token)
      .then((res) => {
        console.log("This is the account res", res);
        this.setState({
          linkedAccountsList: res,
        });
      })
      .catch((error) => {
        console.log("this is error", error);
        // alert(error);
      });
  }

  resetDropDownFunction = (data) => {
    this.setState(
      {
        resetDropdown: data,
      },
      () => {
        console.log(
          "this is resetDropdown inside Table Menu",
          this.state.resetDropdown
        );
      }
    );
  };
  handleClick = (e) => {
    console.log("click ", e);
    this.setState(
      {
        current: e.key,
      },
      () => {
        console.log("this is current", this.state.current);
      }
    );
  };
  onHandleExportCancel = (e) => {
    e.preventDefault();
    // this.props.openExportModal(false);
    this.props.setExportModalVisible(false);
    this.setState({
      // exportModalVisible: false,
      currentItem: {
        alternate_link: "",
        title: "",
        id: "",
      },
      currentLinkedAccount: {
        id: -1,
        url: "",
        cms_domain: "",
      },
      currentLinkedAccountIndex: -1,

      shopifyBlogList: [
        {
          id: "",
          handle: "",
          title: "",
        },
      ],
      shopifyBlogId: "",
    });
  };
  exportSiteChange = async (i) => {
    console.log("This is the i", i);
    i = parseInt(i);
    this.setState(
      {
        currentLinkedAccountIndex: i,
        currentLinkedAccount: this.state.linkedAccountsList[i],
      },
      () => {
        console.log(
          "This is the this.state.currentLinkedAccountIndex",
          this.state.currentLinkedAccountIndex
        );

        console.log(
          "This is the this.state.currentLinkedAccount",
          this.state.currentLinkedAccount
        );
        const { currentLinkedAccount } = this.state;
        console.log(
          "This is the currentLinkedAccount.cms_domain",
          currentLinkedAccount.cms_domain
        );
        if (currentLinkedAccount.cms_domain === SHOPIFY) {
          try {
            let shopifyBlogsArray = api.getShopifyBlogList(
              this.props.token,
              currentLinkedAccount.id
            );
            console.log("This is the shopifyBlogList", shopifyBlogsArray.id);
            // shopifyBlogList = shopifyBlogList.json();
            shopifyBlogsArray
              .then((res) => {
                console.log("This is shopifyBlogList res", res);
                this.setState({
                  shopifyBlogList: res,
                });
              })
              .catch((err) => {
                console.log("This is the err after json making", err);
                err.then((finalErr) => {
                  console.log("This is the finalErr", finalErr);
                });
              });
          } catch (e) {
            console.log("This is the e", e);
          }
        }
      }
    );
  };

  onSelectDropdownVisible = () => {
    this.setState({
      placeholderError: false,
    });
  };

  changeShopifyId = (val) => {
    console.log("This is the val", val);
    this.setState({
      shopifyBlogId: val,
    });
  };

  onClickAddWebsite = (e) => {
    e.preventDefault();
    console.log("on click add website");
    this.setState({
      addWebsiteModalVisible: true,
      addSuccessWebsite: false,
      resetSiteTable: false,
      resetExportTable: false,
    });
  };

  changePlatform = (val) => {
    console.log("This is the val from changePlatfrom", val);
    this.setState(
      {
        currentPlatform: val,
      },
      () => {}
    );
  };

  handleChangePagination = (value) => {
    this.setState(
      {
        pagination: value.key,
      },
      () => {
        console.log("this is pagination value", this.state.pagination);
      }
    );
    console.log(value);
  };

  handleCancel = () => {
    console.log("handel Cancel worked");
    this.setState(
      {
        addWebsiteModalVisible: false,
        resetDropdown: true,
      },
      () => {
        if (
          this.state.linkedAccountsList.length < 2 &&
          this.state.addSuccessWebsite
        ) {
          this.props.setExportModalVisible(false);
          // this.setState({
          //   exportModalVisible: false,
          // });
        }
        setTimeout(() => {
          this.setState({
            currentPlatform: CONSTANTS.WORDPRESS,
          });
        }, 2000);
      }
    );
  };
  handleOk = () => {};
  onSuccessfulAddition = (platform) => {
    console.log("This is the platform", platform);
    this.setState({
      currentPlatform: CONSTANTS.SUCCESS,
      addSuccessWebsite: true,
      resetSiteTable: true,
    });
  };

  getInputForm = () => {
    const { token } = this.props;
    const { currentPlatform } = this.state;
    switch (currentPlatform) {
      case CONSTANTS.WORDPRESS:
        return (
          <WordpressForm
            title=""
            token={token}
            handleCancel={this.handleCancel}
            className=""
            success={this.onSuccessfulAddition}
          />
        );
      // case CONSTANTS.SHOPIFY:
      //   return (
      //     <ShopifyForm
      //       handleCancel={this.handleCancel}
      //       success={this.onSuccessfulAddition}
      //     />
      //   );
      case CONSTANTS.MEDIUM:
        return <MediumForm success={this.onSuccessfulAddition} />;
      case CONSTANTS.DRUPAL:
        return (
          <DrupalForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.BIG_COMMERCE:
        return (
          <BigCommerceForm
            token={token}
            handleCancel={this.handleCancel}
            success={this.onSuccessfulAddition}
          />
        );
      case CONSTANTS.WEB_FLOW:
        // return (
        //   <BigCommerceForm
        //     token={token}
        //     handleCancel={this.handleCancel}
        //     success={this.onSuccessfulAddition}
        //   />
        // );
        return (
          <WebFlowForm
            success={this.onSuccessfulAddition}
            handleCancel={this.handleCancel}
          />
        );

      case CONSTANTS.SUCCESS:
        return <SuccessModal handleCancel={this.handleCancel} />;
      default:
        return "Nothing selected";
    }
  };

  handleExport = () => {
    const {
      currentLinkedAccount,
      currentLinkedAccountIndex,
      currentItem,
      currentItemIndex,
      shopifyBlogId,
    } = this.state;
    console.log("This is the currentLinkedAccount", currentLinkedAccount);
    console.log(
      "This is the currentLinkedAccountIndex",
      currentLinkedAccountIndex
    );
    console.log("This is the currentItem", currentItem);
    console.log("This is the currentItemIndex", currentItemIndex);

    if (currentLinkedAccountIndex < 0) {
      /*
                                                                                                                                          // alert("Please select a site");
                                                                                                                              */
      this.setState({
        placeholderError: true,
      });
      return;
    }
    if (currentItemIndex < 0) {
      this.setState({
        selectDocError: true,
      });
      return;
    }

    let exportBody = {};
    let exportUrl = "";

    exportBody["doc_name"] = currentItem.title;
    exportBody["file_id"] = currentItem.id;

    console.log("PODD", currentLinkedAccount.cms_domain);

    switch (currentLinkedAccount.cms_domain) {
      case WORDPRESS:
        exportBody["status"] = "draft";
        exportBody["wordpress_account_id"] = currentLinkedAccount.id;
        exportBody["page_or_post"] = "post";

        exportUrl = "word-press/export";
        break;
      case "SHOPIFY":
        if (!shopifyBlogId) {
          // alert("Shopify selected but shopify blog not selected");
          return;
        }
        exportBody["id_shopify_account"] = currentLinkedAccount.id;
        exportBody["blog_id"] = shopifyBlogId;

        console.log("this is shopify");

        exportUrl = "shopify/export";
        break;
      case "MEDIUM":
        exportBody["id_medium_account"] = currentLinkedAccount.id;

        exportUrl = "medium/export";
        break;
      case "BIG_COMMERCE":
        exportBody["big_commerce_id"] = currentLinkedAccount.id;

        exportUrl = "big_commerce/export";
        break;

      case "WEB_FLOW":
        exportBody["id_web_flow_account"] = currentLinkedAccount.id;

        exportUrl = "web-flow/export";
        break;
      case "DRUPAL":
        exportBody["drupal_account_id"] = currentLinkedAccount.id;

        exportUrl = "drupal/export";
        break;
      default:
        break;
    }

    this.props.setExportModalVisible(false);
    this.setState({
      // exportModalVisible: false,
      exportingDoc: true,
      exportSuccess: true,
    });

    api
      .exportDocument(this.props.token, exportBody, exportUrl)
      .then((result) => {
        this.setState({
          exportingDoc: false,
          currentLinkedAccountIndex: -1,
          currentLinkedAccount: "",
          currentItem: {
            alternate_link: "",
            title: "",
            id: "",
          },
          resetExportTable: true,
        });
      })
      .catch((e) => {
        console.log("This is the e", e);
        // alert("error occured. Please try again");
      });
  };
  onClickExport = (id) => {
    console.log("this is data index", id);
    const { data } = this.state;
    this.setState({
      docSelectModalVisible: false,
      dataKey: id,
      currentItem: data.notion_doc_list.find((i: any) => i.id === id),
      currentItemIndex: data.notion_doc_list.findIndex((i: any) => i.id === id),
    });
  };
  searchText = (e) => {
    this.setState({
      searchItem: this.state.searchText,
    });
  };
  updateSearchText = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        searchItem: e.target.value,
      },
      () => {
        console.log("this is searchText", this.state.searchText);
      }
    );
  };
  keyPressEnter = (e) => {
    if (e.key === "Enter") {
      this.searchText(e);
    }
  };
  onCancelExportSuccess = () => {
    this.setState({
      exportSuccess: false,
    });
  };

  render(): any {
    const {
      apiLoading,
      resetDropdown,
      currentPlatform,
      addWebsiteModalVisible,
      exportingDoc,
      // exportModalVisible,
      data,
      currentLinkedAccount,
      linkedAccountsList,
      currentItem,
      currentItemIndex,
      shopifyBlogList,
      docSelectModalVisible,
    } = this.state;
    console.log("this is currentItem", currentItem.title.length);

    const columns = [
      {
        title: "Title",
        dataIndex: "title",
        width: "100%",
        key: "title",
        render: (text, record, index) => (
          <div className="doc-select-table-title-container">
            <div
              className="title-icon-container"
              onClick={() => {
                this.onClickExport(record.id);
              }}
            >
              <img src={FileIcon} alt="doc-icon" />
            </div>
            <p
              className="table-title-row"
              onClick={() => {
                this.onClickExport(record.id);
              }}
            >
              {text}
            </p>
          </div>
        ),
      },
      {
        title: "Last modified",
        dataIndex: "last_edited_time",
        key: "last_edited_time",
        width: "20%",
        render: (text, record, index) => {
          return (
            <div style={{ color: "#676A6E", fontSize: "16px" }}>
              {moment(text).format("MMM DD, YYYY")}
            </div>
          );
        },
      },
    ];

    let newData = this.state.data?.notion_doc_list?.filter(
      (fi) => fi.title !== null
    );

    console.log(data.notion_doc_list, "iii");
    return (
      <>
        <div
          id="addNewExport"
          style={{ display: addWebsiteModalVisible ? "none" : "block" }}
        >
          {this.props.exportModalVisible ? (
            <Modal
              maskClosable={false}
              getContainer={() =>
                document.getElementById("addNewExport") || document.body
              }
              wrapClassName="add-new-export-modal-container"
              title={null}
              width={746}
              visible={this.props.exportModalVisible}
              onCancel={this.onHandleExportCancel}
              footer={null}
            >
              <h2 className="export-header"> Create a new export </h2>
              <div>
                <div className="box-button-container">
                  <div
                    className="export-to-dropdown-container"
                    id="exportDropdown"
                  >
                    <Select
                      className="ant-export-select"
                      onChange={this.exportSiteChange}
                      onDropdownVisibleChange={this.onSelectDropdownVisible}
                      getPopupContainer={() =>
                        document.getElementById("exportDropdown") ||
                        document.body
                      }
                      placeholder={
                        <p
                          className={
                            this.state.placeholderError
                              ? "ant-select-selection__placeholder_error"
                              : "ant-select-selection__placeholder"
                          }
                        >
                          Choose a site to export to
                        </p>
                      }
                    >
                      {linkedAccountsList.length > 0 ? (
                        linkedAccountsList.map((item, i) => {
                          return (
                            <Option key={i} value={i}>
                              {item.url}
                            </Option>
                          );
                        })
                      ) : (
                        <Option
                          value="no sites added"
                          className="no-site-menu"
                          disabled={true}
                        >
                          No webistes found in your Notion Press account
                        </Option>
                      )}
                      <Option
                        value="no sites added"
                        disabled={true}
                        className="select-add-site-button"
                      >
                        <DefaultButton
                          onClick={this.onClickAddWebsite}
                          title={
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                              }}
                            >
                              <p className="select-dropdown-button-text">
                                Add a new site
                              </p>
                              <span className="plus-button-span">
                                <img
                                  src={PlusImage}
                                  alt="plus_icon"
                                  width="13px"
                                  height="13px"
                                />
                              </span>
                            </div>
                          }
                          className="width-100 height-63 border-radius-0"
                        />
                      </Option>
                    </Select>
                    {addWebsiteModalVisible ? (
                      <Modal
                        maskClosable={false}
                        title={
                          currentPlatform !== CONSTANTS.SUCCESS ? (
                            <div
                              id="export_modal"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "130px",
                              }}
                            >
                              <p className="modal-title grey-1" id="modalTitle">
                                Connect a new{" "}
                              </p>

                              <Dropdown1
                                className="purple-1"
                                title={currentPlatform}
                                resetDropdown={resetDropdown}
                                resetDropDownFunction={
                                  this.resetDropDownFunction
                                }
                                changePlatform={this.changePlatform}
                              />

                              <p className="modal-title grey-1"> site </p>
                            </div>
                          ) : null
                        }
                        width={746}
                        visible={addWebsiteModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        className={classNames(
                          "docpress-modal",
                          currentPlatform === CONSTANTS.SUCCESS
                            ? "padding-0-modal"
                            : null
                        )}
                      >
                        {this.getInputForm()}
                      </Modal>
                    ) : null}
                  </div>

                  {currentLinkedAccount.cms_domain === SHOPIFY ? (
                    <div className="shopify-dropdown" id="shopifyDropdown">
                      <Select
                        getPopupContainer={() =>
                          document.getElementById("shopifyDropdown") ||
                          document.body
                        }
                        placeholder="Select Blog"
                        onChange={this.changeShopifyId}
                      >
                        {shopifyBlogList.map((obj, i) => {
                          return (
                            <Option key={obj.id} value={obj.id}>
                              {obj.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  ) : null}

                  <div
                    className="google-doc-modal"
                    onClick={
                      currentItem.title.length > 0
                        ? () => {}
                        : this.onClickDocSelectDiv
                    }
                  >
                    {currentItem.title.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div className="title-icon-container">
                            <img src={FileIcon} alt="doc-icon" />
                          </div>
                          <p className="table-title-row">{currentItem.title}</p>
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <p className="doc-edit">
                            <a
                              href={currentItem.alternate_link}
                              target={"_blank"}
                              style={{ color: "#364699" }}
                            >
                              edit
                            </a>
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                          onClick={() => {
                            this.setState({ apiLoading: true });
                            api
                              .getGoogleDocList(this.props.token)
                              .then((res) => {
                                this.setState({
                                  data: res,
                                  apiLoading: false,
                                });
                              })
                              .catch((error) => {
                                console.log("error", error);
                              });
                          }}
                        >
                          <p
                            style={{
                              display: "inline-flex",
                              color: this.state.selectDocError
                                ? "#DE5138"
                                : "#A1A6AB",
                              fontFamily: "URW Geometric",
                              fontSize: "19px",
                              letterSpacing: "0.5px",
                              lineHeight: "22px",
                            }}
                          >
                            Select a Notion Doc
                          </p>
                        </div>
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <p
                            className="doc-edit"
                            style={{
                              height: "100%",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={PlusButton}
                              alt="PlusIcon"
                              width="16px"
                              height="16px"
                            />
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="export-modal-button-container">
                  <DefaultButton
                    title="Cancel"
                    scale="medium"
                    color="grey"
                    className="medium-font margin-right-10 border-grey"
                    onClick={(e) => {
                      this.onHandleExportCancel(e);
                    }}
                  />
                  <DefaultButton
                    title="Export"
                    scale="medium"
                    color="purple"
                    className="medium-font border-none"
                    onClick={this.handleExport}
                  />
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
        <div id="addNewGoogleDoc">
          {docSelectModalVisible ? (
            <Modal
              maskClosable={false}
              getContainer={() =>
                document.getElementById("addNewGoogleDoc") || document.body
              }
              wrapClassName="add-new-google-doc-container"
              title={
                <>
                  <p
                    className="back-button"
                    onClick={this.onCancelSelectDocModal}
                  >
                    <img
                      src={BackButton}
                      width="13px"
                      height="17px"
                      alt="backbutton"
                    />{" "}
                    Back
                  </p>
                  <h2 className="export-header">
                    {" "}
                    Select a Notion Doc to export{" "}
                  </h2>
                </>
              }
              width={1090}
              visible={this.props.exportModalVisible}
              onCancel={this.onCancelSelectDocModal}
              footer={null}
            >
              <>
                <div className="input-search-container">
                  <SearchBar
                    setSearchTableData={(d: any) =>
                      this.setState({ searchTableData: d })
                    }
                    searchTableData={this.state.searchTableData}
                    setSearchText={(t: string) =>
                      this.setState({
                        searchText: t,
                      })
                    }
                    setNextBtn={(b: any) => this.setState({ nextBtn: b })}
                    paginationTableData={this.state.paginationTableData}
                    setPaginationTableData={(d: any) =>
                      this.setState({ paginationTableData: d })
                    }
                    searchText={this.state.searchText}
                    loading={(b: any) => this.setState({ apiLoading: b })}
                    updateTable={(d: any) => this.setState({ data: d })}
                    // refresh={this.props.refresh}
                    setSearchItem={(d: boolean) =>
                      this.setState({ searchItem: d })
                    } //to remove text on refresh
                    searchItem={this.state.searchItem}
                  />
                </div>
                <div className="select-doc-main-table-container">
                  <div
                    className="recently-modified-div"
                    style={{
                      display:
                        this.state.searchItem.length > 0 ? "none" : "block",
                    }}
                  >
                    Recently Edited
                  </div>

                  <div className="select-google-doc-container">
                    {apiLoading || this.state.tableLoading ? (
                      <div
                        className="loading-container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "200px",
                        }}
                      >
                        <ReactLoading
                          type="spin"
                          color="#364699"
                          height={25}
                          width={25}
                        />
                      </div>
                    ) : (
                      <>
                        <AntTable
                          columns={columns}
                          rowKey="key"
                          locale={{
                            emptyText: (
                              <>
                                <div className="error-image-container">
                                  <img src={ErrorImage} alt="error-icon" />
                                </div>
                                <span
                                  style={{
                                    marginTop: "48px",
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <p className="no-data-message">
                                    {" "}
                                    No Notion docs found{" "}
                                  </p>{" "}
                                  <div className="info-circle">
                                    <img
                                      className="info-icon"
                                      src={InfoIcon}
                                      alt="info"
                                    />
                                  </div>
                                </span>
                              </>
                            ),
                          }}
                          scroll={{ y: 500 }}
                          pagination={false}
                          dataSource={newData}
                        />

                        {newData?.length ? (
                          <div style={{ marginTop: "20px" }}>
                            <CursorPagination
                              data={this.state.data}
                              setPaginationTableData={(d: any) =>
                                this.setState(
                                  this.state.searchText
                                    ? { searchTableData: d }
                                    : { paginationTableData: d }
                                )
                              }
                              nextBtn={this.state.nextBtn}
                              setNextBtn={(b: any) =>
                                this.setState({ nextBtn: b })
                              }
                              paginationTableData={
                                this.state.searchText
                                  ? this.state.searchTableData
                                  : this.state.paginationTableData
                              }
                              loading={(b: any) =>
                                this.setState({ tableLoading: b })
                              }
                              updateTable={(d: any) =>
                                this.setState({ data: d })
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            </Modal>
          ) : null}
        </div>
        <div id="addDocExportSuccess" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("addDocExportSuccess") || document.body
            }
            wrapClassName="add-doc-export-success-modal-container"
            title={null}
            width={746}
            visible={this.state.exportSuccess}
            onCancel={this.onCancelExportSuccess}
            footer={null}
          >
            <div className="success-modal main-container">
              <div className="inner-bottom-container">
                <div>
                  <div className="success-body-top">
                    <img
                      src={require("../../../assets/images/confetti-left.svg")}
                      alt="confetti-left"
                    />
                    <div className="sussess-export-message">
                      {exportingDoc ? (
                        <>
                          <Header
                            text="Exporting"
                            style={{ marginRight: "10px" }}
                          />
                          <ReactLoading
                            type="spin"
                            color="#364699"
                            height={25}
                            width={25}
                          />
                        </>
                      ) : (
                        <Header text="Document exported successfully " />
                      )}
                    </div>
                    <img
                      src={require("../../../assets/images/confetti-right.svg")}
                      alt="confetti-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

interface CurrentState {
  nextBtn?: any;
  tableLoading?: boolean;
  searchTableData?: any;
  paginationTableData?: any;
  searchText: string;
  searchItem: any;
  current: string;
  username?: string;
  modifiedInitialState?: string;
  data?: any;
  pagination?: any;
  // exportModalVisible?: boolean;
  apiLoading?: boolean;
  linkedAccountsList: {
    id?: number;
    url?: string;
    cms_domain?: string;
  }[];

  selectSiteKey: any;
  dataKey: number;

  currentItem: {
    alternate_link: string;
    title: string;
    id: string;
  };
  currentItemIndex: number;

  currentLinkedAccount?: any;
  currentLinkedAccountIndex: number;

  shopifyBlogList: {
    id: string;
    handle: string;
    title: string;
  }[];
  shopifyBlogId?: string;
  exportSuccess?: boolean;
  exportingDoc?: boolean;
  currentPlatform: string;
  addWebsiteModalVisible?: boolean;
  placeholderError: boolean;
  selectDocError: boolean;
  docSelectModalVisible?: boolean;
  resetDropdown: boolean;
  addSuccessWebsite: boolean;
  resetSiteTable: boolean;
  resetExportTable: boolean;
}

interface Props {
  token: any;
  exportModalVisible?: any;
  setExportModalVisible?: any;
}

export default NewExport;
