import React, { useEffect } from "react";
import { useHistory } from "react-router";

function NotFound() {
  const history = useHistory();
  useEffect(() => {
    history.push("/documents");
  }, []);

  return <h1>Page Not Found</h1>;
}

export default NotFound;
