import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Switch as ToggleButton, Icon } from "antd";
import api from "../../../api";
import { RootState } from "../../../redux-stuffs/store/store";

const PaymentFailed = () => {
  const history = useHistory();

  const {
    profile: { can_export, notion_connect },
    serverToken,
  } = useSelector<RootState, any>((state) => state?.selfReducers);

  useEffect(() => {
    if (serverToken !== null || serverToken !== undefined) {
      if (notion_connect === true && can_export === true) {
        history.push("/account");
      } else {
        history.push("/plans");
      }
    } else {
      history.push("/notion-connect");
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading type="spin" color="#364699" height={30} width={30} />
    </div>
  );
};

export default PaymentFailed;
