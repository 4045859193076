import React, { Component } from "react";
import { Form, Row, Input } from "antd";
import "./style.scss";
import Button from "../../button/Button";
import { MEDIUM, WORDPRESS } from "../../../../constants";
import Spinner from "react-spinner-material";
import { FormComponentProps } from "antd/es/form";
import api from "../../../../api";
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";
const { TextArea } = Input;

class MediumForm extends Component<Props, FormState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      apiLoading: false,
      error: null,
    };
  }

  addSite = () => {
    this.props.success(MEDIUM);
  };

  // connectToMedium = () => {
  //   let finalUrl =
  //     "https://medium.com/m/oauth/authorize?" +
  //     "client_id=1305343dd8d8&scope=basicProfile,publishPost&state=" +
  //     Math.floor(Math.random() * 10000) +
  //     Date.now() +
  //     "&response_type=code" +
  //     "&redirect_uri=" +
  //     process.env.REACT_APP_MEDIUM_REDIRECT_URL;

  //   console.log("This is the finalUrl", finalUrl);

  //   window.location.href = finalUrl;
  // };

  MediumFormSubmit = (e) => {
    e.preventDefault();
    this.setState({
      apiLoading: true,
    });
    this.props.form.validateFields((err, values) => {
      console.log("error", err);
      console.log(values);
      if (!err) {
        api
          .connectMedium(this.props.serverToken, values.medium_token.trim())
          .then((res) => {
            this.setState({
              apiLoading: false,
            });
            this.addSite();
          })
          .catch((err) => {
            err.then((res) => {
              this.setState({
                apiLoading: false,
              });
              this.props.form.setFields({
                medium_token: {
                  value: this.props.form.getFieldValue("medium_token"),
                  errors: [
                    new Error(
                      res.data
                        ? res.data
                        : "Invalid Integration Token. Please try again."
                    ),
                  ],
                },
              });
            });
          });
      }else{
        this.setState({
          apiLoading: false
          ,
        });
      }
    });
  };

  render(): any {
    const { getFieldDecorator } = this.props.form;
    const { apiLoading, error } = this.state;

    return (
      <div className="medium-form-container">
        {/* <Form className="medium-form-input">
          <Button
            title="Connect to Medium"
            onClick={this.connectToMedium}
            scale="large"
            color="purple"
            className="medium-font"
          />
          <p className="ant-form-description">Offsite log in required</p>
        </Form> */}
        <Form
          className="form-input"
          //  onSubmit={this.wordPressFormSubmit}
        >
          <p className="ant-form-description grey-1">
            {" "}
            Please visit{" "}
            <span
              style={{ cursor: "pointer", color: "#384aa1" }}
              onClick={() => window.open("https://medium.com/me/settings")}
            >
              Medium Settings
            </span>
            . Copy the integration token or create the new one and paste below.
          </p>
          <Form.Item className="form-username">
            {getFieldDecorator("medium_token", {
              rules: [
                {
                  required: true,
                  message: "Please Enter Integration token!",
                },
              ],
            })(
              <TextArea
                rows={4}
                cols={5}
                placeholder="Enter Integration token"
                className="form-input ant-input jwt-token"
              />
            )}
          </Form.Item>

          <div
            style={{
              display: apiLoading ? "block" : "none",
              marginBottom: "45px",
            }}
          >
            <Spinner
              size={20}
              spinnerColor={"#364699"}
              spinnerWidth={2}
              visible={true}
              value="completed"
            />
          </div>

          <Row style={{ display: "inline-flex", marginBottom: "50px" }}>
            <Button
              title="Cancel"
              scale="medium"
              color="grey"
              className="medium-font margin-right-10 border-grey"
              onClick={this.props.handleCancel}
            />
            <Button
              title="Add Site"
              scale="medium"
              color="purple"
              className="medium-font border-none"
              onClick={this.MediumFormSubmit}
            />
          </Row>
        </Form>
      </div>
    );
  }
}

interface Props extends FormComponentProps {
  title?: string;
  className?: string;
  success?: any;
  handleCancel?: any;

  serverToken?: string;
}

interface FormState {
  apiLoading?: boolean;
  error?: any;
}

const mapStateToProps = (state) => ({
  serverToken: state?.selfReducers?.serverToken,
});

export default Form.create<Props>()(
  withRouter(compose(connect(mapStateToProps, null))(MediumForm))
);
