import React, {Component} from "react";
import './style.scss';

class Header extends Component<Props> {
    static defaultProps: {};

    render(): any {
        const {text, fontSize, style} = this.props;

        return (
            <h1 style={{...style, fontSize}} className="default-header">
                {text}
            </h1>
        );
    }
}

interface Props {
    text: string;
    fontSize?: number;
    style?: any;
}

Header.defaultProps = {
    fontSize: 28
};

export default Header;