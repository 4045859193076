import {
  ADMIN_LOGOUT,
  LOGOUT,
  RESET_EXPORT_TABLE,
  RESET_SITE_TABLE,
  SAVE_ADMIN_TOKEN,
  SAVE_DETAILS_OF_ME,
  SAVE_SERVER_TOKEN,
  OPEN_EXPORT_MODAL,
} from "../../constants/ActionTypes";

const self = (state = null, action) => {
  switch (action.type) {
    case SAVE_DETAILS_OF_ME:
      return {
        ...state,
        profile: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        profile: "",
        serverToken: null,
      };
    case ADMIN_LOGOUT:
      return {
        ...state,
        adminToken: null,
      };
    case SAVE_SERVER_TOKEN:
      return {
        ...state,
        serverToken: action.payload,
      };

    case SAVE_ADMIN_TOKEN:
      return {
        ...state,
        adminToken: action.payload,
      };
    case RESET_SITE_TABLE:
      return {
        ...state,
        resetTableState: action.payload,
      };
    case OPEN_EXPORT_MODAL:
      return {
        ...state,
        exportModalState: action.payload,
      };
    case RESET_EXPORT_TABLE:
      return {
        ...state,
        resetExportTableState: action.payload,
      };
    default:
      return state;
  }
};

export default self;
