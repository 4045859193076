import React, { Component } from "react";
import "./account.scss";
import DefaultButton from "../button/Button";
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Row,
  Switch as ToggleButton,
  Table,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import FooterIcon from "../table/FooterIcon";
import {
  logout,
  saveDetailsOfMe,
  saveServerToken,
} from "../../../redux-stuffs/actions";
import { connect } from "react-redux";
import { FormComponentProps } from "antd/es/form";
import api from "../../../api/index";
import ReactLoading from "react-loading";
import { RouteComponentProps } from "react-router";
import moment, { months } from "moment";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: "25%",
    render: (t) => <>{t}</>,
  },
  {
    title: "Plan",
    dataIndex: "plan",
    key: "plan",
    width: "40%",
  },
  {
    title: "Gateway",
    dataIndex: "gateway",
    key: "gateway",
    width: "20%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    width: "15%",
  },
];

const downloadMenu = (
  <Menu>
    <Menu.Item key="1">
      <Link to="/account">PDF</Link>
    </Menu.Item>
    <Menu.Item key="2">
      <Link to="#">CSV</Link>
    </Menu.Item>
  </Menu>
);

class Account extends Component<Props, InitialState> {
  constructor(props) {
    super(props);
    this.state = {
      monthlyState: true,
      editMemberInfoModalVisible: false,
      username: this.props.profile.name,
      email: this.props.profile.email,
      BillHistoryModal: false,
      billLoading: false,
      subscribedPlan: {},
      plans: [],
      payHistory: [],
      loading: false,
      joinedDate: this.props.profile.created_on,
      renewTime: this.props.profile.renew_time,
      exportCount: this.props.profile.remaining_export,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    api.getUserPlan(this.props.token).then(
      (res) =>
        this.setState({
          subscribedPlan: res,
          loading: false,
        })
      // api.getSelectedPlan(this.props.token, res?.plan_id).then((res) =>
      //   this.setState({
      //     subscribedPlan: res,
      //     loading: false,
      //   })
      // )
    );

    // api.listAllPlans(this.props.token).then((res) => {
    //   this.setState({ plans: res });
    // });

    // api
    //   .getUserInfoAfterLogin(this.props.token)
    //   .then((res) => {
    //     console.log("This is userInfo response", res);
    //     this.setState({
    //       email: res.email,
    //       username: res.name,
    //     });
    //   })
    //   .catch((error) => {
    //     window.location.href =
    //       "https://wordpress-335777-1033023.cloudwaysapps.com/";
    //   });
  }

  // data = this.state.plans.map((i) => {
  //   return {
  //     date: moment(i.subscribed_date).format("DD MMM YY"),
  //     plan: i.plan_id,
  //     period: moment(i.subscription_expire_date).format("DD MMM YY"),
  //     amount: i.plan_id,
  //   };
  // });

  onClickEditInfo = () => {
    this.setState({
      editMemberInfoModalVisible: true,
    });
  };
  onClickEditCancel = () => {
    this.setState({
      editMemberInfoModalVisible: false,
    });
  };
  onClickBillHistory = () => {
    this.setState({
      BillHistoryModal: true,
      billLoading: true,
    });
    api.paymentHistory(this.props.token).then((res) => {
      const plan = res.map((i) => {
        return {
          date: moment(i?.date).format("DD MMM YYYY"),
          plan: i?.plan_name,
          gateway: i.payment_gateway,
          amount: "$" + i.amount,
        };
      });
      this.setState({
        payHistory: plan,
        billLoading: false,
      });
    });
  };

  onClickBillHistoryCancel = () => {
    this.setState({
      BillHistoryModal: false,
    });
  };
  logout = () => {
    api.userLogout(this.props.token).then((res) => {
      this.props.logout();
      window.location.href = res.message;
    });
  };
  onToggleSwitch = () => {
    this.setState({
      monthlyState: !this.state.monthlyState,
    });
  };

  editUsername = (e) => {
    e.preventDefault();
    const { token } = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("this is form values", values);
        let body = {
          new_user_name: values.username,
        };
        api.editUsername(token, body).then((res) => {
          console.log("this is response", res);
          this.setState({
            username: res.name,
            editMemberInfoModalVisible: false,
          });
        });
      }
    });
  };

  scrollToPlans = () => {
    this.scroll("plan-section");
  };
  scroll = (errorId) => {
    const section = document.querySelector(`#${errorId}`);
    console.log("section", section);
    section.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  render(): any {
    const { username, email, subscribedPlan } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <div className="account-wrapper">
          <div className="left-menu-wrapper">
            <Menu defaultSelectedKeys={["1"]} mode="inline" theme="light">
              <Menu.Item key="1">
                <span>Account</span>
              </Menu.Item>
              {/* <Menu.Item key="2">
                <span>Setting</span>
              </Menu.Item>
              <Menu.Item key="3">
                <span>Support</span>
              </Menu.Item> */}
              <Menu.Item key="4" onClick={this.logout}>
                <span>Logout</span>
              </Menu.Item>
            </Menu>
          </div>
          {this.state.loading ? (
            <div
              className="membership-area-right-section"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "300px",
              }}
            >
              <ReactLoading
                type="spin"
                color="#364699"
                height={25}
                width={25}
              />
            </div>
          ) : (
            <div className="right-section-wrapper">
              <div className="membership-section-top-container">
                <div className="membership-section-container">
                  <div className="membership-area-left-section">
                    <div className="membership-subscription-info">
                      <h3 className="membership-header">Membership</h3>
                      <p className="membership-area-info">
                        {this.props.profile.user_type === "PAID"
                          ? subscribedPlan.plan_entity?.name
                            ? `${
                                subscribedPlan.plan_entity
                                  .recurring_payment_type === "MONTHLY"
                                  ? "Monthly Plan"
                                  : "Yearly Plan"
                              } : ${subscribedPlan?.plan_entity?.name}`
                            : "Loading . . . "
                          : this.props.profile.user_type === "CANCELED"
                          ? "Plan Cancelled"
                          : "Trial Plan"}
                        <> ({subscribedPlan.status})</>
                      </p>
                    </div>

                    <div className="member-detail-info">
                      <h3 className="membership-header">
                        {username}
                        <span
                          style={{
                            display: "inline-flex",
                            justifyContent: "flex-end",
                            float: "right",
                            marginRight: "5px",
                          }}
                        >
                          <DefaultButton
                            title="edit member info"
                            scale="extra-small"
                            color="white"
                            onClick={this.onClickEditInfo}
                            className="extra-small-font"
                          />
                        </span>
                      </h3>
                      <p className="membership-area-info"> {email} </p>
                    </div>

                    <div className="membership-deadline-info">
                      <h3 className="membership-header">
                        NotionPress member since{" "}
                        {subscribedPlan.subscribed_date &&
                          moment(subscribedPlan.subscribed_date).format(
                            "MMM YYYY"
                          )}
                      </h3>
                      <p className="membership-area-info">
                        {this.props.profile.user_type !== "PAID" ? (
                          <>
                            Upgrade your account now to get additional exports.
                          </>
                        ) : (
                          <>
                            {subscribedPlan.status === "TRIAL" ? (
                              <>
                                You are currently on trial, your{" "}
                                {subscribedPlan.plan_entity
                                  ?.recurring_payment_type === "MONTHLY"
                                  ? "Monthly"
                                  : "Yearly"}{" "}
                                billing starts from{" "}
                                {subscribedPlan.subscription_expire_date &&
                                  moment(
                                    subscribedPlan.subscription_expire_date
                                  ).format("DD MMM, YYYY")}
                                .
                              </>
                            ) : (
                              <>
                                Your{" "}
                                {subscribedPlan.plan_entity
                                  ?.recurring_payment_type === "MONTHLY"
                                  ? "Monthly"
                                  : "Yearly"}{" "}
                                membership will automatically renew on{" "}
                                {subscribedPlan.subscription_expire_date &&
                                  moment(
                                    subscribedPlan.subscription_expire_date
                                  ).format("DD MMM, YYYY")}
                                , and you will be charged $
                                {subscribedPlan.plan_entity?.discounted_price}.
                                Click here if you would like to cancel your
                                membership.
                              </>
                            )}
                          </>
                        )}
                      </p>
                    </div>
                    {this.props.profile.user_type !== "CANCELED" && (
                      <>
                        <div className="membership-deadline-info">
                          <h3 className="membership-header">Export Count</h3>
                          <p
                            className="membership-area-info"
                            style={{
                              color:
                                subscribedPlan.monthly_export -
                                  subscribedPlan.current_export >
                                10
                                  ? "#36AD68"
                                  : "#DE5137",
                            }}
                          >
                            You have{" "}
                            {subscribedPlan.monthly_export -
                              subscribedPlan.current_export}{" "}
                            remaining export
                            {subscribedPlan.monthly_export -
                              subscribedPlan.current_export <=
                            1
                              ? ""
                              : "s"}
                            .
                          </p>
                        </div>
                        <div className="membership-deadline-info">
                          <h3 className="membership-header">
                            Monthly Site Connection
                          </h3>
                          <p
                            className="membership-area-info"
                            style={{
                              color:
                                subscribedPlan.total_site_connection -
                                  subscribedPlan.current_site_connection >
                                1
                                  ? "#36AD68"
                                  : "#DE5137",
                            }}
                          >
                            You have{" "}
                            {subscribedPlan.total_site_connection -
                              subscribedPlan.current_site_connection}{" "}
                            remaining site connection
                            {subscribedPlan.total_site_connection -
                              subscribedPlan.current_site_connection <=
                            1
                              ? ""
                              : "s"}
                            .
                          </p>
                        </div>
                        <div className="membership-contact-us-section">
                          <p className="membership-question">
                            Want to cancel account?
                          </p>
                          <DefaultButton
                            title="Cancel Account"
                            scale="medium"
                            color="grey"
                            className="medium-font"
                            style={{ maxWidth: "fit-content" }}
                            onClick={async () => {
                              this.setState({ loading: true });
                              await api
                                .cancelSubscription(this.props.token)
                                .then(async () => {
                                  setTimeout(async () => {
                                    await api
                                      .getUserPlan(this.props.token)
                                      .then((res) =>
                                        this.setState({ subscribedPlan: res })
                                      );
                                    await api
                                      .getUserInfoAfterLogin(this.props.token)
                                      .then((res) =>
                                        this.props.saveDetailsOfMe(res)
                                      )
                                      .then(() =>
                                        this.setState({ loading: false })
                                      );
                                  }, 500);
                                });
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {this.props.profile.user_type === "PAID" && (
                    <div className="membership-area-right-section">
                      <div className="upper-box">
                        <p className="upper-box-info">
                          {subscribedPlan.plan_entity
                            ?.recurring_payment_type === "MONTHLY"
                            ? "Current Monthly Plan"
                            : "Current Yearly Plan"}
                        </p>
                      </div>
                      <div className="team-press-container">
                        <p className="team-press-left-section">
                          {subscribedPlan.plan_entity?.name}
                        </p>
                        <p className="team-press-right-section">
                          <span style={{ color: "#1A1A1A" }}>
                            ${subscribedPlan.plan_entity?.discounted_price}
                          </span>
                          /{" "}
                          {subscribedPlan.plan_entity
                            ?.recurring_payment_type === "MONTHLY"
                            ? "mo"
                            : "yr"}
                        </p>
                      </div>
                      <div className="year-plan-details">
                        <ul>
                          {subscribedPlan.plan_entity?.features.map(
                            (feature, index) => (
                              <li key={index}>
                                <i className="far fa-check-circle" />
                                {feature}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      {subscribedPlan.plan_id_to_upgrade !== -1 && (
                        <div
                          className="explore-plan-container"
                          onClick={async () => {
                            await api
                              .checkoutUser(
                                this.props.token,
                                subscribedPlan.plan_id_to_upgrade
                              )
                              .then((res) => {
                                window.location.href = res.message;
                              });
                          }}
                        >
                          Upgrade
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div id="editMemberInfo" style={{ position: "relative" }}>
                <Modal
                  maskClosable={false}
                  getContainer={() =>
                    document.getElementById("editMemberInfo") || document.body
                  }
                  wrapClassName="edit-info-container"
                  title={
                    <Row id="editInfoHeader">
                      <h3
                        className="modal-title grey-1"
                        style={{ textAlign: "center" }}
                        id="modalTitle"
                      >
                        {" "}
                        NotionPress Member Info{" "}
                      </h3>
                      <p className="header-message-section">
                        {" "}
                        Member:{" "}
                        <span style={{ color: "#364699" }}>
                          {this.state.username}
                        </span>
                      </p>
                    </Row>
                  }
                  width={746}
                  visible={this.state.editMemberInfoModalVisible}
                  onCancel={this.onClickEditCancel}
                  footer={null}
                >
                  <Form className="form-input" onSubmit={this.editUsername}>
                    <Form.Item className="form-username">
                      {getFieldDecorator("username", {
                        initialValue: `${username}`,
                        rules: [
                          {
                            required: true,
                            message: "Please Enter your Username!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Username"
                          className="edit-form-input ant-input username"
                        />
                      )}
                    </Form.Item>
                    <Form.Item className="edit-form-email">
                      {getFieldDecorator("email", {
                        initialValue: `${email}`,
                        rules: [
                          {},
                          {
                            required: true,
                            message: "Please Enter your email!",
                          },
                        ],
                      })(
                        <Input
                          placeholder="Email"
                          disabled={true}
                          className="edit-form-input ant-input"
                        />
                      )}
                    </Form.Item>
                    <Row style={{ display: "inline-flex", marginTop: "40px" }}>
                      <DefaultButton
                        title="Cancel"
                        scale="medium"
                        color="grey"
                        className="medium-font margin-right-10 border-grey"
                        onClick={this.onClickEditCancel}
                      />
                      <DefaultButton
                        title="Save info"
                        scale="medium"
                        color="grey"
                        className="medium-font margin-right-10 border-grey"
                        onClick={this.editUsername}
                      />
                    </Row>
                  </Form>
                </Modal>
              </div>
              <div id="viewBillInfo" style={{ position: "relative" }}>
                <Modal
                  maskClosable={false}
                  getContainer={() =>
                    document.getElementById("viewBillInfo") || document.body
                  }
                  wrapClassName="view-bill-container"
                  title={
                    <>
                      <div id="editInfoHeader" className="billing-history-left">
                        <h3 className="modal-title grey-1" id="modalTitle">
                          {" "}
                          Membership Billing History{" "}
                        </h3>
                        <p className="billing-member-name">
                          {" "}
                          Member: <span>{this.state.username}</span>
                        </p>
                      </div>
                      {/* <div id="downloadBill" className="download-bill-button">
                        <Dropdown
                          overlay={downloadMenu}
                          overlayClassName="
                            download-dropdown-menu"
                          getPopupContainer={() =>
                            document.getElementById("downloadBillButton") ||
                            document.body
                          }
                          placement="bottomCenter"
                        >
                          <Button id="downloadBillButton">Download</Button>
                        </Dropdown>
                      </div> */}
                    </>
                  }
                  width={746}
                  visible={this.state.BillHistoryModal}
                  onCancel={this.onClickBillHistoryCancel}
                  footer={null}
                >
                  {this.state.billLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ReactLoading
                        type="spin"
                        color="#364699"
                        height={25}
                        width={25}
                      />
                    </div>
                  ) : (
                    <Table
                      pagination={false}
                      scroll={{ y: 488 }}
                      dataSource={this.state.payHistory}
                      columns={columns}
                      rowKey="date"
                    />
                  )}
                </Modal>
              </div>
              <div className="membership-section-middle-container">
                <div className="billing-section-container">
                  <div className="billing-detail-info">
                    <h3 className="billing-section-header">{username}</h3>
                    <p className="billing-section-info"> {email} </p>
                  </div>
                  <div className="edit-billing-button">
                    <DefaultButton
                      title="View billing history"
                      scale="medium"
                      color="grey"
                      className="medium-font"
                      onClick={this.onClickBillHistory}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="membership-section-bottom-container">
              <div className="plan-section-container">
                <div className="plan-detail-info">
                  <h3 className="plan-section-header">Alternate Plans </h3>
                  <p className="plan-section-info"> </p>
                </div>
                <div className="switch-plan-container">
                  <p
                    className="monthly-plan"
                    style={{
                      color: this.state.monthlyState ? "#1A1A1A" : "inherit",
                    }}
                  >
                    {" "}
                    Monthly Pricing{" "}
                  </p>{" "}
                  <ToggleButton onChange={this.onToggleSwitch} />{" "}
                  <p
                    className="annual-plan"
                    style={{
                      color: this.state.monthlyState ? "inherit" : "#1A1A1A",
                    }}
                  >
                    {" "}
                    Annual Pricing{" "}
                  </p>
                </div>
                <div className="save-path" />
                <p className="save-section"> SAVE UP TO 15% </p>
              </div>
              <div className="package-description-container" id="plan-section">
                {this.state.plans.map((i) => (
                  <div className="individual-package-info-container" key={i.id}>
                    {i.plan_entity_list
                      .filter(
                        (f) =>
                          f.recurring_payment_type ===
                          (this.state.monthlyState === true
                            ? "MONTHLY"
                            : "YEARLY")
                      )
                      .map((p) => (
                        <div key={p.id}>
                          <div className="package-name-container">
                            <p className="package-name-left-section">
                              {i.name}
                            </p>
                            <p className="package-name-right-section">
                              <span style={{ color: "#1A1A1A" }}>
                                ${p.discounted_price}
                              </span>
                              / {this.state.monthlyState ? "mo" : "yr"}
                            </p>
                          </div>

                          <div>
                            <div className="year-plan-details">
                              <ul>
                                {p.features.map((feature, index) => (
                                  <li key={index}>
                                    <i className="far fa-check-circle" />
                                    {feature}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div> */}
            </div>
          )}
        </div>
        {/* <FooterIcon /> */}
      </>
    );
  }
}

interface Props extends FormComponentProps {
  token: string;
  logout: any;
  saveDetailsOfMe: any;
  profile: any;
  data: any;
}

interface Props extends RouteComponentProps<any> {}

interface InitialState {
  monthlyState?: boolean;
  username?: string;
  editMemberInfoModalVisible?: boolean;
  BillHistoryModal?: boolean;
  billLoading?: boolean;
  email?: string;
  subscribedPlan?: any;
  plans?: any;
  payHistory?: any;
  loading: boolean;
  renewTime?: string;
  exportCount?: number;
  joinedDate?: string;
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  logout: logout,
  saveServerToken: saveServerToken,
};
const mapStateToProps = (state) => {
  if (state.selfReducers.serverToken) {
    return {
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
    };
  } else {
    return {};
  }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Form.create<Props>()(Account))
);
