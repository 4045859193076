import React, { Component } from "react";
import { Input, Modal, Row, Select, Table as AntTable } from "antd";
import ErrorImage from "../../../assets/images/empty-state/empty-state_export-history.svg";
import moment from "moment";
import { connect } from "react-redux";
import "./table.scss";
import DefaultButton from "../button/Button";
import InfoIcon from "../../../assets/images/table/icon_info.svg";
import WordPressIcon from "../../../assets/images/table/export-table/icon_wordpress-small.svg";
import MediumIcon from "../../../assets/images/table/export-table/icon_medium-small.svg";
import WebFlowIcon from "../../../assets/images/table/export-table/icon_webflow-small.svg";
import ShopifyIcon from "../../../assets/images/table/export-table/icon_shopify-small.svg";
import DrupalIcon from "../../../assets/images/table/export-table/icon_drupal-small.svg";
import BigCommerceIcon from "../../../assets/images/table/export-table/icon_bigcommerce-small.svg";
import SearchIcon from "../../../assets/images/table/icon_search.svg";
import {
  BIG_COMMERCE,
  DRUPAL,
  MEDIUM,
  SHOPIFY,
  WEB_FLOW,
  WORDPRESS,
} from "../../../constants";
import api from "../../../api/index";
import ReactLoading from "react-loading";
import {
  openExportModal,
  reRenderExportTable,
} from "../../../redux-stuffs/actions";
import NewExport from "../innerNavbar/NewExport";

const { Option } = Select;

interface ComponentDispatchProps {}

interface ComponentStateProps {
  token: string;
  resetExportTableState?: boolean;
  reRenderExportTable?: any;
  openExportModal?: any;

  [profile: string]: any;
}

interface ComponentOwnProps {
  lastExportDate: any;
  exportTableStatus: any;
}

const serverData = [
  {
    doc_name: "business_plan_template_2017",
    file_id: "10TME05sbIvfrigbYEv9Lalx8jB6AosszguoB8sDmX4Q",
    cms: "SHOPIFY",
    post_link:
      "https://daari-store.myshopify.com/admin/blogs/49890492461/articles/383656919085",
    exported_time: "2019-12-11T07:02:44.000+0000",
  },
  {
    doc_name: "business_plan_template_2017",
    file_id: "10TME05sbIvfrigbYEv9Lalx8jB6AosszguoB8sDmX4Q",
    cms: "SHOPIFY",
    post_link:
      "https://daari-store.myshopify.com/admin/blogs/49890492461/articles/383656853549",
    exported_time: "haha",
  },
];

type ComponentProps = ComponentStateProps &
  ComponentDispatchProps &
  ComponentOwnProps;

class ExportTable extends Component<ComponentProps, TableState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      searchText: "",
      searchItem: "",
      data: serverData,
      sortData: serverData,
      loading: false,
      icon: 1,
      pagination: 25,
      platform: "ALL_PLATFORM",
      browseModalVisible: false,
      selectSiteKey: "",
      dataKey: 1,
      apiLoading: true,
      lastExportDate: "",
      exportModalVisible: false,
    };
  }

  componentDidMount() {
    this.props.exportTableStatus();
    this.props.openExportModal(false);
    api
      .exportsHistoryList(this.props.token)
      .then((res) => {
        console.log("This is the res", res);
        this.setState(
          {
            apiLoading: false,
            data: res,
            sortData: res,
            lastExportDate: res.length > 0 ? res[0].exported_time : "",
          },
          () => {
            let lastExportData = moment(this.state.lastExportDate).format(
              "dddd, MMMM DD @ hh:mm a"
            );
            console.log("this is exportTable data", lastExportData);
            this.props.lastExportDate(lastExportData);

            if (this.state.data.length === 0) {
              this.props.exportTableStatus(true);
            } else {
              this.props.exportTableStatus(false);
            }
          }
        );
      })
      .catch((err) => {
        /*
                                                                window.location.href = "https://wordpress-335777-1033023.cloudwaysapps.com/";
                                                */
        console.log("This is the err", err);
        this.setState({
          apiLoading: false,
        });
      });
  }

  onClickBrowseModalICon = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: true,
    });
  };
  handleCancelBrowseModal = (e) => {
    e.preventDefault();
    this.setState({
      browseModalVisible: false,
    });
  };

  onClickNewExport = (e) => {
    this.handleCancelBrowseModal(e);
    this.props.openExportModal(true);
    this.setState({ exportModalVisible: true });
  };
  exportSiteChange = (value: any) => {
    console.log();
    this.setState(
      {
        selectSiteKey: value,
      },
      () => {
        console.log("this is select siteKey", this.state.selectSiteKey);
      }
    );
  };
  handleChangePagination = (value) => {
    this.setState(
      {
        pagination: value.key,
      },
      () => {
        console.log("this is pagination value", this.state.pagination);
      }
    );
    console.log(value);
  };
  updateSearchText = (e) => {
    this.setState(
      {
        searchText: e.target.value,
      },
      () => {
        console.log("this is searchText", this.state.searchText);
      }
    );
  };
  searchText = (e) => {
    this.setState({
      searchItem: this.state.searchText,
    });
  };
  keyPressEnter = (e) => {
    if (e.key === "Enter") {
      this.searchText(e);
    }
  };
  handleChangePlatform = (value) => {
    this.setState(
      {
        platform: value.key,
      },
      () => {
        let dataFilter = this.state.sortData;
        console.log("this is dataFilter", dataFilter);
        if (this.state.platform !== "ALL_PLATFORM") {
          let FilterValue = dataFilter.filter(
            (x) => x.cms === this.state.platform
          );
          this.setState({
            data: FilterValue,
          });
        } else {
          this.setState({
            data: this.state.sortData,
          });
        }

        /*
                                                                        console.log("this is filter Value", FilterValue);
                                                            */
        /*this.setState({
                                                                data: data.filter(x => x.cms_domain === `${this.state.platform}`)
                                                            },()=>{
                                                                console.log("this is data after dropdown", this.state.data);
                                                            });*/

        console.log("this is pagination value", this.state.platform);
      }
    );
    /*
                                        console.log(value);
                                */
  };

  upDateExportHistoryList = () => {
    api
      .exportsHistoryList(this.props.token)
      .then((res) => {
        console.log("This is the res", res);
        this.setState(
          {
            apiLoading: false,
            data: res,
            sortData: res,
            lastExportDate: res[0].exported_time,
          },
          () => {
            this.props.reRenderExportTable(false);

            let lastExportData = moment(this.state.lastExportDate).format(
              "dddd, MMMM DD @ hh:mm a"
            );
            console.log("this is exportTable data", lastExportData);
            this.props.lastExportDate(lastExportData);

            if (this.state.data.length === 0) {
              this.props.exportTableStatus(true);
            } else {
              this.props.exportTableStatus(false);
            }
          }
        );
      })
      .catch((err) => {
        /*
                                                                window.location.href = "https://wordpress-335777-1033023.cloudwaysapps.com/";
                                                */
        console.log("This is the err", err);
        this.setState({
          apiLoading: false,
        });
      });
  };

  render(): any {
    const { data, apiLoading } = this.state;
    const { profile } = this.props;
    const { email = "" } = profile || {};

    const columns = [
      {
        title: "Title",
        dataIndex: "doc_name",
        width: "42%",
        key: "doc_name",
        render: (text, record) => (
          <a href={record.post_link} target="_blank">
            <div className="title-icon-container">
              {(() => {
                switch (record.cms) {
                  case WORDPRESS:
                    return <img src={WordPressIcon} alt="wordpress" />;
                  case SHOPIFY:
                    return <img src={ShopifyIcon} alt="shopifyicon" />;
                  case MEDIUM:
                    return <img src={MediumIcon} alt="mediumicon" />;
                  case WEB_FLOW:
                    return <img src={WebFlowIcon} alt="webflow" />;
                  case BIG_COMMERCE:
                    return <img src={BigCommerceIcon} alt="bigcommerce" />;
                  case DRUPAL:
                    return <img src={DrupalIcon} alt="drupal" />;
                  default:
                    return;
                }
              })()}
            </div>
            <p className="table-title-row">{text}</p>
          </a>
        ),
      },
      {
        title: "Page Link",
        dataIndex: "post_link",
        width: "43%",
        key: "post_link",
        render: (text) => (
          <a href={text} target={"_blank"}>
            <p className="page-link">{text}</p>
          </a>
        ),
      },
      {
        title: "Date Export",
        dataIndex: "exported_time",
        key: "exported_time",
        width: "15%",
        render: (date) => {
          try {
            let finalTime = moment(date).format("hh:mm a MM.DD.YY");
            if (finalTime === "Invalid date") {
              finalTime = "-";
            }
            return <p className="date-exported">{finalTime}</p>;
          } catch (e) {
            return <p className="date-exported">-</p>;
          }
        },
      },
    ];

    let newData = data.filter((data) => {
      return (
        data.doc_name
          .toLowerCase()
          .includes(this.state.searchItem.toLowerCase()) || !data
      );
    });

    console.log(
      "this is resetExportTabel state",
      this.props.resetExportTableState
    );
    return (
      <>
        {this.props.resetExportTableState === true
          ? this.upDateExportHistoryList()
          : null}
        <div className="table-top-container">
          <Input
            addonBefore={
              <img
                src={SearchIcon}
                alt="search-box"
                onClick={this.searchText}
              />
            }
            onChange={this.updateSearchText}
            value={this.state.searchText}
            onKeyPress={this.keyPressEnter}
            placeholder="Search your export history"
          />
          <div className="export-table-filter-container">
            <div className="platform-filter" id="platformFilter">
              <Select
                getPopupContainer={() =>
                  document.getElementById("platformFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: "ALL_PLATFORM" }}
                onChange={this.handleChangePlatform}
                className="platform-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value="ALL_PLATFORM">All Platforms</Option>
                <Option value="WORDPRESS">Wordpress</Option>
                {/*<Option value="SHOPIFY">Shopify</Option>*/}
                <Option value="MEDIUM">Medium</Option>
                <Option value="WEB_FLOW">Webflow</Option>
                {/* <Option value="BIG_COMMERCE">BigCommerce</Option>
                <Option value="DRUPAL">Drupal</Option> */}
              </Select>
            </div>
            <div className="export-page-filter" id="exportPageFilter">
              <p className="page-filter-selection">Rows per page:</p>
              <Select
                getPopupContainer={() =>
                  document.getElementById("exportPageFilter") || document.body
                }
                labelInValue
                defaultValue={{ key: 25 }}
                onChange={this.handleChangePagination}
                className="page-filter-selection"
                dropdownClassName="menu-dropdown"
                dropdownMenuStyle={{ overflow: "visible" }}
              >
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={75}>75</Option>
                <Option value={100}>100</Option>
              </Select>
            </div>
          </div>
        </div>

        <div id="googleBrowseModal" style={{ position: "relative" }}>
          <Modal
            maskClosable={false}
            getContainer={() =>
              document.getElementById("googleBrowseModal") || document.body
            }
            wrapClassName="export-google-drive-modal-container"
            title={
              <Row id="gdrive-modal">
                <h3 className="modal-title grey-1" id="modalTitle">
                  {" "}
                  We found no exports in your account{" "}
                </h3>
                <p className="google-drive-email-account-display">
                  {" "}
                  Notion Press account:{" "}
                  <span className="email-section">{email}</span>
                </p>
              </Row>
            }
            width={746}
            visible={this.state.browseModalVisible}
            onCancel={this.handleCancelBrowseModal}
            footer={null}
          >
            <div className="modal-body-container">
              <div className="row">
                <div className="column">
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">1</p>
                      </div>
                      <p className="bullet-message">
                        Try creating an export using the button below
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        onClick={(e) => this.onClickNewExport(e)}
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            New Export{" "}
                          </span>
                        }
                        scale="medium"
                        color="skyblue"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
                <div className="column" style={{ alignItems: "flex-end" }}>
                  <div className="inner-column-container">
                    <div
                      style={{ display: "inline-flex", flexDirection: "row" }}
                    >
                      <div className="bullet-circle">
                        <p className="numbering">2</p>
                      </div>
                      <p className="bullet-message">
                        Still not seeing exports in your export history?
                      </p>
                    </div>
                    <div className="display-button-field">
                      <DefaultButton
                        title={
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Contact us
                          </span>
                        }
                        scale="medium"
                        color="grey"
                        className="medium-font button-width-150"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        {apiLoading ? (
          <div
            className="loading-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "200px",
            }}
          >
            <ReactLoading type="spin" color="#52D1E3" height={25} width={25} />
          </div>
        ) : (
          <AntTable
            columns={columns}
            locale={{
              emptyText: (
                <>
                  <div className="export-error-image-container">
                    <img src={ErrorImage} alt="error-icon" />
                  </div>
                  <span
                    style={{
                      marginTop: "48px",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p className="no-data-message"> No exports created yet </p>
                    <div
                      className="info-circle"
                      onClick={this.onClickBrowseModalICon}
                    >
                      <img src={InfoIcon} className="info-icon" alt="info" />
                    </div>
                  </span>
                </>
              ),
            }}
            scroll={{ y: 550 }}
            pagination={{ pageSize: this.state.pagination }}
            dataSource={newData}
          />
        )}
        <NewExport
          token={this.props.token}
          exportModalVisible={this.state.exportModalVisible}
          setExportModalVisible={(d: any) =>
            this.setState({ exportModalVisible: d })
          }
        />
      </>
    );
  }
}

interface Props {
  token: string;
  exortTableStatus?: () => void;
}

interface TableState {
  data?: any;
  sortData: any;
  exportModalVisible?: boolean;
  pagination?: any;
  loading?: any;
  icon?: number;
  searchText?: any;
  searchItem?: any;
  modalVisible?: boolean;
  browseModalVisible: boolean;
  selectSiteKey: any;
  dataKey: number;
  apiLoading: boolean;
  lastExportDate: string;
  platform: any;
}

const mapDispatchToProps: ComponentDispatchProps = {
  reRenderExportTable: reRenderExportTable,
  openExportModal: openExportModal,
};

const mapStateToProps = (state): ComponentStateProps => {
  if (state.selfReducers.serverToken) {
    return {
      resetExportTableState: state.selfReducers.resetExportTableState,
      token: state.selfReducers.serverToken,
      profile: state.selfReducers.profile,
    };
  } else {
    return {
      token: "",
    };
  }
};

export default connect<
  ComponentStateProps,
  ComponentDispatchProps,
  ComponentOwnProps,
  TableState
>(
  mapStateToProps,
  mapDispatchToProps
)(ExportTable);
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExportTable));
