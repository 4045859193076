import React from "react";
import { connect } from "react-redux";
import api from "../../api/index";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  saveDetailsOfMe,
  saveServerToken,
} from "../../redux-stuffs/actions/SelfActions";
import "./redirect.scss";
import ReactLoading from "react-loading";
// import SweetAlert from 'react-bootstrap-sweetalert';
// import {authAction} from "../redux-stuffs/actions/auth_action";

class RedirectShopify extends React.Component<Props, InitialState> {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      hasError: false,
      showError: false,
    };
  }

  getUrlParameter(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");

    let results = regex.exec(this.props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  componentDidMount() {
    const code = this.getUrlParameter("code");
    const shop = this.getUrlParameter("shop");
    let body = {
      access_code: code,
      website_url: "https://" + shop,
      // redirect_url: "https://app.notionpress.tk/redirect-shopify",
    };

    console.log("This is the body", body);

    api.registerUrlShopify(body, this.props.token).then((result) => {
      if (result.status === 200) {
        this.props.history.push({
          pathname: "/websites",
        });
      } else if (result.status === 409) {
        this.setState(
          {
            errorMessage: "URL already exists",
            showError: true,
          },
          () => {
            this.props.history.push({
              pathname: "/websites",
            });
          }
        );
      } else {
        this.setState({
          errorMessage: "Something went wrong",
          showError: true,
        });
      }
    });
  }

  render() {
    const { showError, errorMessage } = this.state;

    if (showError) {
      return <div>{errorMessage}</div>;
    }

    return (
      <div className="row redirect-page-main-div">
        <div className="loading-icon" style={{ textAlign: "center" }}>
          <ReactLoading type="spin" color="#364699" height={50} width={50} />
        </div>

        {/*<SweetAlert show={this.state.showError}
                            error
                            title={<p className="sweet-alert-title">Oops</p>}
                            onConfirm={this.cancelError}
                >
                    {this.state.errorMessage}
                </SweetAlert>*/}
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveDetailsOfMe: saveDetailsOfMe,
  saveServerToken: saveServerToken,
};

const mapStateToProps = (state) => {
  console.log("This is the state", state);
  return {
    token: state.selfReducers.serverToken,
  };
};

interface InitialState {
  hasError: boolean;
  showError: boolean;
  errorMessage: string;
}

interface Props {
  title: string;
  location: any;
  saveServerToken: any;
  saveDetailsOfMe: any;
  history: any;
  token: string;
}

interface Props extends RouteComponentProps<any> {}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RedirectShopify)
);
